// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlVBGKnOYpEghmpdR\\+3XUA\\=\\={margin:30px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/experiences-module/src/modules/book/components/AdditionalDetails/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA","sourcesContent":[".additional-details-container {\n  margin: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additional-details-container": "BlVBGKnOYpEghmpdR+3XUA=="
};
export default ___CSS_LOADER_EXPORT___;
