export const apiVersionPrefix: string = "/api/v0";

export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;
export const userApiPrefix = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix = `${userApiPrefix}/wallet`;

export const experiencesApiPrefix: string = `${apiVersionPrefix}/experiences`;

export const locationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/experiences`;

export const experiencesAvailabilityApiPrefix: string = `${experiencesApiPrefix}/availability`;
export const experiencesAvailabilityAutocompleteApiPrefix: string = `${experiencesApiPrefix}/availability_autocomplete`;

export const experiencesShopApiPrefix: string = `${experiencesApiPrefix}/shop`;
export const experiencesShopOptionsApiPrefix: string = `${experiencesApiPrefix}/shop_option`;
export const experiencesShopReviewsApiPrefix: string = `${experiencesApiPrefix}/reviews`;

export const customerDetailsApiPath: string = `${apiVersionPrefix}/customer/details`;

export const fetchUserPassengersPath: string = `${apiVersionPrefix}/passengers/list`;
export const deleteUserPassengerPath: string = `${apiVersionPrefix}/passengers/delete`;
export const updateUserPassengerPath: string = `${apiVersionPrefix}/passengers/update`;
