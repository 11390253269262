import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ExperienceItinerary } from "redmond";
import {
  ActionLink,
  ExperienceItineraryDetails,
  truncateString,
} from "halifax";
import clsx from "clsx";

import { ShopComponentSkeleton } from "../../component";
import styles from "./styles.module.scss";
import * as textConstants from "../../textConstants";

export interface IShopWhatToExpect {
  isSkeleton: boolean;
  itinerary?: ExperienceItinerary;
  mobile?: boolean;
}

export interface IShopWhatToExpectProps extends IShopWhatToExpect {
  mobile?: boolean;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
  </Box>
);

export const ShopWhatToExpect = (props: IShopWhatToExpectProps) => {
  const { isSkeleton, itinerary, mobile = false } = props;

  if (!itinerary) {
    return null;
  }

  const { description } = itinerary;
  const [showDescription, setShowDescription] = React.useState(false);

  const renderDescription = () => (
    <Box
      className={
        styles["experiences-shop-what-to-expect-description-container"]
      }
    >
      <Typography
        variant="body2"
        className={styles["experiences-shop-what-to-expect-description"]}
      >
        {!showDescription && description
          ? truncateString(description, 300, true)
          : description}
      </Typography>
      <ActionLink
        className={styles["experiences-shop-what-to-expect-description-button"]}
        onClick={() => setShowDescription(!showDescription)}
        content={
          showDescription ? textConstants.READ_LESS : textConstants.READ_MORE
        }
      />
    </Box>
  );

  return (
    <Box className={clsx(styles["experiences-shop-what-to-expect-container"])}>
      {description && renderDescription()}
      <ExperienceItineraryDetails itinerary={itinerary} mobile={mobile} />
      {isSkeleton ? <Skeleton /> : null}
    </Box>
  );
};
