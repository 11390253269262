import { EDIT_TRAVELERS } from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export const trackEditTravelers = () =>
  new Promise((resolve) => {
    trackEvent({
      eventName: EDIT_TRAVELERS,
      properties: {},
    })
      .then(() => resolve(null))
      .catch(() => resolve(null));
  });
