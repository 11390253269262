import axios from "axios";
import {
  CREATED_TRAVELER_PROFILE,
  IPerson,
  TravelerProfileProperties,
  UPDATED_TRAVELER_PROFILE,
} from "redmond";
import { isCorpTenant } from "@capone/common";

import { updateUserPassengerPath } from "../paths";
import { trackEvent } from "../analytics/trackEvent";
import { config } from "../../config";

export interface IUpdateUserPassengerRequest {
  person: IPerson;
  isNewTraveler?: boolean;
  source?: string;
}

export interface IUpdateUserPassengerResponse {}

export const updateUserPassenger = async (
  body: IUpdateUserPassengerRequest
): Promise<IUpdateUserPassengerResponse> => {
  const { isNewTraveler, ...payload } = body;
  const res = await axios.put(updateUserPassengerPath, payload, {
    baseURL: config.baseURL,
  });

  if (isCorpTenant(config.TENANT)) {
    const frequentFlyerProgramsAmount = Reflect.ownKeys(
      body.person.frequentFlyer
    ).length;
    const hotelLoyaltyProgramsAmount = Reflect.ownKeys(
      body.person.hotelLoyalty
    ).length;

    trackEvent({
      eventName: isNewTraveler
        ? CREATED_TRAVELER_PROFILE
        : UPDATED_TRAVELER_PROFILE,
      properties: {
        frequent_flyer_program_added: frequentFlyerProgramsAmount > 0,
        num_frequent_flyer_programs: frequentFlyerProgramsAmount,
        hotel_loyalty_program_added: hotelLoyaltyProgramsAmount > 0,
        num_hotel_loyalty_programs: hotelLoyaltyProgramsAmount,
        source: body.source,
      } satisfies TravelerProfileProperties,
    });
  }

  return res.data;
};
