import { getObjectKeysAsObject, ParentState } from "@capone/checkout";

import { SelectTravelerEvent } from "../../../modules/book/state/events";
import { ExperiencesContext } from "../../../modules/book/state/types";

export const guards = {
  haveAnyTravelersLoaded: (ctx: ExperiencesContext) => {
    const { userTravelers } = ctx[ParentState.experiencesTravelerInformation];
    if (userTravelers?.length && userTravelers.length > 0) {
      return true;
    }
    return false;
  },
  hasAnySelectedTravelers: (ctx: ExperiencesContext) => {
    const { selectedTravelerIds } =
      ctx[ParentState.experiencesTravelerInformation];
    return selectedTravelerIds.length > 0;
  },
  willHaveSelectedTravelers: (
    ctx: ExperiencesContext,
    event: SelectTravelerEvent
  ) => {
    const { selectedTravelerIds } =
      ctx[ParentState.experiencesTravelerInformation];
    return !(
      selectedTravelerIds.length === 1 &&
      selectedTravelerIds[0] === event.travelerId
    );
  },
  hasCurrentUserTraveler: (ctx: ExperiencesContext) => {
    const currentUserTraveler =
      ctx[ParentState.experiencesTravelerInformation].currentUserTraveler?.id;
    return !!currentUserTraveler;
  },
  notVisitedTraveler: (ctx: ExperiencesContext) =>
    !ctx[ParentState.experiencesTravelerInformation].visited,
  notValidated: (ctx: ExperiencesContext) =>
    !ctx[ParentState.experiencesTravelerInformation].isValidated,
  hasSelectedPrimaryTraveler: (ctx: ExperiencesContext) =>
    !ctx[ParentState.experiencesPrimaryTraveler].selectedPrimaryTravelerId,
};

export const GuardTypes = getObjectKeysAsObject(guards);
