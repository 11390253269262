import {
  ExperiencesAvailabilityAutocompleteRequest,
  ExperiencesAvailabilityAutocompleteResponse,
  ExperiencesDestination,
  IIdExperiences,
} from "redmond";
import { put, select } from "redux-saga/effects";
import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import { getLocation } from "../../search/reducer";
import {
  getEncodedTags,
} from "../reducer";
import { fetchExperiencesAvailabilityAutocomplete } from "../../../api/v0/availability/fetchExperiencesAvailabilityAutocomplete";
import { setExperiencesAvailabilityAutocomplete } from "../actions/actions";

export function* fetchExperiencesAvailabilityAutocompleteSaga(
  action: actions.IFetchExperiencesAvailabilityAutocomplete
) {
  try {
    let requestBody: ExperiencesAvailabilityAutocompleteRequest;
    const state: IStoreState = yield select();

    const destinationId = (
      (getLocation(state)?.id as IIdExperiences)
        .experiencesSelection as ExperiencesDestination
    ).id;

    requestBody = {
      destination: destinationId,
      query: action.queryString,
      encodedTags: getEncodedTags(state)
    };

    let availabilityAutocompleteResponse: ExperiencesAvailabilityAutocompleteResponse =
      yield fetchExperiencesAvailabilityAutocomplete(requestBody);

    yield put(
      setExperiencesAvailabilityAutocomplete(availabilityAutocompleteResponse)
    );
  } catch (e) {
    Logger.debug(e);
  }
}
