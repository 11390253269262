import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@material-ui/core";
import { ShopComponentSkeleton } from "../../component";
import {
  ExperienceBadge,
  ExperienceBookableItem,
  ExperienceDailyPrice,
  ExperienceId,
  ExperienceLanguageGuide,
  ExperienceLogistics,
  ExperienceOptionId,
  ExperiencePricingCategory,
  ExperiencePricingCategoryEnum,
  ExperiencesAgeBandEnum,
  ExperiencesShopOptionRequest,
  ExperienceTimeSlotPrice,
  IPassengerCounts,
  ReviewSummary,
  RewardsAccount,
  SpecificAgeBand,
  TicketType,
} from "redmond";
import {
  AvailabilityReviewRating,
  AvailabilityDuration,
  AvailabilityLanguage,
  Icon,
  IconName,
  truncateString,
  B2BButton,
  ActionButton,
  ExperienceTag,
  getPriceString,
  ActionLink,
  DesktopPopupModal,
  PassengerCountPicker,
  PassengerCountPickerTitles,
  useDeviceTypes,
  roundToNearestHalf,
  getRewardText,
  AvailabilityTicket,
} from "halifax";
import dayjs from "dayjs";
import clsx from "clsx";
import * as textConstants from "../../textConstants";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../common/MonthAndDatePicker";
import { renderAddress, ShopMap } from "../ShopMap/component";
import {
  BadgeExcellenceModal,
  getPaxMixFromTravelersCount,
  getTotalTravelersCount,
} from "../../../common";
import { TOTAL_NUM_TRAVELERS, START_TIME } from "../../../common/textConstants";
import { PATH_BOOK } from "../../../../utils/paths";

export interface IShopOverview {
  isSkeleton?: boolean;
  experienceId?: ExperienceId;
  title?: string;
  overview?: string;
  rating?: ReviewSummary;
  duration?: number;
  locationLogistics?: ExperienceLogistics;
  languageGuides?: ExperienceLanguageGuide[];
  provider?: string;
  badges?: ExperienceBadge[];
  bookableItems?: ExperienceBookableItem[];
  pricingCategory?: ExperiencePricingCategory;
  experienceMaxTravelers?: number;
  adultRequired?: boolean;
  ticket?: TicketType;
  dailyPrices?: ExperienceDailyPrice[];
}

export interface IShopOverviewProps extends IShopOverview {
  setFromDate: (date: Date | null) => void;
  setUntilDate: (date: Date | null) => void;
  fromDate: Date | null;
  untilDate: Date | null;
  fetchExperiencesShop: () => void;
  fetchExperiencesShopOptions: (request: ExperiencesShopOptionRequest) => void;
  selectedBookableItem: ExperienceBookableItem | null;
  setSelectedBookableItem: (bookableItem: ExperienceBookableItem) => void;
  selectedOptionId?: ExperienceOptionId;
  setSelectedOptionId: (optionId: ExperienceOptionId) => void;
  selectedTime: string | null;
  setSelectedTime: (time: any) => void;
  selectedDate: string | null;
  setSelectedDate: (date: any) => void;
  ageBands?: {
    [key in ExperiencesAgeBandEnum]?: SpecificAgeBand;
  };
  setTravelerCounts: (counts: IPassengerCounts) => void;
  travelerCounts?: IPassengerCounts;
  mobile?: boolean;
  largestValueAccount?: RewardsAccount;
}

export const getMinMaxHelperText = (ageBand: SpecificAgeBand) => {
  let helperText = "";
  if (ageBand.minNumberOfTravelers !== undefined) {
    helperText = `${helperText} Minimum: ${ageBand.minNumberOfTravelers}`;
  }

  if (ageBand.maxNumberOfTravelers !== undefined) {
    helperText = `${helperText}${helperText.length > 0 ? "," : ""} Maximum: ${
      ageBand.maxNumberOfTravelers
    }`;
  }

  return helperText;
};

const Skeleton = () => (
  <Box className={styles["experiences-shop-skeleton"]}>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopOverview = (props: IShopOverviewProps) => {
  const {
    isSkeleton = false,
    title,
    experienceId,
    overview,
    rating,
    duration,
    locationLogistics,
    languageGuides,
    provider,
    badges,
    bookableItems,
    pricingCategory,
    setFromDate,
    setUntilDate,
    fromDate,
    untilDate,
    fetchExperiencesShop,
    fetchExperiencesShopOptions,
    selectedBookableItem,
    setSelectedBookableItem,
    selectedTime,
    setSelectedTime,
    ageBands,
    setTravelerCounts,
    travelerCounts,
    selectedOptionId,
    setSelectedOptionId,
    selectedDate,
    setSelectedDate,
    experienceMaxTravelers,
    largestValueAccount,
    adultRequired,
    mobile = false,
    ticket,
    dailyPrices,
  } = props;

  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();
  const [isBadgeExcellenceModalOpen, setBadgeExcellenceModalOpen] =
    React.useState(false);
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [openTravelerCountPicker, setOpenTravelerCountPicker] =
    React.useState(false);

  const [showDescription, setShowDescription] = React.useState(false);

  const [showExpandedMap, setShowExpandedMap] = React.useState(false);

  const [localFromDate, setLocalFromDate] = React.useState<Date | null>(null);
  const [localUntilDate, setLocalUntilDate] = React.useState<Date | null>(null);

  const [modalTitles, setModalTitles] = useState<
    PassengerCountPickerTitles | undefined
  >(undefined);
  const [totalTravelers, setTotalTravelers] = useState<number>(2);

  const isMultiVariant = useMemo(() => {
    return bookableItems && bookableItems.length > 1;
  }, [bookableItems]);

  useEffect(() => {
    setTotalTravelers(getTotalTravelersCount(travelerCounts));
    handleFetchShopOptions();
  }, [travelerCounts]);

  const unavailableDates = useMemo(() => {
    return dailyPrices
      ?.filter((daily) => {
        return daily.price === undefined;
      })
      .map((daily) => {
        return dayjs(daily.date).toDate();
      });
  }, [dailyPrices]);

  const pricePerDay: { [date: string]: string } = useMemo(() => {
    const priceDateMap: { [date: string]: string } = {};
    dailyPrices
      ?.filter((daily) => {
        return daily.price;
      })
      .forEach((daily) => {
        const date = dayjs(daily.date).format("YYYY-MM-DD");
        const price = daily.price;
        if (price) {
          priceDateMap[date] = getPriceString({
            price: price.fiat.value,
            currencySymbol: price.fiat.currencySymbol,
          });
        }
      });

    return priceDateMap;
  }, [dailyPrices]);

  useEffect(() => {
    if (ageBands) {
      let newModalTitles: PassengerCountPickerTitles = {
        modalTitle: textConstants.EDIT_TRAVELERS,
        adultTitle: textConstants.ADULTS,
        adultSubtitle: ageBands.Adult
          ? textConstants.AGES_SUBTITLE(
              ageBands.Adult.lowerBound,
              ageBands.Adult.upperBound
            )
          : "",
        adultHelperText: ageBands.Adult
          ? getMinMaxHelperText(ageBands.Adult)
          : undefined,
        adultPrice: ageBands.Adult
          ? `${getPriceString({
              price: ageBands.Adult?.pricePerTraveler.fiat.value,
            })}`
          : "",
        childrenPrice: ageBands.Child
          ? `${getPriceString({
              price: ageBands.Child?.pricePerTraveler.fiat.value,
            })}`
          : "",
        childrenTitle: ageBands.Child ? textConstants.CHILDREN : "",
        childrenSubtitle: ageBands.Child
          ? textConstants.AGES_SUBTITLE(
              ageBands.Child.lowerBound,
              ageBands.Child.upperBound
            )
          : "",
        childrenHelperText: ageBands.Child
          ? getMinMaxHelperText(ageBands.Child)
          : undefined,
        seniorTitle: ageBands.Senior ? textConstants.SENIORS : undefined,
        seniorSubtitle: ageBands.Senior
          ? textConstants.AGES_SUBTITLE(
              ageBands.Senior.lowerBound,
              ageBands.Senior.upperBound
            )
          : undefined,
        seniorHelperText: ageBands.Senior
          ? getMinMaxHelperText(ageBands.Senior)
          : undefined,
        seniorPrice: ageBands.Senior
          ? `${getPriceString({
              price: ageBands.Senior?.pricePerTraveler.fiat.value,
            })}`
          : undefined,
        youthTitle: ageBands.Youth ? textConstants.YOUTHS : undefined,
        youthSubtitle: ageBands.Youth
          ? textConstants.AGES_SUBTITLE(
              ageBands.Youth.lowerBound,
              ageBands.Youth.upperBound
            )
          : undefined,
        youthHelperText: ageBands.Youth
          ? getMinMaxHelperText(ageBands.Youth)
          : undefined,
        youthPrice: ageBands.Youth
          ? `${getPriceString({
              price: ageBands.Youth?.pricePerTraveler.fiat.value,
            })}`
          : undefined,
        showInfantsTitleOnly: true,
        infantTitle: ageBands.Infant ? textConstants.INFANTS : undefined,
        infantSubtitle: ageBands.Infant
          ? textConstants.AGES_SUBTITLE(
              ageBands.Infant.lowerBound,
              ageBands.Infant.upperBound
            )
          : undefined,
        infantHelperText: ageBands.Infant
          ? getMinMaxHelperText(ageBands.Infant)
          : undefined,
        infantPrice: ageBands.Infant
          ? `${getPriceString({
              price: ageBands.Infant?.pricePerTraveler.fiat.value,
            })}`
          : undefined,
      };
      setModalTitles(newModalTitles);
    }
  }, [ageBands]);

  React.useEffect(() => {
    if (bookableItems) {
      setSelectedBookableItem(bookableItems[0]);
      if (bookableItems[0].experienceOptionId) {
        setSelectedOptionId(bookableItems[0].experienceOptionId);
      }
    }
  }, [bookableItems]);

  React.useEffect(() => {
    if (selectedBookableItem) {
      if (selectedBookableItem.experienceOptionId) {
        setSelectedOptionId(selectedBookableItem.experienceOptionId);
      }
      const dateToSelect = Object.keys(selectedBookableItem.timeSlotsByDate)[0];
      setSelectedDate(dateToSelect);
      setSelectedTime(
        selectedBookableItem.timeSlotsByDate[dateToSelect][0].startTime
      );
    }
  }, [selectedBookableItem]);

  React.useEffect(() => {
    if (selectedOptionId && selectedDate) {
      handleFetchShopOptions();
    }
  }, [selectedOptionId, selectedDate, selectedTime]);

  React.useEffect(() => {
    setLocalFromDate(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setLocalUntilDate(untilDate);
  }, [untilDate]);

  const handleFetchShopOptions = () => {
    if (selectedOptionId && selectedDate && experienceId) {
      fetchExperiencesShopOptions({
        startTime: selectedTime ?? undefined,
        experienceId: experienceId,
        experienceOptionId: selectedOptionId,
        paxMix: getPaxMixFromTravelersCount(travelerCounts),
        date: selectedDate,
        currency: "USD",
      });
    }
  };

  const handleClickDone = () => {
    setOpenCalendarModal(false);
    let refetchExperiencesShop = false;
    if (localFromDate !== fromDate || localUntilDate != untilDate) {
      refetchExperiencesShop = true;
    }
    setFromDate(localFromDate);
    setUntilDate(localUntilDate);
    if (refetchExperiencesShop) {
      fetchExperiencesShop();
    }
  };

  if (
    !title ||
    !overview ||
    !rating ||
    duration === undefined ||
    !locationLogistics ||
    !provider ||
    !languageGuides
  ) {
    return null;
  }

  const sameAsStartLocation =
    locationLogistics.startLocations?.[0]?.address ===
    locationLogistics.endLocations?.[0]?.address;

  const renderExperienceDetailsTopSection = () => {
    return (
      <Box className={styles["experiences-shop-overview-subtext"]}>
        <AvailabilityReviewRating
          reviewCount={rating.numberOfReviews}
          scaledScore={roundToNearestHalf(rating.reviewAverage)}
          shortReviews
        />
        <Divider
          className={styles["experiences-shop-overview-subtext-dividers"]}
          orientation="vertical"
        />
        {duration && (
          <>
            <AvailabilityDuration duration={duration} isSkeleton={isSkeleton} />
            <Divider
              className={styles["experiences-shop-overview-subtext-dividers"]}
              orientation="vertical"
            />
          </>
        )}
        {languageGuides && languageGuides.length > 0 && (
          <>
            <AvailabilityLanguage
              languages={languageGuides}
              isSkeleton={isSkeleton}
            />
            <Divider
              className={styles["experiences-shop-overview-subtext-dividers"]}
              orientation="vertical"
            />
          </>
        )}
        {ticket && (
          <>
            <AvailabilityTicket ticket={ticket} />
            <Divider
              className={styles["experiences-shop-overview-subtext-dividers"]}
              orientation="vertical"
            />
          </>
        )}
        <Typography className={styles["experiences-shop-overview-title"]}>
          {truncateString(provider, 50)}
        </Typography>
      </Box>
    );
  };

  const renderBookableItem = (
    item: ExperienceBookableItem,
    isMultiVariant?: boolean
  ) => {
    return (
      <Box className={styles["experiences-shop-overview-bookable-section"]}>
        {isMultiVariant && renderOverview(item.description)}
        <Box className={styles["experiences-shop-overview-availability"]}>
          <Typography variant="h6">{textConstants.AVAILABILITY}</Typography>
          <Box
            className={
              styles["experiences-shop-overview-availability-selections"]
            }
          >
            {Object.keys(item.timeSlotsByDate)
              .slice(0, 4)
              .map((date) => {
                const price = item.timeSlotsByDate[date][0].price;
                return (
                  <ActionButton
                    className={clsx(
                      styles[
                        "experiences-shop-overview-bookable-options-button"
                      ],
                      {
                        [styles["selected-option"]]: date === selectedDate,
                      }
                    )}
                    defaultStyle={
                      date === selectedDate ? "h4r-primary" : "h4r-secondary"
                    }
                    onClick={() => {
                      if (date !== selectedDate) {
                        setSelectedDate(date);
                      }
                    }}
                    message={
                      <Box
                        className={
                          styles[
                            "experiences-shop-overview-bookable-options-button-label"
                          ]
                        }
                      >
                        <span
                          className={
                            styles[
                              "experiences-shop-overview-bookable-options-button-date"
                            ]
                          }
                        >
                          {dayjs(date).format("ddd, MMM D")}
                        </span>
                        <span
                          className={
                            styles[
                              "experiences-shop-overview-bookable-options-button-price"
                            ]
                          }
                        >
                          {price &&
                            `${getPriceString({
                              price: price.fiat.value,
                              currencySymbol: price.fiat.currencySymbol,
                            })} ${
                              pricingCategory?.ExperiencePricingCategory ===
                              ExperiencePricingCategoryEnum.PerGroup
                                ? textConstants.PER_GROUP
                                : textConstants.PER_PERSON
                            }`}
                        </span>
                      </Box>
                    }
                  />
                );
              })}
            <ActionLink
              content={
                <Box className={styles["view-all-calendar-link-content"]}>
                  <Icon name={IconName.CalendarWithBoxIcon} />
                  <Typography className={styles["view-all-calendar-link-text"]}>
                    {textConstants.VIEW_ALL}
                  </Typography>
                </Box>
              }
              onClick={() => setOpenCalendarModal(true)}
              className={styles["view-all-calendar-link"]}
            />
          </Box>
        </Box>
        <Box className={styles["experiences-shop-overview-bookables"]}>
          <Typography variant="h6">
            {textConstants.CONFIRM_TRAVELERS}
          </Typography>
          <Box
            className={styles["experiences-shop-overview-bookable-selections"]}
          >
            <B2BButton
              aria-label={TOTAL_NUM_TRAVELERS(totalTravelers)}
              className="num-travelers-input b2b"
              variant="traveler-selector"
              onClick={() => setOpenTravelerCountPicker(true)}
            >
              <Box className={styles["num-traveler-content"]}>
                <Box className={styles["num-traveler-with-icon"]}>
                  <Icon
                    aria-hidden={true}
                    className={styles["num-traveler-icon-start"]}
                    name={IconName.B2BUser}
                    ariaLabel=""
                  />
                  <Box className={styles["num-traveler-text"]}>
                    {TOTAL_NUM_TRAVELERS(totalTravelers)}
                  </Box>
                </Box>
                <Icon
                  aria-hidden={true}
                  className={styles["num-traveler-icon-end"]}
                  name={IconName.B2BEditPencil}
                  ariaLabel=""
                />
              </Box>
            </B2BButton>
            {selectedTime && (
              <FormControl className={styles["time-input-container"]}>
                <InputLabel id="time-select-label">{START_TIME}</InputLabel>
                <Select
                  labelId="time-select-label"
                  id="time-select"
                  className={styles["time-selection-menu"]}
                  classes={{
                    root: styles["time-select-root"],
                    icon: styles["time-select-arrow-icon"],
                  }}
                  label={START_TIME}
                  onChange={(event) => {
                    setSelectedTime(event.target.value);
                  }}
                  value={selectedTime}
                  MenuProps={{
                    classes: { paper: "time-selector-menu" },
                  }}
                  input={
                    <Input
                      className={styles["input-root"]}
                      disableUnderline={true}
                      startAdornment={
                        <Icon
                          className={styles["clock-icon"]}
                          name="clock-icon"
                        />
                      }
                    />
                  }
                >
                  {selectedBookableItem &&
                    selectedDate &&
                    selectedBookableItem.timeSlotsByDate[selectedDate].map(
                      (option: ExperienceTimeSlotPrice) => {
                        return option.startTime ? (
                          <MenuItem
                            value={option.startTime}
                            key={option.startTime}
                          >
                            {option.startTime}
                          </MenuItem>
                        ) : null;
                      }
                    )}
                </Select>
              </FormControl>
            )}
          </Box>
          <ActionButton
            className={styles["experiences-shop-overview-checkout-button"]}
            message={
              <span
                className={
                  styles["experiences-shop-overview-checkout-button-text"]
                }
              >
                {textConstants.CONTINUE_TO_CHECKOUT}
              </span>
            }
            defaultStyle="h4r-primary"
            onClick={() =>
              history.push(`${PATH_BOOK}${history.location.search}`)
            }
          />
        </Box>
      </Box>
    );
  };

  const renderExpandedVariantLabel = (item: ExperienceBookableItem) => (
    <Box
      className={clsx(
        styles["experiences-shop-bookable-item-option-container"],
        styles["experiences-shop-expanded-bookable-item-option-container"]
      )}
    >
      <Typography
        id={item.experienceOptionId.value}
        variant="h6"
        className={styles["experiences-shop-bookable-item-option-title"]}
      >
        {item.title}
      </Typography>
      {renderBookableItem(item, true)}
    </Box>
  );

  const renderCollapsedVariantLabel = (item: ExperienceBookableItem) => {
    const firstAvailableDate = Object.keys(item.timeSlotsByDate).find(
      (date) => {
        return item.timeSlotsByDate[date].find((y) => {
          return y.price;
        });
      }
    );
    const firstAvailablePrice: ExperienceTimeSlotPrice | undefined =
      firstAvailableDate
        ? item.timeSlotsByDate[firstAvailableDate].find((y) => {
            return y.price;
          })
        : undefined;

    const priceTitle = firstAvailablePrice?.price
      ? textConstants.PRICE_TITLE_TEXT(
          getPriceString({
            price: firstAvailablePrice.price.fiat.value,
            currencySymbol: firstAvailablePrice.price.fiat.currencySymbol,
          }),
          pricingCategory?.ExperiencePricingCategory ===
            ExperiencePricingCategoryEnum.PerGroup
            ? textConstants.GROUP
            : textConstants.PERSON
        )
      : "";

    const rewardsTitle =
      firstAvailablePrice?.price && largestValueAccount
        ? `${getRewardText({
            reward:
              firstAvailablePrice?.price.rewards[
                largestValueAccount.accountReferenceId
              ],
          })}`
        : "";

    const subtitle = experienceMaxTravelers
      ? textConstants.MAX_TRAVELER_TEXT(experienceMaxTravelers)
      : undefined;
    return (
      <Box
        className={clsx(
          styles["experiences-shop-bookable-item-option-container"],
          styles["experiences-shop-collapsed-bookable-item-option-container"]
        )}
      >
        <Typography
          id={item.experienceOptionId.value}
          variant="h6"
          className={styles["experiences-shop-bookable-item-option-title"]}
        >
          {item.title}
        </Typography>
        {priceTitle && (
          <Box
            className={clsx(
              styles["experiences-shop-bookable-item-option-price-container"]
            )}
          >
            <Typography
              className={styles["experiences-shop-bookable-item-option-price"]}
              dangerouslySetInnerHTML={{
                __html: textConstants.getPriceAndRewardsCopy({
                  price: priceTitle,
                  rewards: rewardsTitle,
                }),
              }}
            />
            {subtitle && (
              <Typography
                className={
                  styles["experiences-shop-bookable-item-option-subtitle"]
                }
                variant={"body2"}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderMultiVariantBookableItems = () => {
    return (
      <Box>
        <Box className={styles["experiences-shop-bookable-item-selection"]}>
          {(bookableItems || []).map((item) => {
            return (
              <FormControlLabel
                className={styles["experiences-shop-bookable-item-option"]}
                key={item.experienceOptionId.value}
                value={item.experienceOptionId.value}
                label={
                  item.experienceOptionId.value === selectedOptionId?.value
                    ? renderExpandedVariantLabel(item)
                    : renderCollapsedVariantLabel(item)
                }
                labelPlacement="end"
                control={
                  <Radio
                    checked={
                      selectedOptionId?.value === item.experienceOptionId?.value
                    }
                    className={
                      styles["experiences-shop-bookable-item-option-radio"]
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        setSelectedBookableItem(item);
                      }
                    }}
                  />
                }
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  const renderOverview = (description: string, mobile?: boolean) => {
    const truncatedDescription = truncateString(description, 300, true);
    return (
      <>
        {!mobile && <Typography variant="h6">{"Overview"}</Typography>}
        <Typography
          variant="body2"
          className={styles["experiences-shop-overview-text"]}
        >
          {!showDescription ? truncatedDescription : description}
        </Typography>
        <Link
          component="button"
          onClick={() => setShowDescription(!showDescription)}
          className={styles["experiences-shop-overview-button"]}
        >
          {showDescription ? textConstants.SHOW_LESS : textConstants.READ_MORE}
        </Link>
      </>
    );
  };

  const renderDesktopVersion = () => (
    <Box className={styles["experiences-shop-overview-container"]}>
      {badges && badges.length > 0 && (
        <Box className={styles["experience-shop-overview-tags-container"]}>
          {badges.map((badge) => (
            <ExperienceTag
              key={badge}
              badge={badge}
              onBadgeClick={
                badge === ExperienceBadge.BadgeOfExcellence
                  ? () => {
                      if (!isBadgeExcellenceModalOpen)
                        setBadgeExcellenceModalOpen(true);
                    }
                  : undefined
              }
            />
          ))}
        </Box>
      )}
      {/* TOP SECTION */}
      {renderExperienceDetailsTopSection()}
      {/* MIDDLE SECTION */}
      {bookableItems && (
        <Box className={styles["experiences-shop-bookable-items-section"]}>
          {isMultiVariant
            ? renderMultiVariantBookableItems()
            : renderBookableItem(bookableItems[0])}
        </Box>
      )}

      {/* BOTTOM SECTION */}
      <Box className={styles["experiences-shop-overview-bottom-section"]}>
        {!isMultiVariant && renderOverview(overview)}
        <Box className={styles["experiences-shop-overview-map-section"]}>
          {locationLogistics.startLocations.length > 0 && (
            <Box
              className={styles["experiences-shop-overview-map-starting-point"]}
            >
              <Typography
                variant="h6"
                className={styles["experiences-shop-overview-map-titles"]}
              >
                <Icon name={IconName.B2BMapPin} />
                {textConstants.STARTING_POINT}
              </Typography>
              {renderAddress(locationLogistics.startLocations[0])}
            </Box>
          )}
          {locationLogistics.endLocations.length > 0 && (
            <Box className={styles["experiences-shop-overview-map-end-point"]}>
              <Typography
                variant="h6"
                className={styles["experiences-shop-overview-map-titles"]}
              >
                <Icon name={IconName.FlagIcon} />
                {textConstants.END_POINT}
              </Typography>
              {renderAddress(
                locationLogistics.endLocations[0],
                sameAsStartLocation
              )}
            </Box>
          )}
          {locationLogistics.startLocations.length > 0 &&
            locationLogistics.endLocations.length > 0 && (
              <Box className={styles["experiences-shop-overview-map"]}>
                <ShopMap
                  locationLogistics={locationLogistics}
                  setShowExpandedlMap={setShowExpandedMap}
                  variant="small"
                  sameAsStart={sameAsStartLocation}
                />
              </Box>
            )}
        </Box>
      </Box>
      <DesktopPopupModal
        open={openCalendarModal}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setOpenCalendarModal(false);
        }}
        className={clsx(
          "desktop-calendar-picker-popup-root",
          "desktop-experiences-calendar-picker-popup-root",
          "experiences-module"
        )}
        contentClassName="desktop-calendar-picker-wrapper"
        invisibleBackdrop={false}
      >
        {/* dailyPrices: Array<ExperienceDailyPrice>; */}
        <MonthAndDatePicker
          viewType={MonthAndDatePickerType.Horizontal}
          startDate={localFromDate}
          endDate={localUntilDate}
          setStartDate={(val: Date | null) => {
            setLocalFromDate(val);
          }}
          setEndDate={(val: Date | null) => {
            setLocalUntilDate(val);
          }}
          header={textConstants.SELECT_DATE}
          subheader={
            pricingCategory?.ExperiencePricingCategory ===
            ExperiencePricingCategoryEnum.PerGroup
              ? textConstants.PRICE_PER_GROUP
              : textConstants.PRICE_PER_PERSON
          }
          className={clsx("experiences-shop-date-picker", "b2b")}
          unavailableDates={unavailableDates}
          pricePerDay={pricePerDay}
        />
        <Button
          onClick={handleClickDone}
          disabled={!localFromDate || !localUntilDate}
          className="select-dates-button"
          variant="contained"
        >
          {textConstants.SELECT_DATE}
        </Button>
      </DesktopPopupModal>
      {travelerCounts && setTravelerCounts && (
        <DesktopPopupModal
          open={openTravelerCountPicker}
          className={styles["desktop-experiences-passenger-picker-popup-root"]}
          contentClassName={
            styles["desktop-passenger-count-picker-popup-container"]
          }
          onClose={() => setOpenTravelerCountPicker(false)}
          invisibleBackdrop={false}
        >
          <PassengerCountPicker
            minimumAdultsCount={0}
            titles={modalTitles}
            setPassengerCounts={(counts) => {
              const travellers = counts as IPassengerCounts;
              setTravelerCounts(travellers);
            }}
            onClickApply={() => {
              setOpenTravelerCountPicker(false);
            }}
            counts={travelerCounts}
            className="b2b"
            maximumTravelersCount={experienceMaxTravelers}
            paxCountLimits={{
              seniorsCount: {
                min: ageBands?.Senior?.minNumberOfTravelers,
                max: ageBands?.Senior?.maxNumberOfTravelers,
              },
              adultsCount: {
                min: ageBands?.Adult?.minNumberOfTravelers,
                max: ageBands?.Adult?.maxNumberOfTravelers,
              },
              youthsCount: {
                min: ageBands?.Youth?.minNumberOfTravelers,
                max: ageBands?.Youth?.maxNumberOfTravelers,
              },
              childrenCount: {
                min: ageBands?.Child?.minNumberOfTravelers,
                max: ageBands?.Child?.maxNumberOfTravelers,
              },
              infantsCount: {
                min: ageBands?.Infant?.minNumberOfTravelers,
                max: ageBands?.Infant?.maxNumberOfTravelers,
              },
            }}
            getPaxCountsError={(counts) => {
              const passengerCounts = counts as IPassengerCounts;
              let errorText = "";
              if (adultRequired) {
                errorText =
                  passengerCounts.adultsCount +
                    (passengerCounts.seniorsCount ?? 0) <=
                  0
                    ? textConstants.ADULT_REQUIRED
                    : "";
              }

              return errorText;
            }}
          />
        </DesktopPopupModal>
      )}
      {locationLogistics && (
        <DesktopPopupModal
          open={showExpandedMap}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            setShowExpandedMap(false);
          }}
          className={clsx(styles["desktop-experiences-shop-map-popup-root"])}
          contentClassName={styles["desktop-shop-map-wrapper"]}
          invisibleBackdrop={false}
        >
          <div className={styles["experiences-shop-map-header-container"]}>
            <div className={styles["experiences-shop-map-title"]}>{title}</div>
            {renderExperienceDetailsTopSection()}
          </div>
          <ShopMap
            locationLogistics={locationLogistics}
            setShowExpandedlMap={setShowExpandedMap}
            variant="large"
            sameAsStart={sameAsStartLocation}
          />
        </DesktopPopupModal>
      )}
      <BadgeExcellenceModal
        isOpen={isBadgeExcellenceModalOpen}
        isMobile={matchesMobile}
        onClose={() => {
          setBadgeExcellenceModalOpen(false);
        }}
      />
      {isSkeleton ? <Skeleton /> : null}
    </Box>
  );

  const renderMobileVersion = () => (
    <Box
      className={clsx(
        styles["experiences-shop-overview-container"],
        styles["mobile"]
      )}
    >
      {!isMultiVariant && renderOverview(overview, true)}
      <Box className={styles["experiences-shop-overview-map-section"]}>
        {locationLogistics.startLocations.length > 0 && (
          <Box
            className={styles["experiences-shop-overview-map-starting-point"]}
          >
            <Typography
              variant="h6"
              className={styles["experiences-shop-overview-map-titles"]}
            >
              <Icon name={IconName.B2BMapPin} />
              {textConstants.STARTING_POINT}
            </Typography>
            {renderAddress(locationLogistics.startLocations[0])}
          </Box>
        )}
        {locationLogistics.endLocations.length > 0 && (
          <Box className={styles["experiences-shop-overview-map-end-point"]}>
            <Typography
              variant="h6"
              className={styles["experiences-shop-overview-map-titles"]}
            >
              <Icon name={IconName.FlagIcon} />
              {textConstants.END_POINT}
            </Typography>
            {renderAddress(
              locationLogistics.endLocations[0],
              sameAsStartLocation
            )}
          </Box>
        )}
        {locationLogistics.startLocations.length > 0 &&
          locationLogistics.endLocations.length > 0 && (
            <Box className={styles["experiences-shop-overview-map"]}>
              <ShopMap
                locationLogistics={locationLogistics}
                setShowExpandedlMap={setShowExpandedMap}
                variant="small"
                sameAsStart={sameAsStartLocation}
              />
            </Box>
          )}
      </Box>
    </Box>
  );

  return mobile ? renderMobileVersion() : renderDesktopVersion();
};
