import { fetchUserPassengers } from "../../../../api/v0/user/fetchUserPassengers";

export const fetchUserPassengersService = () =>
  new Promise((resolve, reject) => {
    fetchUserPassengers()
      .then((response) => {
        resolve({
          value: response,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
