export enum AdditionalDetailsState {
  idle = "idle",
  error = "error",
  validate = "validate",
}

export enum PickupPointValidation {
  PickupPointSelection = "pickup-point-selection",
  HotelSelection = "hotel-selection",
  EnterPickupLocation = "enter-pickup-location",
}

export enum LangGuideValidation {
  LangSelection = "lang-selection",
}

export type AdditionalDetailsValidation =
  | PickupPointValidation
  | LangGuideValidation;

export const HOTEL_OPTION_NOT_AVAILABLE_LABEL = "I don't see my hotel";
