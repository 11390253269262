import React, { useState } from "react";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  ActionButton,
  AvailabilityCarousel,
  AvailabilityDuration,
  AvailabilityReviewRating,
  Carousel,
  DesktopPopupModal,
  getPriceString,
  getRewardText,
  Icon,
  IconName,
  PhotoLayout,
  roundToNearestHalf,
} from "halifax";
import {
  ExperiencesAgeBandEnum,
  ExperiencesPriceBreakdown,
  MediaAsset,
  MediaAssetKindEnum,
  ReviewSummary,
  RewardsAccount,
  SpecificAgeBand,
} from "redmond";
import clsx from "clsx";

import styles from "./styles.module.scss";
import {
  ALL_PHOTOS,
  BOOK_NOW,
  MAX_TRAVELER_TEXT,
  PRICE_TITLE_TEXT,
  PROVIDER_PHOTOS,
  REVIEWS_POWERED_BY,
  SHOW_ALL_PHOTOS_BUTTON_LABEL,
  TRAVELERS_PHOTOS,
  TRIPADVISOR,
} from "../../textConstants";
import { FREE_CANCELLATION } from "../../../common/textConstants";

export enum PhotoGroup {
  PROVIDER = "Provider",
  TRAVELER = "Traveler",
}

export interface IShopPhotoGalleryProps {
  isSkeleton: boolean;
  providerPhotos?: string[];
  travelerPhotos?: string[];
  experienceName?: string;
  rating?: ReviewSummary;
  duration?: number;
  isFreeCancellation?: boolean;
  experienceMaxTravelers?: number;
  selectedGroup?: PhotoGroup;
  mobile?: boolean;
  priceBreakdown?: ExperiencesPriceBreakdown;
  largestValueAccount?: RewardsAccount;
  ageBands?: {
    [key in ExperiencesAgeBandEnum]?: SpecificAgeBand;
  };
}

export const ShopPhotoGallery = (props: IShopPhotoGalleryProps) => {
  const {
    isSkeleton,
    providerPhotos,
    travelerPhotos,
    experienceName,
    rating,
    duration,
    isFreeCancellation,
    selectedGroup = PhotoGroup.PROVIDER,
    mobile = false,
    priceBreakdown,
    largestValueAccount,
    ageBands,
    experienceMaxTravelers,
  } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedPhotoGroup, setSelectedPhotoGroup] =
    useState<PhotoGroup>(selectedGroup);
  const [selectedTravelerPhotoIdx, setSelectedTravelerPhotoIdx] = useState(0);

  const mediaArray: MediaAsset[] = [];
  const photos =
    selectedGroup === PhotoGroup.TRAVELER ? travelerPhotos : providerPhotos;
  if (photos && photos.length > 0) {
    photos.forEach((photo) => {
      mediaArray.push({
        kind: MediaAssetKindEnum.Image,
        url: photo,
      } as MediaAsset);
    });
  }

  if (isSkeleton || !providerPhotos || !travelerPhotos) {
    return (
      <Skeleton className="experiences-shop-photo-gallery-modal-main-image-container skeleton" />
    );
  }

  let priceTitle = "";
  let rewardsTitle = "";

  if (priceBreakdown) {
    let chosenLineItem = priceBreakdown.lineItems.find(
      (item) => item.specificAgeBand.ageBand === ExperiencesAgeBandEnum.ADULT
    );
    if (!chosenLineItem) {
      // eslint-disable-next-line prefer-destructuring
      chosenLineItem = priceBreakdown.lineItems[0];
    }
    priceTitle = PRICE_TITLE_TEXT(
      getPriceString({
        price: chosenLineItem.specificAgeBand.pricePerTraveler.fiat.value,
        currencySymbol:
          chosenLineItem.specificAgeBand.pricePerTraveler.fiat.currencySymbol,
      }),
      chosenLineItem.specificAgeBand.ageBand.toLowerCase()
    );
    rewardsTitle = largestValueAccount
      ? `${getRewardText({
          reward:
            chosenLineItem.specificAgeBand.pricePerTraveler.rewards[
              largestValueAccount.accountReferenceId
            ],
        })}`
      : "";
  } else if (ageBands && ageBands.Adult) {
    priceTitle = PRICE_TITLE_TEXT(
      getPriceString({
        price: ageBands.Adult.pricePerTraveler.fiat.value,
        currencySymbol: ageBands.Adult.pricePerTraveler.fiat.currencySymbol,
      }),
      "adult"
    );
    rewardsTitle = largestValueAccount
      ? `${getRewardText({
          reward:
            ageBands.Adult.pricePerTraveler.rewards[
              largestValueAccount.accountReferenceId
            ],
        })}`
      : "";
  }

  const subtitle = experienceMaxTravelers
    ? MAX_TRAVELER_TEXT(experienceMaxTravelers)
    : undefined;

  const renderGalleryInfo = () => (
    <Box className={styles["experiences-photo-gallery-info"]}>
      <Box className={styles["review-duration-container"]}>
        <AvailabilityReviewRating
          reviewCount={rating?.numberOfReviews}
          scaledScore={roundToNearestHalf(rating?.reviewAverage ?? 0)}
          shortReviews
        />
        <Divider orientation="vertical" />
        <AvailabilityDuration duration={duration} isSkeleton={isSkeleton} />
      </Box>
      <Box className={styles["exp-name"]}>{experienceName}</Box>
      {isFreeCancellation && (
        <Box className={styles["free-cancellation-container"]}>
          <Icon name={IconName.CheckCircleBorderThick} /> {FREE_CANCELLATION}
        </Box>
      )}
      <Box>
        <Box className={styles["photo-gallery-price-line"]}>
          <Typography className={styles["photo-gallery-price-info"]}>
            <strong>{priceTitle}</strong>
          </Typography>
          {rewardsTitle && <>&nbsp;/&nbsp;{rewardsTitle}</>}
        </Box>
        <Typography variant="caption">{subtitle}</Typography>
      </Box>
      <ActionButton
        className={styles["photo-gallery-cta-button"]}
        onClick={() => {
          setOpenModal(false);
          setTimeout(() => {
            document
              .getElementsByClassName("continue-button")[0]
              ?.scrollIntoView({ behavior: "smooth", block: "end" });
          }, 300);
        }}
        message={BOOK_NOW}
      />
    </Box>
  );

  const renderProviderPhotoGallery = () => (
    <Box
      id="experiences-shop-photo-gallery-modal-container"
      className={
        styles["experiences-shop-provider-photo-gallery-modal-container"]
      }
    >
      <Box id="photo-gallery" className={styles["photo-gallery"]}>
        <PhotoLayout
          imgUrls={providerPhotos}
          scrollableTarget="photo-gallery"
        />
      </Box>
      {renderGalleryInfo()}
    </Box>
  );

  const renderTravelerPhotoGallery = () => (
    <Box>
      <Box
        className={
          styles["experiences-shop-traveler-photo-gallery-modal-container"]
        }
      >
        <Carousel
          imageUrlsArray={travelerPhotos}
          initialIndex={selectedTravelerPhotoIdx}
          onNavigateToPrevSlide={() => {
            const newSlideIdx = selectedTravelerPhotoIdx - 1;
            setSelectedTravelerPhotoIdx(
              newSlideIdx < 0 ? travelerPhotos.length - 1 : newSlideIdx
            );
          }}
          onNavigateToNextSlide={() => {
            const newSlideIdx = selectedTravelerPhotoIdx + 1;
            setSelectedTravelerPhotoIdx(
              newSlideIdx > travelerPhotos.length - 1 ? 0 : newSlideIdx
            );
          }}
          hideDots
        />
        {renderGalleryInfo()}
      </Box>
      <Box className={styles["experiences-shop-traveler-photo-container"]}>
        <Box className={styles["slider-container"]}>
          {travelerPhotos.map((photoSrc, photoIdx) => (
            <img
              key={photoSrc}
              src={photoSrc}
              className={clsx({
                [styles["selected-img"]]: photoIdx === selectedTravelerPhotoIdx,
              })}
              onClick={() => setSelectedTravelerPhotoIdx(photoIdx)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );

  const renderDesktopPhotos = () => (
    <Box className={styles["experiences-shop-photo-gallery-modal"]}>
      <Box
        className={
          styles["experiences-shop-photo-gallery-modal-main-image-container"]
        }
        onClick={() => {
          setOpenModal(!openModal);
        }}
      >
        <Box
          className={
            styles[
              "experiences-shop-photo-gallery-modal-main-image-section-left"
            ]
          }
        >
          <img
            className={styles["main-image-left"]}
            src={providerPhotos[0]}
            alt="experiences-1"
          />
        </Box>
        <Box
          className={
            styles[
              "experiences-shop-photo-gallery-modal-main-image-section-right"
            ]
          }
        >
          <Box
            className={clsx(styles["main-image-right"], styles["top-image"])}
          >
            <img src={providerPhotos[1]} alt="experiences-2" />
          </Box>
          <Box
            className={clsx(styles["main-image-right"], styles["bottom-image"])}
          >
            <img src={providerPhotos[2]} alt="experiences-3" />
          </Box>
        </Box>
        <Button
          className={styles["show-all-photos-button"]}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <Icon name={IconName.ShowAllImagesIcon} />
          <span className={styles["show-all-photos-button-label"]}>
            {SHOW_ALL_PHOTOS_BUTTON_LABEL(
              providerPhotos.length + travelerPhotos.length
            )}
          </span>
        </Button>
      </Box>
      <DesktopPopupModal
        className={styles["experiences-shop-photo-gallery-modal-popup"]}
        open={openModal}
        invisibleBackdrop={false}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setOpenModal(false);

          // This is needed to show the correct photo counts for the default selected group
          if (selectedPhotoGroup !== selectedGroup) {
            setSelectedPhotoGroup(selectedGroup);
          }
        }}
      >
        <Box
          className={styles["experiences-shop-photo-gallery-header-container"]}
        >
          <Box className={styles["experiences-shop-photo-gallery-title"]}>
            {ALL_PHOTOS} ({providerPhotos.length + travelerPhotos.length})
          </Box>
          <Typography
            variant="body1"
            className={styles["experiences-shop-photo-gallery-subtitle"]}
          >
            {REVIEWS_POWERED_BY}
            <Icon name={IconName.TripAdvisorIcon} />
            <strong>{TRIPADVISOR}</strong>
          </Typography>
        </Box>
        <Box className={styles["photo-group-button-container"]}>
          <Button
            className={clsx(styles["photo-group-button"], {
              [styles.selected]: selectedPhotoGroup === PhotoGroup.PROVIDER,
            })}
            onClick={() => {
              setSelectedPhotoGroup(PhotoGroup.PROVIDER);
            }}
          >
            {PROVIDER_PHOTOS} ({providerPhotos.length})
          </Button>
          <Button
            className={clsx(styles["photo-group-button"], {
              [styles.selected]: selectedPhotoGroup === PhotoGroup.TRAVELER,
            })}
            onClick={() => {
              setSelectedPhotoGroup(PhotoGroup.TRAVELER);
            }}
          >
            {TRAVELERS_PHOTOS} ({travelerPhotos.length})
          </Button>
        </Box>
        {selectedPhotoGroup === PhotoGroup.PROVIDER
          ? renderProviderPhotoGallery()
          : renderTravelerPhotoGallery()}
      </DesktopPopupModal>
    </Box>
  );

  const renderMobilePhotos = () => (
    <AvailabilityCarousel
      media={mediaArray}
      isSkeleton={isSkeleton}
      hideArrows={false}
    />
  );

  return (
    <Box
      className={clsx(styles["experiences-shop-photo-gallery"], {
        [styles.mobile]: mobile,
      })}
    >
      {mobile ? renderMobilePhotos() : renderDesktopPhotos()}
    </Box>
  );
};
