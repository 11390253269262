import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import { ExperiencesBook } from "./component";
import {
  getExperiencesShopDetails,
  getExperiencesShopSelectedPriceBreakdown,
  getTravelerCounts,
} from "../shop/reducer";

const mapStateToProps = (state: IStoreState) => ({
  shopDetails: getExperiencesShopDetails(state),
  priceBreakdown: getExperiencesShopSelectedPriceBreakdown(state),
  travelerCounts: getTravelerCounts(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesBookConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedExperiencesBook = withRouter(connector(ExperiencesBook));
