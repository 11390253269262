import {
  getBookableLangGuides,
  getHasPickupLocationField,
  getPickupPointStates,
  getSelectedLangGuide,
} from "../selectors";

import { ExperiencesContext } from "../../../../modules/book/state/types";
import {
  HOTEL_OPTION_NOT_AVAILABLE_LABEL,
  LangGuideValidation,
  PickupPointValidation,
} from "../types";
import { PickupPointOption } from "../context";

export const validateAdditionalDetailsService = (context: ExperiencesContext) =>
  new Promise((resolve, reject) => {
    const validationErrors = [];
    const hasPickupLocationField = getHasPickupLocationField({ context });
    const bookableLangGuides = getBookableLangGuides({ context });
    const selectedLangGuide = getSelectedLangGuide({ context });

    if (hasPickupLocationField) {
      const {
        selectedPickupPoint,
        hotelSelection,
        hotelName,
        address,
        city,
        zipCode,
      } = getPickupPointStates({ context });

      if (!selectedPickupPoint) {
        validationErrors.push(PickupPointValidation.PickupPointSelection);
      }

      if (
        selectedPickupPoint === PickupPointOption.SelectPickup &&
        !hotelSelection
      ) {
        validationErrors.push(PickupPointValidation.HotelSelection);
      }

      if (
        hotelSelection?.name === HOTEL_OPTION_NOT_AVAILABLE_LABEL &&
        (!hotelName || !address || !city || !zipCode)
      ) {
        validationErrors.push(PickupPointValidation.EnterPickupLocation);
      }
    }

    if (bookableLangGuides.length > 1 && !selectedLangGuide) {
      validationErrors.push(LangGuideValidation.LangSelection);
    }

    if (validationErrors.length > 0) {
      reject(validationErrors);
    } else {
      resolve([]);
    }
  });
