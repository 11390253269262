// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xGzOviEZaJ5gQvZ-8JebWg\\=\\={padding-top:20px;display:flex;flex-direction:column;gap:10px}.xGzOviEZaJ5gQvZ-8JebWg\\=\\= .LPN16E\\+Zg\\+dU16p4Dzesxg\\=\\={display:flex;align-items:center;gap:15px}.nDnc3GMNG8oipSQA18aa2A\\=\\={padding-top:20px;margin-top:20px;border-top:.5px solid var(--grey-11)}.nDnc3GMNG8oipSQA18aa2A\\=\\= ul{padding-inline-start:20px;list-style:initial;padding-top:20px}.nDnc3GMNG8oipSQA18aa2A\\=\\= ul li{padding-bottom:10px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/experiences-module/src/modules/shop/components/ShopWhatsIncluded/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,0DACE,YAAA,CACA,kBAAA,CACA,QAAA,CAIJ,4BACE,gBAAA,CACA,eAAA,CACA,oCAAA,CAEA,+BACE,yBAAA,CACA,kBAAA,CACA,gBAAA,CAEA,kCACE,mBAAA","sourcesContent":[".icon-content-list {\n  padding-top: 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n\n  .icon-description-container {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n  }\n}\n\n.segment-container {\n  padding-top: 20px;\n  margin-top: 20px;\n  border-top: 0.5px solid var(--grey-11);\n\n  ul {\n    padding-inline-start: 20px;\n    list-style: initial;\n    padding-top: 20px;\n\n    li {\n      padding-bottom: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-content-list": "xGzOviEZaJ5gQvZ-8JebWg==",
	"icon-description-container": "LPN16E+Zg+dU16p4Dzesxg==",
	"segment-container": "nDnc3GMNG8oipSQA18aa2A=="
};
export default ___CSS_LOADER_EXPORT___;
