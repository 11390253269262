import { ADD_TRAVELER } from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export const trackNewTraveler = () =>
  new Promise((resolve) => {
    trackEvent({
      eventName: ADD_TRAVELER,
      properties: {},
    })
      .then(() => resolve(null))
      .catch(() => resolve(null));
  });
