import { ParentState } from "@capone/checkout";
import {
  CART_UPDATE_TEXT,
  LOADING_GENERIC,
  LOADING_TRAVELERS,
} from "./textConstants";
import { TravelerInformationState } from "../../../../checkout/states/TravelerInformation/types";

export const getLoadingMessage = (
  parentState: ParentState,
  childState?: string
) => {
  switch (true) {
    case parentState === ParentState.experiencesTravelerInformation &&
      childState === TravelerInformationState.loading:
      return LOADING_TRAVELERS;
    case parentState === ParentState.loading:
      return LOADING_GENERIC;
    case parentState === ParentState.cartUpdate:
      return CART_UPDATE_TEXT;
    default:
      return undefined;
  }
};
