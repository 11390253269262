// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ne1PM2Of9FVRHh9THui3nA\\=\\=.TPS-G2Xk8CVZ9Vmfmi0kLQ\\=\\={width:100%}.g\\+ZQWH671Pj8snrml3NEOw\\=\\={padding-top:20px}.Gre2-gt5XqlOepFmC7BBRw\\=\\={display:grid;grid-template-columns:1fr 1fr;gap:10px}.Gre2-gt5XqlOepFmC7BBRw\\=\\= ._4UWP-vLaGHMVUiXsatummg\\=\\={width:12px;height:20px}.Gre2-gt5XqlOepFmC7BBRw\\=\\= ._4UWP-vLaGHMVUiXsatummg\\=\\= path{fill:#0276b1}.Gre2-gt5XqlOepFmC7BBRw\\=\\= .vWJjFm2BOJzeOZgTMNx6DQ\\=\\={display:flex;flex-direction:column;align-items:center;border:.5px solid #cecfcd;border-radius:4px;padding:20px;gap:5px;margin-top:30px}.Gre2-gt5XqlOepFmC7BBRw\\=\\=.TPS-G2Xk8CVZ9Vmfmi0kLQ\\=\\={display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./../../cap1-modules/experiences-module/src/modules/common/ShopContactInformation/styles.module.scss"],"names":[],"mappings":"AACE,uDACE,UAAA,CAGJ,6BACE,gBAAA,CAGF,4BACE,YAAA,CACA,6BAAA,CACA,QAAA,CAEA,yDACE,UAAA,CACA,WAAA,CAEA,8DACE,YAAA,CAIJ,wDACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,yBAAA,CACA,iBAAA,CACA,YAAA,CACA,OAAA,CACA,eAAA,CAGF,uDACE,YAAA,CACA,qBAAA","sourcesContent":[".contact-info-container {\n  &.mobile {\n    width: 100%;\n  }\n}\n.description-container {\n  padding-top: 20px;\n}\n\n.contact-methods-container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 10px;\n\n  .phone-icon {\n    width: 12px;\n    height: 20px;\n\n    path {\n      fill: #0276b1;\n    }\n  }\n\n  .contact-method-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border: 0.5px solid #cecfcd;\n    border-radius: 4px;\n    padding: 20px;\n    gap: 5px;\n    margin-top: 30px;\n  }\n\n  &.mobile {\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact-info-container": "ne1PM2Of9FVRHh9THui3nA==",
	"mobile": "TPS-G2Xk8CVZ9Vmfmi0kLQ==",
	"description-container": "g+ZQWH671Pj8snrml3NEOw==",
	"contact-methods-container": "Gre2-gt5XqlOepFmC7BBRw==",
	"phone-icon": "_4UWP-vLaGHMVUiXsatummg==",
	"contact-method-container": "vWJjFm2BOJzeOZgTMNx6DQ=="
};
export default ___CSS_LOADER_EXPORT___;
