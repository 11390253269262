import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Box, Button, Divider, Typography } from "@material-ui/core";

import { useCheckoutStateSelector as useSelector } from "@capone/checkout";
import {
  AvailabilityDuration,
  AvailabilityLanguage,
  AvailabilityReviewRating,
  ExperienceTag,
  Icon,
  IconName,
  roundToNearestHalf,
  useDeviceTypes,
} from "halifax";
import { ExperienceBadge } from "redmond";

import styles from "./styles.module.scss";
import { ExperiencesSelectors } from "../../state/selectors";

import {
  CHANGE_CTA_TEXT,
  DATE,
  START_TIME,
  TOTAL_NUM_TRAVELERS,
  TRAVELERS,
} from "../../../common/textConstants";
import { BadgeExcellenceModal, getTotalTravelersCount } from "../../../common";
import { PATH_SHOP } from "../../../../utils/paths";

interface ExperienceSummaryPanelProps {
  displayChangeBtn?: boolean;
}

export const ExperienceSummaryPanel = ({
  displayChangeBtn = false,
}: ExperienceSummaryPanelProps) => {
  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();
  const expShop = useSelector(ExperiencesSelectors.getExperienceShop);
  const travelerCounts = useSelector(
    ExperiencesSelectors.getExperienceTravelerCounts
  );
  const priceBreakdown = useSelector(
    ExperiencesSelectors.getExperiencePriceBreakdown
  );
  const [isBadgeExcellenceModalOpen, setBadgeExcellenceModalOpen] =
    useState(false);

  if (!expShop || !priceBreakdown) return null;

  const {
    rating,
    duration,
    coverImage,
    languageGuides,
    supplierInfo,
    badges,
    name,
  } = expShop;

  const { date, startTime } = priceBreakdown;

  return (
    <Box className={styles["experience-summary-panel-container"]}>
      <Box>
        <img
          src={coverImage}
          alt=""
          className={styles["experience-summary-image"]}
        />
      </Box>
      <Box className={styles["info-section"]}>
        <Box className={styles["top-section"]}>
          <Box className={styles["experience-info"]}>
            <AvailabilityReviewRating
              reviewCount={rating.numberOfReviews}
              scaledScore={roundToNearestHalf(rating.reviewAverage)}
              shortReviews
            />
            {duration && (
              <>
                <Divider
                  className={styles["vertical-dividers"]}
                  orientation="vertical"
                />
                <AvailabilityDuration duration={duration} />
              </>
            )}
            {languageGuides && languageGuides.length && (
              <>
                <Divider
                  className={styles["vertical-dividers"]}
                  orientation="vertical"
                />
                <AvailabilityLanguage languages={languageGuides} />
              </>
            )}
            {supplierInfo && (
              <>
                <Divider
                  className={styles["vertical-dividers"]}
                  orientation="vertical"
                />
                <Typography className={styles["experience-name-container"]}>
                  {supplierInfo.name}
                </Typography>
              </>
            )}
          </Box>
          <Box className={styles["experience-title"]}>{name}</Box>
          {badges && badges.length > 0 && (
            <Box className={styles["experience-tags-container"]}>
              {badges.map((badge) => (
                <ExperienceTag
                  key={badge}
                  badge={badge}
                  onBadgeClick={
                    badge === ExperienceBadge.BadgeOfExcellence
                      ? () => {
                          if (!isBadgeExcellenceModalOpen)
                            setBadgeExcellenceModalOpen(true);
                        }
                      : undefined
                  }
                />
              ))}
            </Box>
          )}
        </Box>
        <Box className={styles["bottom-section"]}>
          <Box className={styles["left-section"]}>
            <Box className={styles["booking-option-item"]}>
              <Icon name={IconName.Calendar} />
              <Box>
                <Box className={styles["option-header"]}>{DATE}</Box>
                <Box className={styles["option-value"]}>
                  {dayjs(date).format("ddd, MMM D")}
                </Box>
              </Box>
            </Box>
            <Divider
              className={styles["vertical-dividers"]}
              orientation="vertical"
            />
            <Box className={styles["booking-option-item"]}>
              <Icon name={IconName.ClockIcon} />
              <Box>
                <Box className={styles["option-header"]}>{START_TIME}</Box>
                <Box className={styles["option-value"]}>
                  {dayjs(startTime, "HH:mm").format("h:mm A")}
                </Box>
              </Box>
            </Box>
            <Divider
              className={styles["vertical-dividers"]}
              orientation="vertical"
            />
            <Box className={styles["booking-option-item"]}>
              <Icon name={IconName.B2BUser} />
              <Box>
                <Box className={styles["option-header"]}>{TRAVELERS}</Box>
                <Box className={styles["option-value"]}>
                  {TOTAL_NUM_TRAVELERS(getTotalTravelersCount(travelerCounts))}
                </Box>
              </Box>
            </Box>
          </Box>
          {displayChangeBtn && (
            <Box className={styles["right-section"]}>
              <Button
                variant="text"
                color="primary"
                onClick={() =>
                  history.push(`${PATH_SHOP}${history.location.search}`)
                }
              >
                {CHANGE_CTA_TEXT}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <BadgeExcellenceModal
        isOpen={isBadgeExcellenceModalOpen}
        isMobile={matchesMobile}
        onClose={() => {
          setBadgeExcellenceModalOpen(false);
        }}
      />
    </Box>
  );
};
