import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { ShopComponentSkeleton } from "../../component";
import { ActionButton, getPriceString, getRewardText } from "halifax";
import { PATH_BOOK } from "../../../../utils/paths";
import {
  ExperiencesAgeBandEnum,
  ExperiencesPriceBreakdown,
  RewardsAccount,
  SpecificAgeBand,
} from "redmond";
import { ExperiencesPricingBreakdown } from "../../../common";
import { IShopCancellationPolicyProps } from "../ShopCancellationPolicy/component";
import {
  CONTINUE_TO_CHECKOUT,
  getPriceAndRewardsCopy,
  MAX_TRAVELER_TEXT,
  PRICE_TITLE_TEXT,
} from "../../textConstants";

export interface IShopCheckoutBreakdown {
  isSkeleton: boolean;
  priceBreakdown?: ExperiencesPriceBreakdown;
  experienceMaxTravelers?: number;
  isFreeCancellation?: boolean;
}

export interface IShopCheckoutBreakdownProps extends IShopCheckoutBreakdown {
  ageBands?: {
    [key in ExperiencesAgeBandEnum]?: SpecificAgeBand;
  };
  largestValueAccount?: RewardsAccount;
  cancellationPolicyProps: IShopCancellationPolicyProps | null;
  mobile?: boolean;
  onlyPrice?: boolean;
}

export const ShopCheckoutBreakdown = (props: IShopCheckoutBreakdownProps) => {
  const history = useHistory();
  const {
    isSkeleton,
    priceBreakdown,
    ageBands,
    experienceMaxTravelers,
    largestValueAccount,
    cancellationPolicyProps,
    isFreeCancellation = false,
    mobile = false,
    onlyPrice = false,
  } = props;

  let priceTitle = "";
  let rewardsTitle = "";

  if (priceBreakdown) {
    let chosenLineItem = priceBreakdown.lineItems.find((item) => {
      return item.specificAgeBand.ageBand === ExperiencesAgeBandEnum.ADULT;
    });
    if (!chosenLineItem) {
      chosenLineItem = priceBreakdown.lineItems[0];
    }
    priceTitle = PRICE_TITLE_TEXT(
      getPriceString({
        price: chosenLineItem.specificAgeBand.pricePerTraveler.fiat.value,
        currencySymbol:
          chosenLineItem.specificAgeBand.pricePerTraveler.fiat.currencySymbol,
      }),
      chosenLineItem.specificAgeBand.ageBand.toLowerCase()
    );
    rewardsTitle = largestValueAccount
      ? `${getRewardText({
          reward:
            chosenLineItem.specificAgeBand.pricePerTraveler.rewards[
              largestValueAccount.accountReferenceId
            ],
        })}`
      : "";
  } else if (ageBands && ageBands.Adult) {
    priceTitle = PRICE_TITLE_TEXT(
      getPriceString({
        price: ageBands.Adult.pricePerTraveler.fiat.value,
        currencySymbol: ageBands.Adult.pricePerTraveler.fiat.currencySymbol,
      }),
      "adult"
    );
    rewardsTitle = largestValueAccount
      ? `${getRewardText({
          reward:
            ageBands.Adult.pricePerTraveler.rewards[
              largestValueAccount.accountReferenceId
            ],
        })}`
      : "";
  }

  const subtitle = experienceMaxTravelers
    ? MAX_TRAVELER_TEXT(experienceMaxTravelers)
    : undefined;

  const renderSkeleton = () => (
    <Box className={styles["experiences-shop-skeleton"]}>
      <Box className={styles["section-wrapper"]}>
        <ShopComponentSkeleton name="medium" />
        <ShopComponentSkeleton name="large" />
        <ShopComponentSkeleton name="small" />
      </Box>
    </Box>
  );

  return (
    <Box
      className={clsx(
        styles["experiences-shop-checkout-breakdown-container"],
        "experiences-shop-checkout-breakdown-container"
      )}
    >
      <Box
        className={clsx(
          styles["checkout-breakdown-section"],
          "checkout-breakdown-section"
        )}
      >
        <Typography
          className={styles["experiences-shop-checkout-breakdown-title"]}
          dangerouslySetInnerHTML={{
            __html: getPriceAndRewardsCopy({
              price: priceTitle,
              rewards: rewardsTitle,
            }),
          }}
        />
        {subtitle && (
          <Typography
            className={styles["experiences-shop-checkout-breakdown-subtitle"]}
            variant={"body2"}
          >
            {subtitle}
          </Typography>
        )}
        {isSkeleton || !priceBreakdown ? (
          renderSkeleton()
        ) : !onlyPrice ? (
          <>
            <ExperiencesPricingBreakdown
              className={clsx(styles["experiences-price-breakdown"])}
              priceBreakdown={priceBreakdown}
              largestValueAccount={largestValueAccount}
              isFreeCancellation={isFreeCancellation}
              cancellationPolicyProps={cancellationPolicyProps}
            />
            {!mobile && (
              <ActionButton
                className={styles["continue-button"]}
                message={CONTINUE_TO_CHECKOUT}
                onClick={() =>
                  history.push(`${PATH_BOOK}${history.location.search}`)
                }
              />
            )}
          </>
        ) : null}
      </Box>
    </Box>
  );
};
