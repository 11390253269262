import {
  CardPaymentEventTypes,
  CartQuoteEventType,
  CartQuoteState,
  CartState,
  ContactEventType,
  ParentState,
  PaymentInformationChildState,
  PaymentInformationState,
  RewardsPaymentEventTypes,
  WalletEventTypes,
  getCartFulfillMachine,
  getCartQuoteMachine,
  getCartUpdateMachine,
  getContactInformationMachine,
  getReviewMachineState,
} from "@capone/checkout";
import { MachineConfig } from "xstate";

import { ActionTypes } from "./actions";
import { Event, TEvent } from "./events";
import { getInitialExperiencesMachineContext } from "./initialContext";
import { Services } from "./services";
import { ExperiencesMachineContext } from "./types";
import { GuardTypes } from "./guards";
import {
  getExperiencesTravelerMachine,
  getExperiencesAdditionalDetailsMachine,
  getCardPaymentMachine,
} from "../../../checkout";
import {
  TravelerInformationChildState,
  TravelerInformationState,
} from "../../../checkout/states/TravelerInformation/types";

export const experiencesMachineId = "capone-experiences-checkout";

export const experiencesBookStateMachine: MachineConfig<
  ExperiencesMachineContext,
  any,
  TEvent
> = {
  schema: {
    context: getInitialExperiencesMachineContext(),
    events: {} as TEvent,
  },

  // Machine identifier
  id: experiencesMachineId,

  // Initial state
  initial: ParentState.loading,

  // State definitions
  states: {
    [ParentState.loading]: {
      initial: "fetchContactInfo",
      states: {
        fetchContactInfo: {
          invoke: {
            src: Services.fetchContactInfoService,
            onDone: {
              target: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
              actions: ActionTypes.setContactInfo,
            },
            onError: {
              target: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
            },
          },
        },
      },
    },
    [ParentState.experiencesTravelerInformation]: {
      ...getExperiencesTravelerMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.experiencesPrimaryTraveler}`,
        nextStateFromReview: `#${experiencesMachineId}.${ParentState.experiencesPrimaryTraveler}`,
      }),
    },
    [ParentState.contactInformation]: {
      ...getContactInformationMachine({
        parentMachineId: experiencesMachineId,
        previousState: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
        onDone: [
          {
            target: `#${experiencesMachineId}.${ParentState.experiencesPrimaryTraveler}`,
          },
        ],
        onError: [
          {
            target: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
          },
        ],
      }),
    },
    [ParentState.experiencesPrimaryTraveler]: {
      initial: TravelerInformationState.travelerPicker,
      states: {
        [TravelerInformationState.travelerPicker]: {
          initial: TravelerInformationChildState.idle,
          states: {
            [TravelerInformationChildState.idle]: {},
          },
        },
        [TravelerInformationState.error]: {},
      },
      on: {
        [Event.NEXT]: [
          {
            target: `#${experiencesMachineId}.${ParentState.experiencesAdditionalDetails}`,
            cond: GuardTypes.hasAdditionalDetailsStep,
          },
          {
            target: `#${experiencesMachineId}.${ParentState.cartQuote}`,
            cond: GuardTypes.isDesktop,
          },
          {
            target: `#${experiencesMachineId}.${ParentState.contactInformation}`,
            cond: GuardTypes.isMobile,
          },
        ],
        [Event.PREVIOUS]: [
          {
            target: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
          },
        ],
        [Event.SELECT_TRAVELER]: [
          {
            actions: ActionTypes.selectPrimaryTraveler,
          },
        ],
      },
    },
    [ParentState.experiencesAdditionalDetails]: {
      ...getExperiencesAdditionalDetailsMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cartQuote}`,
        nextStateFromReview: `#${experiencesMachineId}.${ParentState.cartQuote}`,
      }),
    },
    [ParentState.cartQuote]: {
      entry: ActionTypes.addExperienceProduct,
      ...getCartQuoteMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cardPayment}`,
      }),
      // TODO
      // exit: [ActionTypes.checkForPriceChange],
    },
    [ParentState.cardPayment]: {
      ...getCardPaymentMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        previousState: `#${experiencesMachineId}.${ParentState.experiencesPrimaryTraveler}`,
      }),
    },
    [ParentState.review]: {
      ...getReviewMachineState({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        previousState: `#${experiencesMachineId}.${ParentState.cardPayment}`,
      }),
    },
    [ParentState.cartUpdateBeforeFulfill]: {
      ...getCartUpdateMachine({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.cartFulfill}`,
      }),
    },
    [ParentState.cartFulfill]: {
      entry: [
        ActionTypes.setPaymentFulfillParams,
        ActionTypes.setPrepareDetailsFulfillParams,
      ],
      ...getCartFulfillMachine<ExperiencesMachineContext>({
        parentMachineId: experiencesMachineId,
        nextState: `#${experiencesMachineId}.${ParentState.bookingConfirmation}`,
        // TODO add these back when we start tracking
        // trackPollSuccessAction: trackPollSuccess({
        //   eventName: "complete_buy_experience",
        //   // trackProperties returned in pollfulfill response will be also added
        //   getAdditionalProperties: (context) => {
        //     return {
        //       total_card_amount_usd:
        //         CardPaymentSelectors.getTotalCardPaymentRequired({ context }),

        //       total_rewards_amount_usd:
        //         RewardsPaymentSelectors.getRewardsFiatAmountToApply({
        //           context,
        //         })?.value || 0,

        //       credit_amt_redeemed: Math.abs(
        //         WalletSelectors.getTravelWalletCreditToApply({ context })
        //           ?.amount.amount || 0
        //       ),
        //       credit_redeemed: !!WalletSelectors.getTravelWalletCreditToApply({
        //         context,
        //       })?.amount.amount,
        //     };
        //   },
        //   getAdditionalEncryptedProperties: (context) => {
        //     const encryptedProperties: string[] = [];

        //     const selectedOffer = WalletSelectors.getSelectedOffer({ context });

        //     if (selectedOffer?.trackingPropertiesV2?.encryptedProperties) {
        //       encryptedProperties.push(
        //         selectedOffer.trackingPropertiesV2.encryptedProperties
        //       );
        //     }

        //     return encryptedProperties;
        //   },
        // }),
        // trackPollFailureAction: trackPollFailure({
        //   eventName: "complete_buy_experience",
        //   // trackProperties returned in pollfulfill response will be also added
        //   getAdditionalProperties: (_, __) => {
        //     return {};
        //   },
        // }),
      }),
    },
    [ParentState.bookingConfirmation]: {},
  },
  on: {
    [Event.OPEN_TRAVELER_PICKER]: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
    // wallet credit Payment Events,
    [WalletEventTypes.SET_SELECTED_OFFER]: {
      actions: ActionTypes.addDiscounts,
    },
    [WalletEventTypes.REMOVE_SELECTED_OFFER]: {
      actions: ActionTypes.removeDiscounts,
    },
    [WalletEventTypes.SET_CREDIT_AMOUNT_TO_APPLY]: {
      actions: ActionTypes.setCreditAmountToApply,
    },
    // Rewards Payment Events,
    [RewardsPaymentEventTypes.SET_SELECTED_REWARDS_ACCOUNT_ID]: {
      actions: ActionTypes.setSelectedRewardsAccountId,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_FIAT_AMOUNT_TO_PAY]: {
      actions: ActionTypes.setRewardsFiatAmountToPay,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_AMOUNT_TO_PAY]: {
      actions: ActionTypes.setRewardsAmountToPay,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_PAYMENT_VISITED]: {
      actions: ActionTypes.setRewardsPaymentVisited,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_ACCOUNTS]: {
      actions: ActionTypes.setRewardsAccounts,
    },
    [RewardsPaymentEventTypes.SET_EARN_BY_REWARDS_ACCOUNT_ID]: {
      actions: ActionTypes.setEarnValueForAccount,
    },
    // Card Payment Events
    [CardPaymentEventTypes.SET_SELECTED_PAYMENT_METHOD_ID]: {
      actions: ActionTypes.setSelectedPaymentMethodId,
    },
    [CardPaymentEventTypes.DELETE_PAYMENT_METHOD]: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.delete}`,
    [CardPaymentEventTypes.OPEN_PAYMENT_FORM]: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}`,
    [CardPaymentEventTypes.CLOSE_PAYMENT_FORM]: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
    [CardPaymentEventTypes.VERIFY_PAYMENT_METHOD]: {
      actions: ActionTypes.setSpreedlyToken,
      target: `#${experiencesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}.${PaymentInformationChildState.verify}`,
    },
    [CardPaymentEventTypes.ACKNOWLEDGE_PRICE_CHANGE]: {
      actions: ActionTypes.acknowledgePriceChange,
    },
    // Contact Information Events
    [ContactEventType.CHANGE]: {
      actions: ActionTypes.setContactInfo,
    },
    // Events below are used for browser navigation
    [Event.GO_TO_TRAVELER_SELECT]: `#${experiencesMachineId}.${ParentState.experiencesTravelerInformation}`,
    [Event.GO_TO_CONTACT_INFORMATION]: `#${experiencesMachineId}.${ParentState.contactInformation}`,
    [Event.GO_TO_PRIMARY_TRAVELER_SELECT]: `#${experiencesMachineId}.${ParentState.experiencesPrimaryTraveler}`,
    [Event.GO_TO_ADDITIONAL_DETAILS]: `#${experiencesMachineId}.${ParentState.experiencesAdditionalDetails}`,
    [Event.GO_TO_CART_QUOTE]: `#${experiencesMachineId}.${ParentState.cartQuote}`,
    [Event.GO_TO_REVIEW]: `#${experiencesMachineId}.${ParentState.review}`,
    [Event.GO_TO_CARD_PAYMENT]: `#${experiencesMachineId}.${ParentState.cardPayment}`,
    [Event.GO_TO_BOOKING_CONFIRMATION]: `#${experiencesMachineId}.${ParentState.bookingConfirmation}`,
    [Event.SET_PLATFORM]: {
      actions: ActionTypes.setPlatform,
    },
    [CartQuoteEventType.RETRY_QUOTE]: {
      target: `#${experiencesMachineId}.${CartState.cartQuote}.${CartQuoteState.route}`,
      actions: [ActionTypes.setQuoteRetries, ActionTypes.clearCartQuoteError],
    },
  },
};
