import {
  CallState,
  ExperienceId,
  ExperiencesPriceBreakdown,
  ExperiencesShopResponse,
  ExperiencesShopReviewsResponse,
  IPassengerCounts,
} from "redmond";
import { actions, actionTypes } from "../actions";

export interface IExperiencesShopState {
  selectedExperienceId?: ExperienceId;
  experiencesShopCallState: CallState;
  experiencesShopDetails?: ExperiencesShopResponse;
  experiencesShopOptionsCallState: CallState;
  selectedExperiencePriceBreakdown?: ExperiencesPriceBreakdown;
  experiencesShopReviewsCallState: CallState;
  selectedExperienceReviews?: ExperiencesShopReviewsResponse;
  travelerCounts: IPassengerCounts;
}

export const initialState: IExperiencesShopState = {
  experiencesShopCallState: CallState.NotCalled,
  experiencesShopOptionsCallState: CallState.NotCalled,
  experiencesShopReviewsCallState: CallState.NotCalled,
  travelerCounts: {
    adultsCount: 2,
    seniorsCount: 0,
    youthsCount: 0,
    childrenCount: 0,
    infantsInSeatCount: 0,
    infantsOnLapCount: 0,
  },
};

export const reducer = (
  state: IExperiencesShopState = initialState,
  action: actions.ExperiencesShopActions
): IExperiencesShopState => {
  switch (action.type) {
    case actionTypes.FETCH_EXPERIENCES_SHOP:
      return {
        ...state,
        experiencesShopCallState: CallState.InProcess,
        experiencesShopDetails: undefined,
      };
    case actionTypes.SET_EXPERIENCES_SHOP_DETAILS:
      return {
        ...state,
        experiencesShopCallState: CallState.Success,
        experiencesShopDetails: action.response,
      };
    case actionTypes.SET_SELECTED_EXPERIENCE:
      return {
        ...state,
        selectedExperienceId: action.experienceId,
      };
    case actionTypes.SET_EXPERIENCES_SHOP_CALL_FAILED:
      return {
        ...state,
        experiencesShopCallState: CallState.Failed,
      };
    case actionTypes.FETCH_EXPERIENCES_SHOP_OPTIONS:
      return {
        ...state,
        experiencesShopOptionsCallState: CallState.InProcess,
      };
    case actionTypes.SET_EXPERIENCES_SHOP_OPTIONS:
      return {
        ...state,
        experiencesShopOptionsCallState: CallState.Success,
        selectedExperiencePriceBreakdown: action.priceBreakdown,
      };
    case actionTypes.SET_EXPERIENCES_SHOP_OPTIONS_CALL_FAILED:
      return {
        ...state,
        experiencesShopOptionsCallState: CallState.Failed,
      };
    case actionTypes.FETCH_EXPERIENCES_SHOP_REVIEWS:
      return {
        ...state,
        experiencesShopReviewsCallState: CallState.InProcess,
      };
    case actionTypes.SET_EXPERIENCES_SHOP_REVIEWS:
      return {
        ...state,
        experiencesShopOptionsCallState: CallState.Success,
        selectedExperienceReviews: action.response,
      };
    case actionTypes.SET_EXPERIENCES_SHOP_REVIEWS_CALL_STATE_FAILED:
      return {
        ...state,
        experiencesShopOptionsCallState: CallState.Failed,
      };
    case actionTypes.SET_TRAVELER_COUNTS:
      return {
        ...state,
        travelerCounts: action.counts,
      };
    default:
      return {
        ...state,
      };
  }
};

export * from "./selectors";
