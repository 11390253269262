import {
  BookingQuestion,
  BookingQuestionId,
  ExperienceLanguageGuide,
  ExperiencesPriceBreakdown,
  ExperiencesShopResponse,
} from "redmond";
import { AdditionalDetailsValidation } from "./types";

export enum PickupPointOption {
  SelectPickup = "select-pickup",
  PickupLater = "pickup-later",
  NoPickup = "no-pickup-needed",
}

export interface ExperiencesAdditionalDetailsContext {
  hasAdditionalDetailsStep: boolean;
  requiredBookingQuestions: BookingQuestion[];

  // pick up fields
  selectedPickupPoint?: PickupPointOption;
  hotelSelection?: any | null; // TBD on option passed to auto complete
  hotelName?: string;
  address?: string;
  city?: string;
  zipCode?: string;

  // lang field
  selectedLangGuide?: ExperienceLanguageGuide;

  // special requirement field
  specialRequirement?: string;

  errors: AdditionalDetailsValidation[];
}

// we only handle these per booking questions in additional steps
const HANDLED_BOOKING_QUESTIONS: BookingQuestionId[] = [
  BookingQuestionId.SpecialRequirements,
  BookingQuestionId.PickupPoint,
];

export const getInitialAdditionalDetailsContext = (
  experienceShop?: ExperiencesShopResponse,
  priceBreakdown?: ExperiencesPriceBreakdown
): ExperiencesAdditionalDetailsContext => {
  const bookingQuestions = experienceShop?.bookingQuestions?.perBooking || [];

  const requiredBookingQuestions = bookingQuestions.filter((question) =>
    HANDLED_BOOKING_QUESTIONS.includes(question.id)
  );

  const langGuides = experienceShop?.bookableItems.find(
    (item) => item.experienceOptionId === priceBreakdown?.optionId
  );

  return {
    hasAdditionalDetailsStep:
      !!requiredBookingQuestions?.length ||
      // there's an associated lang guide for selected experience option and requires user to select a lang guide in additional details step
      !!(langGuides?.languageGuides && langGuides.languageGuides.length > 1),
    requiredBookingQuestions,
    errors: [],
  };
};
