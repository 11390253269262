import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PriceBreakdown } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getRewardsAccountWithLargestValue } from "../../../rewards/reducer";

export const mapStateToProps = (state: IStoreState) => ({
  largestValueAccount: getRewardsAccountWithLargestValue(state),
});

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceBreakdown = withRouter(connector(PriceBreakdown));
