import React from "react";
import { TextField } from "@material-ui/core";
import {
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@capone/checkout";

import styles from "./styles.module.scss";
import { ExperiencesAdditionalDetailsSelectors } from "../../../../../checkout";
import { Event, TEvent } from "../../../state/events";
import { ExperiencesMachineContext } from "../../../state/types";

export const SpecialRequirementArea = () => {
  const [_state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();
  const specialRequirement = useSelector(
    ExperiencesAdditionalDetailsSelectors.getSpecialRequirement
  );

  const setSpecialRequirement = (data: string) => {
    send({
      type: Event.SET_SPECIAL_REQUIREMENT,
      data,
    });
  };

  return (
    <div className={styles["section-container"]}>
      <div className={styles["section-title"]}>
        Special requirements (optional)
      </div>
      <TextField
        variant="outlined"
        placeholder="Include any dietary or accessibility needs."
        aria-label="Include any dietary or accessibility needs."
        multiline
        minRows={3}
        maxRows={3}
        fullWidth
        value={specialRequirement}
        onChange={(e) => {
          setSpecialRequirement(e.target.value);
        }}
      />
    </div>
  );
};
