import { assign } from "xstate";
import { ProductOpaqueValue } from "@b2bportal/purchase-api";

import {
  cartHelpers,
  ContactSelectors,
  ParentState,
  setContextWithKey,
} from "@capone/checkout";
import { CreateExperienceProductRequest } from "redmond";

import { ExperiencesMachineContext } from "../types";
import {
  getExperienceShop,
  getExperiencePriceBreakdown,
  getExperienceTravelerCounts,
} from "../selectors/experiences";
import { getPaxMixFromTravelersCount } from "../../../common";

const addExperienceProduct = assign((context: ExperiencesMachineContext) => {
  const shopDetails = getExperienceShop({ context });
  const priceBreakdown = getExperiencePriceBreakdown({ context });
  const travelerCounts = getExperienceTravelerCounts({ context });

  const contactInformationEmail = ContactSelectors.getEmailAddress({
    context,
  });

  if (!shopDetails || !priceBreakdown) {
    throw new Error("Missing shop response or price breakdown");
  }

  const experiencesProductValue: CreateExperienceProductRequest = {
    opaqueShoppedProduct: priceBreakdown?.opaqueShoppedProduct,
    startTime: priceBreakdown?.startTime,
    experienceOptionId: priceBreakdown?.optionId,
    date: priceBreakdown?.date || "",
    experienceId: shopDetails.id,
    paxMix: getPaxMixFromTravelersCount(travelerCounts),
  };

  const experienceProduct = {
    type: "Experiences", // need to add Experience to Product in @b2bportal/purchase-api
    value: experiencesProductValue,
  } as unknown as ProductOpaqueValue;

  const ctxWithExperience = cartHelpers.addQuoteProduct(
    experienceProduct,
    context
  );

  return setContextWithKey(
    ctxWithExperience,
    `${ParentState.cartQuote}.customerEmail`,
    contactInformationEmail
  );
});

export default addExperienceProduct;
