import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import styles from "./styles.module.scss";
import { ShopComponentSkeleton } from "../../shop/component";
import clsx from "clsx";

export interface IShopContactInformationProps {
  isSkeleton?: boolean;
  supplierEmail?: string;
  supplierPhone?: string;
  mobile?: boolean;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopContactInformation = (props: IShopContactInformationProps) => {
  const { isSkeleton, supplierEmail, supplierPhone, mobile } = props;

  if (isSkeleton) {
    return <Skeleton />;
  }

  return (
    <Box
      className={clsx(styles["contact-info-container"], {
        [styles["mobile"]]: mobile,
      })}
    >
      <Box className={styles["description-container"]}>
        <Typography variant="body2">
          For questions about meeting or pickup point, experience details, or
          special requests, contact your experience provider for more
          information.
        </Typography>
      </Box>
      <Box
        className={clsx(styles["contact-methods-container"], {
          [styles["mobile"]]: mobile,
        })}
      >
        {supplierPhone && (
          <Box className={styles["contact-method-container"]}>
            <Icon
              name={IconName.MobilePhoneIcon}
              className={styles["phone-icon"]}
            />
            <Typography variant="h6">Call</Typography>
            <Typography variant="body2">{supplierPhone}</Typography>
          </Box>
        )}
        {supplierEmail && (
          <Box className={clsx(styles["contact-method-container"])}>
            <Icon name={IconName.EmailIcon} />
            <Typography variant="h6">Email</Typography>
            <Typography variant="body2">{supplierEmail}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
