export const HEADER_MAIN_TEXT = "Confirm and book";
export const HEADER_SUB_TEXT = "Enter all required details";

export const TRAVELERS_HEADING = "Step 1: Traveler information";
export const PRIMARY_TRAVELER_HEADING = "Step 2: Primary traveler";
export const ADDITIONAL_DETAILS_HEADING = "Step 3: Additional details";
export const PAYMENT_HEADING = (hasAdditionalDetailsStep: boolean) => {
  const stepCount = hasAdditionalDetailsStep ? "Step 4" : "Step 3";
  return `${stepCount}: Rewards and payment`;
};
export const PAYMENT_SUBHEADING =
  "Combine travel credits, a travel offer, rewards, and the payment method you add to book this trip.";

export const LOADING_TEXT = "Loading";
