import {
  FiatPrice,
  PaymentV2Enum,
  RewardsPrice,
  PaymentSplitRequestEnum,
} from "redmond";
import { State } from "xstate";
import { ReactElement } from "react";
import { roundToTwoDecimals } from "halifax";
import {
  CardPaymentSelectors,
  RewardsPaymentSelectors,
  WalletSelectors,
} from "@capone/checkout";
import { PaymentOpaqueValue, Payment } from "@b2bportal/purchase-api";
import { DO_NOT_APPLY_REWARDS_KEY } from "@capone/common";

import { ExperiencesMachineContext } from "../types";

const fallbackAmount: FiatPrice = {
  currencyCode: "USD",
  currencySymbol: "$",
  value: 0,
};

const fallbackRewards: RewardsPrice = {
  value: 0,
  currency: "",
};

export interface IPricingLineItem {
  icon?: ReactElement;
  key: string;
  value: string[] | FiatPrice;
  classNames?: string[];
  showPointsPrice?: boolean;
}

export interface TravelerLineItem {
  title: string;
  subtitleKey?: string;
  pricingLineItems: IPricingLineItem[];
}

type CheckoutState = State<ExperiencesMachineContext>;
type CheckoutStateWithoutValue = Pick<CheckoutState, "context">;
type CheckoutStateWithAndWithoutValue =
  | CheckoutState
  | CheckoutStateWithoutValue;

const getPaymentRequestType = (state: CheckoutStateWithAndWithoutValue) => {
  const selectedRewardsAccountId =
    RewardsPaymentSelectors.getSelectedAccountId(state);
  const selectedCardPaymentId =
    CardPaymentSelectors.getSelectedPaymentMethodId(state);

  const rewardsAmountToApply =
    RewardsPaymentSelectors.getRewardsAmountToApply(state);
  const cardAmountToApply =
    CardPaymentSelectors.getTotalCardPaymentRequired(state);

  const rewardsSelected = selectedRewardsAccountId
    ? selectedRewardsAccountId !== DO_NOT_APPLY_REWARDS_KEY
    : false;

  switch (true) {
    case !!selectedCardPaymentId && !rewardsSelected:
      return PaymentSplitRequestEnum.PaymentCardRequest;
    case rewardsSelected &&
      !!rewardsAmountToApply?.value &&
      cardAmountToApply === 0:
      return PaymentSplitRequestEnum.PaymentRewardsRequest;
    case !!rewardsAmountToApply?.value && rewardsSelected:
      return PaymentSplitRequestEnum.PaymentCardRewardsRequest;
    default:
      return undefined;
  }
};

export const getOpaquePayments = (state: CheckoutStateWithAndWithoutValue) => {
  const payments: PaymentOpaqueValue[] = [];

  const creditToApply = WalletSelectors.getTravelWalletCreditToApply(state);

  const paymentRequestType = getPaymentRequestType(state);

  const selectedRewardsAccount =
    RewardsPaymentSelectors.getSelectedAccount(state);
  const selectedCardPaymentId =
    CardPaymentSelectors.getSelectedPaymentMethodId(state);
  const selectedCardPaymentRewardsAccountId =
    CardPaymentSelectors.getSelectedPaymentMethodRewardsAccountId(state);

  const rewardsAmountToApply =
    RewardsPaymentSelectors.getRewardsAmountToApply(state);
  const rewardsFiatAmountToApply =
    RewardsPaymentSelectors.getRewardsFiatAmountToApply(state);
  const cardAmountToApply =
    CardPaymentSelectors.getTotalCardPaymentRequired(state);

  if (creditToApply) {
    if (Math.abs(creditToApply.amount.amount) > 0) {
      const credit: PaymentOpaqueValue = {
        type: Payment.TravelWalletCredit,
        value: {
          offerId: creditToApply.id,
          description: "TravelWalletCredit", // creditToApply doesn't have a description
          paymentAmount: {
            fiatValue: {
              amount: roundToTwoDecimals(Math.abs(creditToApply.amount.amount)),
              currency: creditToApply.amount.currency,
            },
          },
          PaymentV2: PaymentV2Enum.TravelWalletCredit,
        },
      };
      payments.push(credit);
    }
  }
  switch (paymentRequestType) {
    case PaymentSplitRequestEnum.PaymentCardRequest: {
      const userCardPayment: PaymentOpaqueValue = {
        type: Payment.Card,
        value: {
          paymentId: selectedCardPaymentId || "",
          accountReferenceId: selectedCardPaymentRewardsAccountId,
          paymentAmount: {
            currency: "USD",
            amount: roundToTwoDecimals(cardAmountToApply),
          },
          PaymentV2: PaymentV2Enum.UserCard,
        },
      };
      payments.push(userCardPayment);
      break;
    }
    case PaymentSplitRequestEnum.PaymentCardRewardsRequest: {
      const splitUserCardPayment: PaymentOpaqueValue = {
        type: Payment.Card,
        value: {
          paymentId: selectedCardPaymentId || "",
          accountReferenceId: selectedCardPaymentRewardsAccountId,
          paymentAmount: {
            currency: "USD",
            amount: roundToTwoDecimals(cardAmountToApply),
          },
          PaymentV2: PaymentV2Enum.UserCard,
        },
      };

      const splitRewardsPayment: PaymentOpaqueValue = {
        type: Payment.Rewards,
        value: {
          paymentAmount: {
            rewardsAccountId: selectedRewardsAccount?.accountReferenceId,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatAmountToApply?.value || 0),
              currency: rewardsFiatAmountToApply?.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsAmountToApply?.value || 0),
              currency: rewardsAmountToApply?.currency,
            },
          },
          PaymentV2: PaymentV2Enum.Rewards,
        },
      };

      payments.push(splitUserCardPayment, splitRewardsPayment);
      break;
    }
    case PaymentSplitRequestEnum.PaymentRewardsRequest: {
      const rewardsPaymentType: PaymentOpaqueValue = {
        type: Payment.Rewards,
        value: {
          paymentAmount: {
            rewardsAccountId: selectedRewardsAccount?.accountReferenceId,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatAmountToApply?.value || 0),
              currency: rewardsFiatAmountToApply?.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsAmountToApply?.value || 0),
              currency: rewardsAmountToApply?.currency,
            },
          },
          PaymentV2: PaymentV2Enum.Rewards,
        },
      };
      payments.push(rewardsPaymentType);
      break;
    }
    default:
      break;
  }

  return payments;
};

export const getBreakdownTotal = ():
  | { fiat: FiatPrice; rewards?: RewardsPrice; isDueToday: boolean }
  | undefined => ({
  fiat: fallbackAmount,
  rewards: fallbackRewards,
  isDueToday: false,
});
