import { ADD_TRAVELER } from "redmond";

import { updateUserPassenger } from "../../../../api/v0/user/updateUserPassenger";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { fetchUserPassengers } from "../../../../api/v0/user/fetchUserPassengers";
import { ExperiencesContext } from "../../../../modules/book/state/types";

export const updateUserPassengerService = (
  _context: ExperiencesContext,
  event: any
) =>
  new Promise((resolve, reject) => {
    const { person, onUpdate } = event;
    updateUserPassenger({ person })
      .then(() => {
        trackEvent({ eventName: ADD_TRAVELER, properties: {} });
        fetchUserPassengers().then((response) => {
          resolve({
            id: person.id,
            value: response,
            onUpdate,
          });
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
