import React, { useCallback } from "react";
import { Box, Radio } from "@material-ui/core";
import clsx from "clsx";
import {
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@capone/checkout";
import { ExperienceLanguageGuide } from "redmond";
import { AVAILABILITY_LANG_GUIDE_LABEL_MAPPING } from "halifax";

import { ExperiencesAdditionalDetailsSelectors } from "../../../../../checkout";
import { Event, TEvent } from "../../../state/events";
import { ExperiencesMachineContext } from "../../../state/types";

import styles from "./styles.module.scss";
import { LangGuideValidation } from "../../../../../checkout/states/AdditionalDetails";

export const SelectLang = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const productOptionLangGuides = useSelector(
    ExperiencesAdditionalDetailsSelectors.getBookableLangGuides
  );
  const selectedLangGuide = useSelector(
    ExperiencesAdditionalDetailsSelectors.getSelectedLangGuide
  );

  const validationErrors = useSelector(
    ExperiencesAdditionalDetailsSelectors.getAdditionalDetailsErrors
  );

  const clearValidationErrors = useCallback(() => {
    send({ type: Event.CLEAR_ADDITIONAL_DETAILS_ERROR });
  }, []);

  const handleRowSelection = (data: ExperienceLanguageGuide) => {
    send({
      type: Event.SET_SELECTED_LANG_GUIDE,
      data,
    });
    clearValidationErrors();
  };

  const hasLangGuideSelectionError = validationErrors.includes(
    LangGuideValidation.LangSelection
  );

  return (
    <div className={styles["section-container"]}>
      <div
        className={clsx(styles["section-title"], {
          [styles.error]: hasLangGuideSelectionError,
        })}
      >
        Select langugage
      </div>
      <div
        className={clsx(styles["section-subtitle"], {
          [styles.error]: hasLangGuideSelectionError,
        })}
      >
        This provider offers this experience in multiple languages
      </div>
      <div className={styles["radio-selection-container"]}>
        {productOptionLangGuides.map((option) => (
          <div key={option.language} className={styles["radio-row-container"]}>
            <Radio
              className={styles.radio}
              checked={option.language === selectedLangGuide?.language}
              onChange={() => handleRowSelection(option)}
            />
            <Box
              className={clsx(styles["row-label"], {
                [styles.selected]:
                  option.language === selectedLangGuide?.language,
              })}
              onClick={() => handleRowSelection(option)}
            >
              {`${option.languageDisplayName} - ${
                AVAILABILITY_LANG_GUIDE_LABEL_MAPPING[option.guideType]
              }`}
            </Box>
          </div>
        ))}
      </div>
      {hasLangGuideSelectionError && (
        <div className={clsx(styles["error-caption"], [styles.error])}>
          You must make a selection to continue.
        </div>
      )}
    </div>
  );
};
