import { getObjectKeysAsObject, ParentState } from "@capone/checkout";

import { ExperiencesContext } from "../../../modules/book/state/types";

export const guards = {
  hasAdditionalDetailsStep: (ctx: ExperiencesContext) =>
    ctx[ParentState.experiencesAdditionalDetails].hasAdditionalDetailsStep,
};

export const GuardTypes = getObjectKeysAsObject(guards);
