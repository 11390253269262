import { assign, DoneInvokeEvent } from "xstate";
import { getObjectKeysAsObject, ParentState } from "@capone/checkout";

import { ExperiencesContext } from "../../../modules/book/state/types";
import {
  SetSpecialRequirementEvent,
  SetSelectedPickupPointEvent,
  SetHotelSelectionEvent,
  SetHotelNameEvent,
  SetAddressEvent,
  SetCityEvent,
  SetZipCodeEvent,
  SetSelectedLangGuideEvent,
} from "../../../modules/book/state/events";

export const actions = {
  setSelectedPickupPoint: assign(
    (ctx: ExperiencesContext, event: SetSelectedPickupPointEvent) => {
      ctx[ParentState.experiencesAdditionalDetails].selectedPickupPoint =
        event.data;
      return ctx;
    }
  ),

  setHotelSelection: assign(
    (ctx: ExperiencesContext, event: SetHotelSelectionEvent) => {
      ctx[ParentState.experiencesAdditionalDetails].hotelSelection = event.data;
      return ctx;
    }
  ),

  setHotelName: assign((ctx: ExperiencesContext, event: SetHotelNameEvent) => {
    ctx[ParentState.experiencesAdditionalDetails].hotelName = event.data;
    return ctx;
  }),

  setAddress: assign((ctx: ExperiencesContext, event: SetAddressEvent) => {
    ctx[ParentState.experiencesAdditionalDetails].address = event.data;
    return ctx;
  }),

  setCity: assign((ctx: ExperiencesContext, event: SetCityEvent) => {
    ctx[ParentState.experiencesAdditionalDetails].city = event.data;
    return ctx;
  }),

  setZipCode: assign((ctx: ExperiencesContext, event: SetZipCodeEvent) => {
    ctx[ParentState.experiencesAdditionalDetails].zipCode = event.data;
    return ctx;
  }),

  setSelectedLangGuide: assign(
    (ctx: ExperiencesContext, event: SetSelectedLangGuideEvent) => {
      ctx[ParentState.experiencesAdditionalDetails].selectedLangGuide =
        event.data;
      return ctx;
    }
  ),

  setSpecialRequirement: assign(
    (ctx: ExperiencesContext, event: SetSpecialRequirementEvent) => {
      ctx[ParentState.experiencesAdditionalDetails].specialRequirement =
        event.data;
      return ctx;
    }
  ),

  setAdditionalDetailsError: assign(
    (ctx: ExperiencesContext, event: DoneInvokeEvent<any>) => {
      ctx[ParentState.experiencesAdditionalDetails].errors = event.data;
      return ctx;
    }
  ),

  clearErrors: assign((ctx: ExperiencesContext) => {
    ctx[ParentState.experiencesAdditionalDetails].errors = [];
    return ctx;
  }),
};

export const ActionTypes = getObjectKeysAsObject(actions);
