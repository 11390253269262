import React, { useMemo, useEffect } from "react";
import {
  TravelerSelectWorkflow,
  TravelerSelectStep,
  ActionButton,
  isAdult,
} from "halifax";
import { PersonId, SELECT_TRAVELERS } from "redmond";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import {
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@capone/checkout";

import "./styles.scss";
import * as constants from "./textConstants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { config } from "../../../../api/config";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { Event, TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import { DesktopExperiencesBookValidationError } from "../DesktopExperiencesBookWorkflow";
import { ExperiencesTravelerSelectors } from "../../../../checkout";

export interface PrimaryTravelerSelectionProps {
  isMobile?: boolean;
  validationErrorTypes?: DesktopExperiencesBookValidationError[];
  setValidationErrorTypes?: (
    types: DesktopExperiencesBookValidationError[]
  ) => void;
  showNonModalContent?: boolean;
}

export const PrimaryTravelerSelection = ({
  isMobile,
  validationErrorTypes,
  setValidationErrorTypes,
  showNonModalContent,
}: PrimaryTravelerSelectionProps) => {
  const [_state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const travelers = useSelector(ExperiencesTravelerSelectors.getUserTravelers);

  const selectedTravelerIds = useSelector(
    ExperiencesTravelerSelectors.getSelectedTravelerIds
  );

  const selectedPrimaryTravelerId = useSelector(
    ExperiencesTravelerSelectors.getSelectedPrimaryTravelerId
  );

  const selectableTravelers = useMemo(
    () =>
      travelers
        ?.filter(
          (person) =>
            isAdult(person.dateOfBirth) &&
            selectedTravelerIds?.includes(person.id)
        )
        .map(personToIPerson),
    [travelers, selectedTravelerIds]
  );

  const handleSelectTraveler = (travelerId?: PersonId) => {
    send({
      type: Event.SELECT_TRAVELER,
      travelerId,
      singleTravelerWorkflow: true,
    });
  };

  const canContinue = isMobile || !!selectedPrimaryTravelerId;

  const handleContinue = () => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
      send(Event.NEXT);
    } else {
      setValidationErrorTypes?.(["primary-traveler"]);
    }
  };

  const handleBack = () => {
    send(Event.PREVIOUS);
  };

  useEffect(() => {
    if (!selectedPrimaryTravelerId && selectableTravelers.length === 1) {
      handleSelectTraveler(selectableTravelers[0].id);
    }
  }, [selectedPrimaryTravelerId, selectableTravelers]);

  useEffect(() => {
    if (selectedPrimaryTravelerId && selectableTravelers.length) {
      // reset primary traveler if not in selected travelers
      if (
        !selectableTravelers.some(
          (passenger) => passenger.id === selectedPrimaryTravelerId
        )
      ) {
        handleSelectTraveler(undefined);
      }
    }
  }, [selectableTravelers, selectedPrimaryTravelerId]);

  useEffect(() => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
    }
  }, [canContinue]);

  return (
    <>
      <TravelerSelectWorkflow
        readOnly
        hideTravelerActions
        displayModalSubtitle
        showGenderField
        showNationalityField
        singleTravelerWorkflow
        buttonClassName="b2b"
        className={clsx("experiences-primary-traveler-selection-root", {
          error: validationErrorTypes?.includes("primary-traveler"),
        })}
        errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
        isMobile={isMobile}
        progress={
          isMobile ? TravelerSelectStep.TravelerSelect : TravelerSelectStep.Main
        }
        setProgress={() => {}}
        selectedTravelerIds={
          selectedPrimaryTravelerId ? [selectedPrimaryTravelerId] : []
        }
        setSelectedTravelerIds={(travelerIds: string[]) => {
          trackEvent({
            eventName: SELECT_TRAVELERS,
            properties: {},
          });
          handleSelectTraveler(travelerIds[0]);
        }}
        titles={{
          travelerInfoTitle: isMobile
            ? constants.TRAVELER_INFO_TEXT
            : constants.PRIMARY_TRAVELER_TITLE_SUBHEADING,
          travelerInfoSubtitle: isMobile
            ? constants.PRIMARY_TRAVELER_TITLE_SUBHEADING
            : constants.PRIMARY_TRAVELER_SUBTITLE,
          frequentFlyerTitle: "",
          additionalInfoTitle: "",
          adultTitle: constants.ADULT_TITLE,
          childTitle: constants.CHILD_TITLE,
          infantSeatTitle: "",
          infantLapTitle: "",
        }}
        travelers={selectableTravelers}
        tenant={config.TENANT}
        trackEvent={trackEvent}
        onContinue={handleContinue}
        onGoBack={handleBack}
        mobileTravelerRowType="checkbox"
        // TODO add experiences price breakdown
        // selectionScreenHeaderElement={
        //   <experiencesPriceBreakdown isMobile dropdown />
        // }
        customHeader={
          <Box className="experiences-traveler-mobile-header">
            <Typography variant="h4" className="pax-header-secondary-title">
              {constants.PRIMARY_TRAVELER_TITLE_SUBHEADING}
            </Typography>
            <Typography className="pax-header-subtitle">
              {constants.PRIMARY_TRAVELER_SUBTITLE}
            </Typography>
          </Box>
        }
        showMobilePopoverTransition={false}
        bypassSelectForSingleTraveler={false}
        showNonModalContent={showNonModalContent}
      />
      {!isMobile && (
        <>
          <Typography
            className={clsx("experiences-primary-traveler-select-notice", {
              error: validationErrorTypes?.includes("primary-traveler"),
            })}
          >
            {constants.PRIMARY_TRAVELER_NOTICE}
          </Typography>
          <ActionButton
            onClick={handleContinue}
            message={constants.CONTINUE_TEXT}
            className="experiences-continue-button"
          />
        </>
      )}
    </>
  );
};
