// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LBeTB5H-LKxtoVqA7tBCiA\\=\\={display:grid;grid-template-columns:1fr 2fr 1fr;grid-column-gap:5px;justify-items:center;align-items:center;width:100%;padding:18px;box-sizing:border-box}.LBeTB5H-LKxtoVqA7tBCiA\\=\\= .xFIwJnTiJdPLB7MuL-A4fQ\\=\\={grid-column-start:2}.LBeTB5H-LKxtoVqA7tBCiA\\=\\= ._3vnst1TwCfXO69dBMw-LmA\\=\\={width:unset;margin-left:auto}.LBeTB5H-LKxtoVqA7tBCiA\\=\\= ._3vnst1TwCfXO69dBMw-LmA\\=\\= svg{width:16px;height:16px}.LBeTB5H-LKxtoVqA7tBCiA\\=\\= ._3vnst1TwCfXO69dBMw-LmA\\=\\= svg path{fill:var(--blue-9)}._0zxkDjBfCoXO4mmuytl6bQ\\=\\={padding:0 20px 22px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/experiences-module/src/modules/common/ExperiencesMobilePopoverCard/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,iCAAA,CACA,mBAAA,CACA,oBAAA,CACA,kBAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,wDACE,mBAAA,CAEF,yDACE,WAAA,CACA,gBAAA,CACA,6DACE,UAAA,CACA,WAAA,CACA,kEACE,kBAAA,CAMR,6BACE,mBAAA","sourcesContent":[".experiences-mobile-popover-card-header-container {\n  display: grid;\n  grid-template-columns: 1fr 2fr 1fr;\n  grid-column-gap: 5px;\n  justify-items: center;\n  align-items: center;\n  width: 100%;\n  padding: 18px;\n  box-sizing: border-box;\n  .header-title {\n    grid-column-start: 2;\n  }\n  .close-button {\n    width: unset;\n    margin-left: auto;\n    svg {\n      width: 16px;\n      height: 16px;\n      path {\n        fill: var(--blue-9);\n      }\n    }\n  }\n}\n\n.experiences-mobile-popover-card-content {\n  padding: 0 20px 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experiences-mobile-popover-card-header-container": "LBeTB5H-LKxtoVqA7tBCiA==",
	"header-title": "xFIwJnTiJdPLB7MuL-A4fQ==",
	"close-button": "_3vnst1TwCfXO69dBMw-LmA==",
	"experiences-mobile-popover-card-content": "_0zxkDjBfCoXO4mmuytl6bQ=="
};
export default ___CSS_LOADER_EXPORT___;
