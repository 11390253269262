import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName, useDeviceTypes, TreesCard } from "halifax";
import {
  useCheckoutStateSelector,
  ContactSelectors,
  cartFulfillSelectors,
} from "@capone/checkout";

import "./styles.scss";
import {
  EXPERIENCES_CONFIRMATION_CODE_TEXT,
  EXPERIENCES_CONFIRMATION_CONTACT_PICK_UP,
  EXPERIENCES_CONFIRMATION_EARN,
  EXPERIENCES_CONFIRMATION_HEADING,
} from "./textConstants";

import { postBookingSurveyPageId } from "./utils";
import { ExperienceSummaryPanel } from "../ExperienceSummaryPanel";
import {
  AVAILABLE,
  getExperimentVariant,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import {
  ExperiencesAdditionalDetailsSelectors,
  PickupPointOption,
} from "../../../../checkout";
import { ExperiencesSelectors } from "../../state/selectors";

export const ConfirmationPage = () => {
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();
  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesExperiment = treesModalExperiment === AVAILABLE;

  const earnedRewards = useCheckoutStateSelector(
    cartFulfillSelectors.getFulfillEarnedRewards
  );
  const emailAddress = useCheckoutStateSelector(
    ContactSelectors.getEmailAddress
  );
  const confirmationCode = useCheckoutStateSelector(
    cartFulfillSelectors.getFulfilledProductsReservationId
  );

  const selectedPickupPoint = useCheckoutStateSelector(
    ExperiencesAdditionalDetailsSelectors.getSelectedPickupPoint
  );
  const expShop = useCheckoutStateSelector(
    ExperiencesSelectors.getExperienceShop
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      className={clsx("experiences-confirmation-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="experiences-confirmation-header">
        <Box className="check-mark-icon">
          <Icon name={IconName.ConfirmationCheckMark} aria-hidden />
        </Box>
        <Typography
          variant="h4"
          className="experiences-confirmation-heading-text"
        >
          {EXPERIENCES_CONFIRMATION_HEADING}
        </Typography>
      </Box>
      <Box className="experiences-confirmation-section-container">
        <Typography className="earn-string">
          {EXPERIENCES_CONFIRMATION_EARN(earnedRewards, emailAddress)}
        </Typography>
        <Typography className="confirmation-code">
          {EXPERIENCES_CONFIRMATION_CODE_TEXT(confirmationCode)}
        </Typography>
      </Box>

      <Box className="experiences-confirmation-section-container">
        <ExperienceSummaryPanel />
        {selectedPickupPoint === PickupPointOption.PickupLater &&
          expShop?.supplierInfo && (
            <Box
              className={clsx(
                "experiences-confirmation-pickup-later-container",
                {
                  mobile: matchesMobile,
                }
              )}
            >
              <Icon name={IconName.Wayfinding} />
              <Typography className="experiences-confirmation-pickup-later-body">
                {EXPERIENCES_CONFIRMATION_CONTACT_PICK_UP()}
              </Typography>
              <Box className="experiences-confirmation-pickup-later-supplier-info-container">
                <Box>
                  <Typography>
                    <strong className="supplier-name">
                      {expShop?.supplierInfo.name}
                    </strong>
                  </Typography>
                </Box>
                <Box>{expShop?.supplierInfo.phone}</Box>
                <Box>{expShop?.supplierInfo.email}</Box>
              </Box>
            </Box>
          )}
      </Box>

      <div id={postBookingSurveyPageId()} />

      {isTreesExperiment && <TreesCard isConfirmationScreen />}
    </Box>
  );
};
