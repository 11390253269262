import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "@reduxjs/toolkit";
import {
  getLocation,
  getFromDate,
  getUntilDate,
} from "../../../search/reducer";
import { IResult } from "redmond/build/common";
import {
  CallState,
  Category,
  ExperiencesAvailabilityAutocompleteGroup,
  ExperiencesAvailabilityAutocompleteResult,
  ExperienceTag,
  ExperienceTagCount,
  GroupedExperienceIds,
  ParsedGroupedExperienceIds,
  PriceRefinement,
  TripadvisorRatingRefinement,
} from "redmond";

export const getExperiencesAvailabilityCallState = (state: IStoreState) =>
  state.experiencesAvailability.experiencesAvailabilityCallState;

export const getExperiencesAvailabilityResponse = (state: IStoreState) =>
  state.experiencesAvailability.availabilityResponse;

export const getExperiencesAvailabilityNextPageToken = (state: IStoreState) =>
  state.experiencesAvailability.availabilityNextPageToken;

export const getExperiencesAutocompleteResults = (state: IStoreState) =>
  state.experiencesAvailability.autocompleteResults;

export const getExperiences = (state: IStoreState) =>
  state.experiencesAvailability.availabilityExperiences;

export const getExperiencesMap = (state: IStoreState) =>
  state.experiencesAvailability.experiencesMap;

export const getExperiencesMaxPrice = createSelector(
  getExperiences,
  (experiences) => {
    if (experiences.length <= 0) return null;
    let experienceMaxPrice = experiences[0].bestPrice;
    experiences.map((experience) => {
      experienceMaxPrice =
        experience.bestPrice.fiat.value > experienceMaxPrice.fiat.value
          ? experience.bestPrice
          : experienceMaxPrice;
    });

    return experienceMaxPrice;
  }
);

export const getExperiencesPopularTagsList = createSelector(
  getExperiencesAvailabilityResponse,
  (availabilityResponse): ExperienceTag[] => {
    if (!availabilityResponse) {
      return [];
    }
    return availabilityResponse.popularFeatureTags.map((tag) => {
      return tag.tag;
    });
  }
);

export const getExperiencesByCategory = createSelector(
  getExperiencesAvailabilityResponse,
  (availabilityResponse): GroupedExperienceIds[] => {
    if (!availabilityResponse) {
      return [];
    }

    return availabilityResponse.experiencesByCategory;
  }
);

export const getExperiencesCategoryTags = createSelector(
  getExperiencesAvailabilityResponse,
  (availabilityResponse): ExperienceTagCount[] => {
    if (!availabilityResponse) {
      return [];
    }

    return availabilityResponse.categoryTags;
  }
);

export const getParsedExperiencesByCategory = createSelector(
  getExperiencesByCategory,
  getExperiencesCategoryTags,
  (experiencesByCategory, categoryTags): ParsedGroupedExperienceIds[] => {
    if (experiencesByCategory.length <= 0 || categoryTags.length <= 0) {
      return [];
    }

    return experiencesByCategory.map((item) => {
      const matchingCategoryTag = categoryTags.find((categoryTag) => {
        return categoryTag.tag.id === item.tag.id;
      });
      return {
        tag: matchingCategoryTag ?? { tag: item.tag, count: 0 },
        experienceIds: item.experienceIds,
      };
    });
  }
);

export const getExistingExperiencesAvailabilityRequestParameters =
  createSelector(
    getLocation,
    getFromDate,
    getUntilDate,

    (
      location,
      departureDate,
      returnDate
    ): {
      location: IResult | null;
      departureDate: Date | null;
      returnDate: Date | null;
    } => ({
      location,
      departureDate,
      returnDate,
    })
  );

export const getExperiencesQueryParams = createSelector(
  getFromDate,
  getUntilDate,
  (fromDate, untilDate) => ({
    fromDate,
    untilDate,
  })
);

export const getKeyword = (
  state: IStoreState
): ExperiencesAvailabilityAutocompleteResult | null =>
  state.experiencesAvailability.keyword;

export const getKeywordCategories = createSelector(
  getExperiencesAutocompleteResults,
  (autocompleteResults): ExperiencesAvailabilityAutocompleteGroup[] => {
    if (!autocompleteResults) {
      return [];
    }

    let experiencesAvailabilityAutocompleteGroups: ExperiencesAvailabilityAutocompleteGroup[] =
      [];

    const categoryTagResults: ExperiencesAvailabilityAutocompleteResult[] =
      autocompleteResults.categories.map((categoryResult) => {
        return {
          id: categoryResult.tag.id.toString(),
          label: `${categoryResult.tag.name} (${categoryResult.count})`,
        };
      });

    experiencesAvailabilityAutocompleteGroups.push({
      category: Category.Categories,
      label: "Categories",
      results: categoryTagResults,
    });

    const specificExperiencesResults: ExperiencesAvailabilityAutocompleteResult[] =
      autocompleteResults.experiences.map((experiencesResult) => {
        return {
          id: experiencesResult.id.value,
          label: experiencesResult.name,
          rating: experiencesResult.rating,
        };
      });

    experiencesAvailabilityAutocompleteGroups.push({
      category: Category.Experiences,
      label: "Experiences",
      results: specificExperiencesResults,
    });

    return experiencesAvailabilityAutocompleteGroups;
  }
);

export const getKeywordCategoriesLoading = (state: IStoreState) =>
  state.experiencesAvailability.autocompleteCallState === CallState.InProcess;

export const getFilteredTripAdvisorRating = (state: IStoreState) =>
  state.experiencesAvailability.tripAdvisorRating
    ? state.experiencesAvailability.tripAdvisorRating
    : TripadvisorRatingRefinement.Any;

export const getFilteredStartTimes = (state: IStoreState) =>
  state.experiencesAvailability.startTime
    ? state.experiencesAvailability.startTime
    : [];

export const getFilteredDurationTimes = (state: IStoreState) =>
  state.experiencesAvailability.duration
    ? state.experiencesAvailability.duration
    : [];

export const getEncodedTags = createSelector(
  getExperiencesAvailabilityResponse,
  (availabilityResponse): string | undefined => {
    if (!availabilityResponse) {
      return undefined;
    }
    return availabilityResponse.encodedTags;
  }
);

export const getFilterPrice = (state: IStoreState) =>
  state.experiencesAvailability.price;

export const getCurrentFilterPrice = createSelector(
  getExperiencesMaxPrice,
  getFilterPrice,
  (experiencesMaxPrice, filterPrice): PriceRefinement => {
    return filterPrice
      ? filterPrice
      : experiencesMaxPrice
      ? { maxPriceUSD: experiencesMaxPrice.fiat.value }
      : { maxPriceUSD: 0 };
  }
);

export const getFilteredTags = (state: IStoreState) =>
  state.experiencesAvailability.tags ? state.experiencesAvailability.tags : [];

export const getFiltersChangedSinceLastSearch = (state: IStoreState) =>
  state.experiencesAvailability.filtersChangedSinceLastSearch;

export const getSortOption = (state: IStoreState) =>
  state.experiencesAvailability.sort;

export const getExperiencesByCategoryMap = (state: IStoreState) =>
  state.experiencesAvailability.experiencesByCategoryMap;
