import {
  PaymentError,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import {
  CartFulfillErrorModal,
  CartQuoteErrorModal,
  PriceChangeType,
} from "@capone/checkout";
import { Icon, IconName } from "halifax";
import { IGenericInfoPopupProps } from "halifax/build/GenericInfoPopup";
import React from "react";

import {
  GENERIC_ERROR_SUBTITLE,
  GENERIC_ERROR_TITLE,
  NO_AVAILABILITY_TITLE,
  PRICE_INCREASE_TITLE,
  CONTINUE_CTA_TEXT,
  SELECT_ANOTHER_EXPERIENCE_CTA_TEXT,
  PRICE_DECREASE_TITLE,
  TRY_AGAIN_CTA_TEXT,
  BOOKING_FAILED_TITLE,
  PRICE_CHANGE_SUBTITLE,
  GENERIC_ERROR_TRY_AGAIN_SUBTITLE,
} from "./textConstants";

const SELECT_ANOTHER_PACKAGE_BUTTON = (
  onClick: () => void
): IGenericInfoPopupProps["buttons"] => [
  {
    buttonText: SELECT_ANOTHER_EXPERIENCE_CTA_TEXT,
    onClick,
  },
];

const DEFAULT_ERROR_MODEL_PROPS = (
  onClick?: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons"> => ({
  title: GENERIC_ERROR_TITLE,
  subtitle: GENERIC_ERROR_SUBTITLE,
  buttons: onClick ? SELECT_ANOTHER_PACKAGE_BUTTON(onClick) : [],
});

const getProductError = (error: ProductError, goToAvailability: () => void) => {
  switch (error.value.type) {
    default:
      return DEFAULT_ERROR_MODEL_PROPS(goToAvailability);
  }
};

const getPaymentError = (_error: PaymentError, onClick?: () => void) =>
  DEFAULT_ERROR_MODEL_PROPS(onClick);

export const getErrorModalProps = (
  type?: CartQuoteErrorModal | CartFulfillErrorModal,
  data?: PurchaseError[],
  priceChange?: PriceChangeType,
  isQuote?: boolean,
  isFulfill?: boolean,
  handleAcknowledgePriceChange?: () => void,
  retryQuote?: () => void,
  goToAvailability?: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons" | "image"> => {
  if (!type && !!priceChange?.hasDifference && !!priceChange.difference) {
    if (priceChange.isIncrease) {
      return {
        title: PRICE_INCREASE_TITLE(priceChange.difference),
        subtitle: PRICE_CHANGE_SUBTITLE,
        image: <Icon className="error-icon" name={IconName.PriceIncrease} />,
        buttons: [
          {
            buttonText: CONTINUE_CTA_TEXT,
            onClick: handleAcknowledgePriceChange,
          },
          {
            buttonText: SELECT_ANOTHER_EXPERIENCE_CTA_TEXT,
            onClick: goToAvailability,
            defaultStyle: "h4r-secondary",
          },
        ],
      };
    }

    return {
      title: PRICE_DECREASE_TITLE(priceChange.difference),
      subtitle: PRICE_CHANGE_SUBTITLE,
      image: <Icon className="error-icon" name={IconName.PriceDrop} />,
      buttons: [
        {
          buttonText: CONTINUE_CTA_TEXT,
          onClick: handleAcknowledgePriceChange,
        },
      ],
    };
  }

  const handleDefaultError = () => {
    switch (true) {
      case isQuote:
        return {
          title: GENERIC_ERROR_TITLE,
          subtitle: GENERIC_ERROR_TRY_AGAIN_SUBTITLE,
          buttons: [
            { buttonText: TRY_AGAIN_CTA_TEXT, onClick: retryQuote },
            {
              buttonText: SELECT_ANOTHER_EXPERIENCE_CTA_TEXT,
              onClick: goToAvailability,
              defaultStyle: "h4r-secondary",
            },
          ],
        };
      case isFulfill:
        return {
          title: BOOKING_FAILED_TITLE,
          buttons: [
            {
              buttonText: SELECT_ANOTHER_EXPERIENCE_CTA_TEXT,
              onClick: goToAvailability,
            },
          ],
        };
      default:
        return DEFAULT_ERROR_MODEL_PROPS(goToAvailability);
    }
  };

  switch (type) {
    case CartFulfillErrorModal.FailedPolling:
    case CartQuoteErrorModal.FailedPolling: {
      const head = data?.[0];
      switch (head?.Error) {
        case PurchaseErrorEnum.NoAvailability:
        case PurchaseErrorEnum.ExpiredFulfillToken:
          return {
            title: NO_AVAILABILITY_TITLE,
            buttons: SELECT_ANOTHER_PACKAGE_BUTTON(
              goToAvailability || (() => {})
            ),
          };
        case PurchaseErrorEnum.PaymentError:
          return getPaymentError(head as PaymentError, goToAvailability);
        case PurchaseErrorEnum.ProductError:
          return getProductError(
            head as ProductError,
            goToAvailability || (() => {})
          );
        default:
          return handleDefaultError();
      }
    }
    default:
      return handleDefaultError();
  }
};
