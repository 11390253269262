import { ExperiencesMachineContext } from "./types";
import { CurrencyFormatters } from "halifax";
import { FiatPrice, RewardsPrice } from "redmond";

export const validateContext = (_: ExperiencesMachineContext) => true;
// Boolean(
//   (ctx.flightShop.shopPricingInfo.fare?.length || -1) > 0 &&
//     ctx.flightSearch.departureDate &&
//     ctx.flightSearch.destination &&
//     ctx.flightSearch.origin &&
//     ctx.flightShop.selectedTrip.outgoingFareId &&
//     ctx.flightShop.selectedTrip.outgoingSliceId &&
//     ctx.flightShop.selectedTrip.tripId &&
//     Object.keys(ctx.flightShop.airports || {}).length > 0
// );

export const formatCurrency = (price: FiatPrice, discount?: boolean) =>
  CurrencyFormatters.get(price.currencyCode).format(
    discount ? Math.abs(price.value) * -1 : price.value
  );

export const formatRewards = (price: RewardsPrice, discount?: boolean) => {
  const priceValueToUse = discount ? Math.abs(price.value) : price.value;

  const isCash = price.currencyDescription?.includes("cash");

  const currencySymbol = isCash ? "$" : "";
  return `${
    discount ? "-" : ""
  }${currencySymbol}${priceValueToUse.toLocaleString("en-US", {
    minimumFractionDigits: isCash ? 2 : 0,
    maximumFractionDigits: isCash ? 2 : 0,
  })} ${price?.currencyDescription}`;
};
