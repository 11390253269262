import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { ShopComponentSkeleton } from "../../component";
import {
  ExperienceBookableItem,
  ExperienceOptionId,
  ExperiencePricingCategoryEnum,
  ExperiencesAgeBandEnum,
  ExperiencesPriceBreakdown,
  ExperiencesShopOptionRequest,
  ExperienceTimeSlotPrice,
  IPassengerCounts,
  RewardsAccount,
  SpecificAgeBand,
} from "redmond";
import {
  Icon,
  IconName,
  ActionButton,
  MobileOutlinedTextInput,
  PassengerCountPickerTitles,
  getPriceString,
  PassengerCountPicker,
  getRewardText,
} from "halifax";
import dayjs from "dayjs";
import clsx from "clsx";
import * as textConstants from "../../textConstants";
import { TOTAL_NUM_TRAVELERS, START_TIME } from "../../../common/textConstants";
import { getMinMaxHelperText, IShopOverview } from "../ShopOverview/component";
import {
  ExperiencesPricingBreakdown,
  getPaxMixFromTravelersCount,
  getTotalTravelersCount,
} from "../../../common";
import { ExperiencesMobilePopoverCard } from "../../../common/ExperiencesMobilePopoverCard";
import {
  IShopCheckoutBreakdown,
  ShopCheckoutBreakdown,
} from "../ShopCheckoutBreakdown/component";

export interface IShopMobileAvailabilityCardProps extends IShopOverview {
  setFromDate: (date: Date | null) => void;
  setUntilDate: (date: Date | null) => void;
  fromDate: Date | null;
  untilDate: Date | null;
  fetchExperiencesShop: () => void;
  fetchExperiencesShopOptions: (request: ExperiencesShopOptionRequest) => void;
  selectedBookableItem: ExperienceBookableItem | null;
  setSelectedBookableItem: (bookableItem: ExperienceBookableItem) => void;
  selectedOptionId?: ExperienceOptionId;
  setSelectedOptionId: (optionId: ExperienceOptionId) => void;
  selectedTime: string | null;
  setSelectedTime: (time: any) => void;
  selectedDate: string | null;
  setSelectedDate: (date: any) => void;
  ageBands?: {
    [key in ExperiencesAgeBandEnum]?: SpecificAgeBand;
  };
  setTravelerCounts: (counts: IPassengerCounts) => void;
  travelerCounts?: IPassengerCounts;
  pricingBreakdowns?: ExperiencesPriceBreakdown[];
  largestValueAccount?: RewardsAccount;
  checkoutBreakdownProps?: IShopCheckoutBreakdown | null;
}

const Skeleton = () => (
  <Box className={styles["experiences-shop-skeleton"]}>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopMobileAvailabilityCard = (
  props: IShopMobileAvailabilityCardProps
) => {
  const {
    isSkeleton = false,
    experienceId,
    bookableItems,
    // setFromDate,
    // setUntilDate,
    fromDate,
    untilDate,
    // fetchExperiencesShop,
    fetchExperiencesShopOptions,
    selectedBookableItem,
    setSelectedBookableItem,
    selectedTime,
    setSelectedTime,
    ageBands,
    setTravelerCounts,
    travelerCounts,
    selectedOptionId,
    setSelectedOptionId,
    selectedDate,
    setSelectedDate,
    experienceMaxTravelers,
    largestValueAccount,
    pricingCategory,
    checkoutBreakdownProps,
    adultRequired,
  } = props;

  // const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [openTravelerCountPicker, setOpenTravelerCountPicker] =
    React.useState(false);
  const [openBookableOptionPicker, setOpenBookableOptionPicker] =
    React.useState(false);

  const [_localFromDate, setLocalFromDate] = React.useState<Date | null>(null);
  const [_localUntilDate, setLocalUntilDate] = React.useState<Date | null>(
    null
  );

  const [modalTitles, setModalTitles] = useState<
    PassengerCountPickerTitles | undefined
  >(undefined);
  const [totalTravelers, setTotalTravelers] = useState<number>(2);

  useEffect(() => {
    setTotalTravelers(getTotalTravelersCount(travelerCounts));
    handleFetchShopOptions();
  }, [travelerCounts]);

  const isMultiVariant = useMemo(() => {
    return bookableItems && bookableItems.length > 1;
  }, [bookableItems]);

  useEffect(() => {
    if (ageBands) {
      let newModalTitles: PassengerCountPickerTitles = {
        modalTitle: "",
        adultTitle: textConstants.ADULTS,
        adultSubtitle: ageBands.Adult
          ? textConstants.AGES_SUBTITLE(
              ageBands.Adult.lowerBound,
              ageBands.Adult.upperBound
            )
          : "",
        adultHelperText: ageBands.Adult
          ? getMinMaxHelperText(ageBands.Adult)
          : undefined,
        adultPrice: ageBands.Adult
          ? `${getPriceString({
              price: ageBands.Adult?.pricePerTraveler.fiat.value,
            })}`
          : "",
        childrenPrice: ageBands.Child
          ? `${getPriceString({
              price: ageBands.Child?.pricePerTraveler.fiat.value,
            })}`
          : "",
        childrenTitle: ageBands.Child ? textConstants.CHILDREN : "",
        childrenSubtitle: ageBands.Child
          ? textConstants.AGES_SUBTITLE(
              ageBands.Child.lowerBound,
              ageBands.Child.upperBound
            )
          : "",
        childrenHelperText: ageBands.Child
          ? getMinMaxHelperText(ageBands.Child)
          : undefined,
        seniorTitle: ageBands.Senior ? textConstants.SENIORS : undefined,
        seniorSubtitle: ageBands.Senior
          ? textConstants.AGES_SUBTITLE(
              ageBands.Senior.lowerBound,
              ageBands.Senior.upperBound
            )
          : undefined,
        seniorHelperText: ageBands.Senior
          ? getMinMaxHelperText(ageBands.Senior)
          : undefined,
        seniorPrice: ageBands.Senior
          ? `${getPriceString({
              price: ageBands.Senior?.pricePerTraveler.fiat.value,
            })}`
          : undefined,
        youthTitle: ageBands.Youth ? textConstants.YOUTHS : undefined,
        youthSubtitle: ageBands.Youth
          ? textConstants.AGES_SUBTITLE(
              ageBands.Youth.lowerBound,
              ageBands.Youth.upperBound
            )
          : undefined,
        youthHelperText: ageBands.Youth
          ? getMinMaxHelperText(ageBands.Youth)
          : undefined,
        youthPrice: ageBands.Youth
          ? `${getPriceString({
              price: ageBands.Youth?.pricePerTraveler.fiat.value,
            })}`
          : undefined,
        showInfantsTitleOnly: true,
        infantTitle: ageBands.Infant ? textConstants.INFANTS : undefined,
        infantSubtitle: ageBands.Infant
          ? textConstants.AGES_SUBTITLE(
              ageBands.Infant.lowerBound,
              ageBands.Infant.upperBound
            )
          : undefined,
        infantHelperText: ageBands.Infant
          ? getMinMaxHelperText(ageBands.Infant)
          : undefined,
        infantPrice: ageBands.Infant
          ? `${getPriceString({
              price: ageBands.Infant?.pricePerTraveler.fiat.value,
            })}`
          : undefined,
      };
      setModalTitles(newModalTitles);
    }
  }, [ageBands]);

  React.useEffect(() => {
    if (bookableItems) {
      setSelectedBookableItem(bookableItems[0]);
    }
  }, [bookableItems]);

  React.useEffect(() => {
    if (selectedBookableItem) {
      if (selectedBookableItem.experienceOptionId) {
        setSelectedOptionId(selectedBookableItem.experienceOptionId);
      }
      const dateToSelect = Object.keys(selectedBookableItem.timeSlotsByDate)[0];
      setSelectedDate(dateToSelect);
      setSelectedTime(
        selectedBookableItem.timeSlotsByDate[dateToSelect][0].startTime
      );
    }
  }, [selectedBookableItem]);

  React.useEffect(() => {
    if (selectedBookableItem && selectedTime) {
      handleFetchShopOptions();
    }
  }, [selectedBookableItem, selectedTime]);

  React.useEffect(() => {
    setLocalFromDate(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setLocalUntilDate(untilDate);
  }, [untilDate]);

  const handleFetchShopOptions = () => {
    if (selectedBookableItem && selectedDate && selectedTime && experienceId) {
      fetchExperiencesShopOptions({
        startTime: selectedTime,
        experienceId: experienceId,
        experienceOptionId: selectedOptionId,
        paxMix: getPaxMixFromTravelersCount(travelerCounts),
        date: selectedDate,
        currency: "USD",
      });
    }
  };

  // const handleClickDone = () => {
  //   setOpenCalendarModal(false);
  //   let refetchExperiencesShop = false;
  //   if (localFromDate !== fromDate || localUntilDate != untilDate) {
  //     refetchExperiencesShop = true;
  //   }
  //   setFromDate(localFromDate);
  //   setUntilDate(localUntilDate);
  //   if (refetchExperiencesShop) {
  //     fetchExperiencesShop();
  //   }
  // };

  const getPriceTitle = (
    item: ExperienceBookableItem
  ): {
    priceTitle: string;
    rewardsTitle: string;
    subtitle: string | undefined;
  } => {
    const firstAvailableDate = Object.keys(item.timeSlotsByDate).find(
      (date) => {
        return item.timeSlotsByDate[date].find((y) => {
          return y.price;
        });
      }
    );
    const firstAvailablePrice: ExperienceTimeSlotPrice | undefined =
      firstAvailableDate
        ? item.timeSlotsByDate[firstAvailableDate].find((y) => {
            return y.price;
          })
        : undefined;

    const priceTitle = firstAvailablePrice?.price
      ? textConstants.PRICE_TITLE_TEXT(
          getPriceString({
            price: firstAvailablePrice.price.fiat.value,
            currencySymbol: firstAvailablePrice.price.fiat.currencySymbol,
          }),
          pricingCategory?.ExperiencePricingCategory ===
            ExperiencePricingCategoryEnum.PerGroup
            ? textConstants.GROUP
            : textConstants.PERSON
        )
      : "";

    const rewardsTitle =
      firstAvailablePrice?.price && largestValueAccount
        ? `${getRewardText({
            reward:
              firstAvailablePrice?.price.rewards[
                largestValueAccount.accountReferenceId
              ],
          })}`
        : "";

    const subtitle = experienceMaxTravelers
      ? textConstants.MAX_TRAVELER_TEXT(experienceMaxTravelers)
      : undefined;

    return {
      priceTitle: priceTitle,
      rewardsTitle: rewardsTitle,
      subtitle: subtitle,
    };
  };

  const renderBookableItem = (
    item: ExperienceBookableItem,
    isMultiVariant?: boolean
  ) => {
    return (
      <Box
        className={clsx(styles["experiences-shop-mobile-availability-card"], {
          [styles["multi-variant"]]: isMultiVariant,
        })}
      >
        <Box className={styles["experiences-shop-mobile-availability-content"]}>
          <Typography variant="h6">{textConstants.AVAILABILITY}</Typography>
          <Box
            className={
              styles["experiences-shop-mobile-availability-selections"]
            }
          >
            {bookableItems &&
              Object.keys(item.timeSlotsByDate)
                .slice(0, 4)
                .map((date) => {
                  return (
                    <ActionButton
                      className={clsx(
                        styles[
                          "experiences-shop-mobile-availability-bookable-options-button"
                        ],
                        {
                          [styles["selected-button"]]: date === selectedDate,
                        }
                      )}
                      defaultStyle={
                        date === selectedDate ? "h4r-primary" : "h4r-secondary"
                      }
                      onClick={() => {
                        if (date !== selectedDate) {
                          setSelectedDate(date);
                        }
                      }}
                      message={
                        <Box
                          className={
                            styles[
                              "experiences-shop-mobile-availability-bookable-options-button-label"
                            ]
                          }
                        >
                          <span
                            className={
                              styles[
                                "experiences-shop-mobile-availability-bookable-options-button-day"
                              ]
                            }
                          >
                            {dayjs(date).format("ddd")}
                          </span>
                          <span
                            className={
                              styles[
                                "experiences-shop-mobile-availability-bookable-options-button-date"
                              ]
                            }
                          >
                            {dayjs(date).format("D")}
                          </span>
                          <span
                            className={
                              styles[
                                "experiences-shop-mobile-availability-bookable-options-button-month"
                              ]
                            }
                          >
                            {dayjs(date).format("MMM")}
                          </span>
                        </Box>
                      }
                    />
                  );
                })}
            {/* <ActionLink
          content={
            <Box className={styles["view-all-calendar-link-content"]}>
              <Icon name={IconName.CalendarWithBoxIcon} />
              <Typography className={styles["view-all-calendar-link-text"]}>
                {textConstants.VIEW_ALL}
              </Typography>
            </Box>
          }
          onClick={() => setOpenCalendarModal(true)}
          className={styles["view-all-calendar-link"]}
        /> */}
          </Box>
        </Box>
        {isMultiVariant && checkoutBreakdownProps && (
          <ShopCheckoutBreakdown
            {...checkoutBreakdownProps}
            largestValueAccount={largestValueAccount}
            cancellationPolicyProps={null}
            onlyPrice={true}
          />
        )}
        <Box className={clsx(styles["experiences-shop-overview-bookables"])}>
          <Typography variant="h6">
            {textConstants.CONFIRM_TRAVELERS}
          </Typography>
          <Box
            className={styles["experiences-shop-overview-bookable-selections"]}
          >
            <MobileOutlinedTextInput
              className="num-traveler-count"
              prefixString={TOTAL_NUM_TRAVELERS(totalTravelers)}
              icon={faUser}
              customEndIcon={<Icon name={IconName.B2BEditPencil} />}
              showCloseIcon={false}
              onClick={() => setOpenTravelerCountPicker(true)}
              value={""}
            />
            {selectedTime && (
              <MobileOutlinedTextInput
                label={START_TIME}
                className="time-input-container"
                prefixString={selectedTime}
                customIcon={
                  <Icon className={styles["clock-icon"]} name="clock-icon" />
                }
                customEndIcon={<Icon name={IconName.B2BEditPencil} />}
                showCloseIcon={false}
                onClick={() => setOpenBookableOptionPicker(true)}
                value={""}
              />
            )}
          </Box>
        </Box>
        {isMultiVariant && checkoutBreakdownProps && (
          <ExperiencesPricingBreakdown
            className={clsx(styles["experiences-price-breakdown"])}
            priceBreakdown={checkoutBreakdownProps.priceBreakdown}
            largestValueAccount={largestValueAccount}
            isFreeCancellation={checkoutBreakdownProps.isFreeCancellation}
            cancellationPolicyProps={null}
            mobile={true}
          />
        )}
      </Box>
    );
  };

  const renderExpandedVariantLabel = (item: ExperienceBookableItem) => (
    <Box
      className={clsx(
        styles["experiences-shop-bookable-item-option-container"],
        styles["experiences-shop-expanded-bookable-item-option-container"]
      )}
    >
      <Typography
        id={item.experienceOptionId.value}
        variant="h6"
        className={styles["experiences-shop-bookable-item-option-title"]}
      >
        {item.title}
      </Typography>
      {renderBookableItem(item, true)}
    </Box>
  );

  const renderCollapsedVariantLabel = (item: ExperienceBookableItem) => {
    const { priceTitle, rewardsTitle, subtitle } = getPriceTitle(item);
    return (
      <Box
        className={clsx(
          styles["experiences-shop-bookable-item-option-container"],
          styles["experiences-shop-collapsed-bookable-item-option-container"]
        )}
      >
        <Typography
          id={item.experienceOptionId.value}
          variant="h6"
          className={styles["experiences-shop-bookable-item-option-title"]}
        >
          {item.title}
        </Typography>
        {priceTitle && (
          <Box
            className={clsx(
              styles["experiences-shop-bookable-item-option-price-container"]
            )}
          >
            <Typography
              className={styles["experiences-shop-bookable-item-option-price"]}
              dangerouslySetInnerHTML={{
                __html: textConstants.getPriceAndRewardsCopy({
                  price: priceTitle,
                  rewards: rewardsTitle,
                }),
              }}
            />
            {subtitle && (
              <Typography
                className={
                  styles["experiences-shop-bookable-item-option-subtitle"]
                }
                variant={"body2"}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderMultiVariantBookableItems = () => {
    return (
      <Box>
        <Box className={styles["experiences-shop-bookable-item-selection"]}>
          {(bookableItems || []).map((item) => {
            return (
              <FormControlLabel
                className={styles["experiences-shop-bookable-item-option"]}
                key={item.experienceOptionId.value}
                value={item.experienceOptionId.value}
                label={
                  item.experienceOptionId.value === selectedOptionId?.value
                    ? renderExpandedVariantLabel(item)
                    : renderCollapsedVariantLabel(item)
                }
                labelPlacement="end"
                control={
                  <Radio
                    checked={
                      selectedOptionId?.value === item.experienceOptionId?.value
                    }
                    className={
                      styles["experiences-shop-bookable-item-option-radio"]
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        setSelectedBookableItem(item);
                      }
                    }}
                  />
                }
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  return (
    <Box className={styles["experiences-shop-mobile-availability-section"]}>
      {bookableItems && (
        <Box className={styles["experiences-shop-bookable-items-section"]}>
          {isMultiVariant
            ? renderMultiVariantBookableItems()
            : renderBookableItem(bookableItems[0])}
        </Box>
      )}
      {travelerCounts && (
        <ExperiencesMobilePopoverCard
          headerText={textConstants.EDIT_TRAVELERS}
          content={
            <PassengerCountPicker
              minimumAdultsCount={0}
              titles={modalTitles}
              setPassengerCounts={(counts) => {
                const travellers = counts as IPassengerCounts;
                setTravelerCounts(travellers);
              }}
              onClickApply={() => setOpenTravelerCountPicker(false)}
              counts={travelerCounts}
              className={clsx(
                "b2b",
                styles["experiences-shop-mobile-availability-traveler-modal"]
              )}
              maximumTravelersCount={experienceMaxTravelers}
              paxCountLimits={{
                seniorsCount: {
                  min: ageBands?.Senior?.minNumberOfTravelers,
                  max: ageBands?.Senior?.maxNumberOfTravelers,
                },
                adultsCount: {
                  min: ageBands?.Adult?.minNumberOfTravelers,
                  max: ageBands?.Adult?.maxNumberOfTravelers,
                },
                youthsCount: {
                  min: ageBands?.Youth?.minNumberOfTravelers,
                  max: ageBands?.Youth?.maxNumberOfTravelers,
                },
                childrenCount: {
                  min: ageBands?.Child?.minNumberOfTravelers,
                  max: ageBands?.Child?.maxNumberOfTravelers,
                },
                infantsCount: {
                  min: ageBands?.Infant?.minNumberOfTravelers,
                  max: ageBands?.Infant?.maxNumberOfTravelers,
                },
              }}
              getPaxCountsError={(counts) => {
                const passengerCounts = counts as IPassengerCounts;
                let errorText = "";
                if (adultRequired) {
                  errorText =
                    passengerCounts.adultsCount +
                      (passengerCounts.seniorsCount ?? 0) <=
                    0
                      ? textConstants.ADULT_REQUIRED
                      : "";
                }

                return errorText;
              }}
            />
          }
          isOpen={openTravelerCountPicker}
          setOpen={setOpenTravelerCountPicker}
        />
      )}
      {selectedTime && (
        <ExperiencesMobilePopoverCard
          headerText={textConstants.CHOOSE_START_TIME}
          content={
            <RadioGroup
              className={
                styles["experiences-shop-mobile-availability-start-time-modal"]
              }
              value={selectedTime}
              onChange={(event) => {
                const optionId = selectedBookableItem?.experienceOptionId;
                setSelectedTime(event.target.value);
                if (optionId) {
                  setSelectedOptionId(optionId);
                }
              }}
            >
              {selectedBookableItem &&
                selectedDate &&
                selectedBookableItem.timeSlotsByDate[selectedDate].map(
                  (option: ExperienceTimeSlotPrice) => (
                    <FormControlLabel
                      className={styles["start-time-option"]}
                      key={option.startTime}
                      value={option.startTime}
                      label={
                        <span className={styles["start-time-option-label"]}>
                          {option.startTime}
                        </span>
                      }
                      labelPlacement="start"
                      control={
                        <Radio className={styles["start-time-option-radio"]} />
                      }
                    />
                  )
                )}
            </RadioGroup>
          }
          isOpen={openBookableOptionPicker}
          setOpen={setOpenBookableOptionPicker}
        />
      )}
      {isSkeleton ? <Skeleton /> : null}
    </Box>
  );
};
