import { PassengerError, Person } from "redmond";

import { deleteUserPassenger } from "../../../../api/v0/user/deleteUserPassenger";
import { fetchUserPassengers } from "../../../../api/v0/user/fetchUserPassengers";
import { ExperiencesContext } from "../../../../modules/book/state/types";

export interface DeleteUserPassengerSuccessResponse {
  userPassengers: Person[];
  deletedPassengerId: string;
}

export type DeleteUserPassengerResponse =
  | DeleteUserPassengerSuccessResponse
  | PassengerError;

export const deleteUserPassengersService = (
  _ctx: ExperiencesContext,
  event: any
) =>
  new Promise<DeleteUserPassengerResponse>((resolve, reject) => {
    const passengerIdToDelete = event.personId;
    deleteUserPassenger(passengerIdToDelete)
      .then(() => {
        fetchUserPassengers().then((response) => {
          resolve({
            userPassengers: response,
            deletedPassengerId: passengerIdToDelete,
          });
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
