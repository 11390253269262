import { fetchUserInfo } from "../../../../../api/v0/user/fetchUserInfo";
import { fetchCustomerDetails } from "../../../../../api/v0/user/fetchCustomerDetails";

export const fetchContactInfoService = () =>
  new Promise((resolve, reject) => {
    fetchUserInfo()
      .then((userInfo) => {
        fetchCustomerDetails().then((customerDetails) => {
          resolve({
            email: userInfo.userInfo.email,
            phoneNumber: customerDetails.phoneNumber,
          });
        });
      })
      .catch((e) => reject(e));
  });
