import React, { useEffect } from "react";
import { Box, debounce, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  ExperienceId,
  ExperienceReview,
  ExperiencesShopReviewsRequest,
  ExperiencesShopReviewsSort,
  ExperiencesShopReviewRatingRefinement,
  ReviewSummary,
  TripadvisorRatingRefinement,
} from "redmond";
import {
  AvailabilityReviewBadge,
  B2BButton,
  B2BTextField,
  DetailedAllRatings,
  Icon,
  IconName,
  RadioDropdown,
  roundToNearestHalf,
  TripAdvisorReview,
} from "halifax";
import clsx from "clsx";
import { ShopComponentSkeleton } from "../../component";
import styles from "./styles.module.scss";
import { SEARCH_BY_KEYWORD } from "../../textConstants";

export interface IShopCustomerReviews {
  isSkeleton: boolean;
  summary?: ReviewSummary;
  reviews?: ExperienceReview[];
}

export interface IShopCustomerReviewsProps extends IShopCustomerReviews {
  selectedExperienceId?: ExperienceId;
  fetchExperiencesShopReviews?: (
    request: ExperiencesShopReviewsRequest
  ) => void;
  mobile?: boolean;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

const tripadvisorRatingFilterOptions = [
  TripadvisorRatingRefinement.Any,
  TripadvisorRatingRefinement.Great,
  TripadvisorRatingRefinement.VeryGood,
  TripadvisorRatingRefinement.Good,
];

const tripadvisorRatingFilterOptionsLabels: {
  [key in TripadvisorRatingRefinement]: string;
} = {
  Any: "All",
  Great: "Great 5",
  VeryGood: "Very good 4+",
  Good: "Good 3+",
};

const reviewsSortOptions = [
  ExperiencesShopReviewsSort.Newest,
  ExperiencesShopReviewsSort.RatingsHighToLow,
  ExperiencesShopReviewsSort.RatingsLowToHigh,
];

const reviewsSortOptionsLabels: {
  [key in ExperiencesShopReviewsSort]: string;
} = {
  Newest: "Newest",
  RatingsHighToLow: "Rating (high to low)",
  RatingsLowToHigh: "Rating (low to high)",
};

export const ShopCustomerReviews = (props: IShopCustomerReviewsProps) => {
  const {
    isSkeleton,
    summary,
    reviews,
    selectedExperienceId,
    fetchExperiencesShopReviews,
    mobile,
  } = props;

  const [page, setPage] = React.useState(1);
  const [selectedFilter, setSelectedFilter] =
    React.useState<TripadvisorRatingRefinement>(
      TripadvisorRatingRefinement.Any
    );

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [reviewSortOption, setReviewSortOption] =
    React.useState<ExperiencesShopReviewsSort>(
      ExperiencesShopReviewsSort.Newest
    );

  const mapToRatingRefinement = (
    filter: TripadvisorRatingRefinement
  ): ExperiencesShopReviewRatingRefinement | undefined => {
    switch (filter) {
      case TripadvisorRatingRefinement.Great:
        return ExperiencesShopReviewRatingRefinement.Great;
      case TripadvisorRatingRefinement.VeryGood:
        return ExperiencesShopReviewRatingRefinement.VeryGood;
      case TripadvisorRatingRefinement.Good:
        return ExperiencesShopReviewRatingRefinement.Good;
      case TripadvisorRatingRefinement.Any:
      default:
        return undefined;
    }
  };

  const handleReviewFilterChange = (filter: TripadvisorRatingRefinement) => {
    setSelectedFilter(filter);
    if (fetchExperiencesShopReviews && selectedExperienceId) {
      fetchExperiencesShopReviews({
        experienceId: selectedExperienceId,
        refinement: {
          sort: reviewSortOption,
          rating: mapToRatingRefinement(filter) ?? undefined,
          ...(searchTerm && {
            query: searchTerm,
          }),
        },
        start: 1,
        count: 50,
      });
    }
  };

  useEffect(() => {
    if (selectedExperienceId && fetchExperiencesShopReviews) {
      fetchExperiencesShopReviews({
        experienceId: selectedExperienceId,
        refinement: {
          sort: reviewSortOption,
          ...(searchTerm && {
            query: searchTerm,
          }),
        },
        start: 1,
        count: 50,
      });
    }
  }, [searchTerm, reviewSortOption]);

  const handleSearchTermChangeDebounced = React.useMemo(
    () => debounce(setSearchTerm, 100),
    []
  );

  const handleSearchTermChange = (value: string) => {
    handleSearchTermChangeDebounced(value);
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  if (isSkeleton || !summary || !reviews) return <Skeleton />;

  const renderReviewFilters = () =>
    tripadvisorRatingFilterOptions.map((filter) => (
      <B2BButton
        key={filter}
        aria-label={filter}
        className={clsx(styles["experiences-shop-reviews-filter-button"], {
          [styles["filter-selected"]]: selectedFilter == filter,
        })}
        variant="b2b-shop-filter"
        onClick={() => {
          handleReviewFilterChange(filter);
        }}
      >
        <Box className="text">{`${tripadvisorRatingFilterOptionsLabels[filter]}`}</Box>
      </B2BButton>
    ));

  return (
    <Box
      className={clsx(styles["experiences-shop-reviews-container"], {
        [styles["mobile"]]: mobile,
      })}
    >
      <Box className={styles["experiences-shop-reviews-summary-container"]}>
        <Box
          className={
            styles["experiences-shop-reviews-summary-average-container"]
          }
        >
          <Box
            className={
              styles["experiences-shop-reviews-summary-average-content"]
            }
          >
            <Typography
              className={
                styles["experiences-shop-reviews-summary-average-title"]
              }
            >{`${roundToNearestHalf(
              summary.reviewAverage
            )} average`}</Typography>
            <AvailabilityReviewBadge
              scaledScore={roundToNearestHalf(summary.reviewAverage)}
              hideIcon
            />
          </Box>
          <Typography>{`${summary.numberOfReviews} reviews`}</Typography>
        </Box>
        <Box className={styles["experiences-shop-reviews-summary-all-ratings"]}>
          <DetailedAllRatings summary={summary} />
        </Box>
      </Box>
      <Box className={styles["experiences-shop-reviews-search-and-filter"]}>
        <Box className={styles["experiences-shop-reviews-filters-and-sort"]}>
          <Box className={styles["experiences-shop-reviews-filters"]}>
            {renderReviewFilters()}
          </Box>
          <Box className={styles["experiences-shop-reviews-sort"]}>
            <RadioDropdown
              buttonClassName={clsx(
                "experiences-shop-reviews-sort-button",
                "b2b-shop-filter"
              )}
              dropdownLabel={
                <>
                  <strong>{"Sort: "}</strong>
                  {reviewsSortOptionsLabels[reviewSortOption]}
                </>
              }
              options={reviewsSortOptions.map((option) => ({
                value: option,
                label: reviewsSortOptionsLabels[option],
              }))}
              setOption={(option) =>
                setReviewSortOption(option as ExperiencesShopReviewsSort)
              }
              selected={reviewSortOption}
              groupAriaLabel="Choose a Sort Option"
              buttonAriaLabel="Change Sort Option"
              popoverClassName={clsx(
                "b2b",
                "experiences-shop-reviews-sort-popover"
              )}
              dropdownIcon={<Icon name={IconName.Sort} />}
              radioLabelPlacement="end"
            />
          </Box>
        </Box>

        <Box className={styles["experiences-shop-reviews-search"]}>
          <B2BTextField
            fullWidth
            label={
              <>
                <Icon name={IconName.MagnifyingGlass} />
                {SEARCH_BY_KEYWORD}
              </>
            }
            onChange={(value) => {
              handleSearchTermChange(value);
            }}
            value={searchTerm}
            className={styles["experiences-shop-reviews-search-text-field"]}
          />
        </Box>
      </Box>
      <Box className={styles["experiences-shop-reviews-texts"]}>
        {reviews.slice((page - 1) * 4, (page - 1) * 4 + 4).map((review) => (
          <TripAdvisorReview review={review} searchTerm={searchTerm} />
        ))}
      </Box>
      {reviews.length / 4 > 1 ? (
        <Box className={styles["experiences-shop-reviews-paginator"]}>
          <Pagination
            count={Math.ceil(reviews.length / 4)}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      ) : undefined}
    </Box>
  );
};
