import { State } from "xstate";
import { ParentState } from "@capone/checkout";
import { BookingQuestionId } from "redmond";

import { ExperiencesContext } from "../../../modules/book/state/types";

type ExperiencesStateType = State<ExperiencesContext>;
type ExperiencesStateWithoutValue = Pick<ExperiencesStateType, "context">;

export const getHasAdditionalDetailsStep = ({
  context,
}: ExperiencesStateWithoutValue) =>
  context[ParentState.experiencesAdditionalDetails].hasAdditionalDetailsStep;

export const getPickupLocations = ({ context }: ExperiencesStateWithoutValue) =>
  context.experienceShop?.bookingQuestions.pickupLocations;

export const getIsPickupIncluded = ({
  context,
}: ExperiencesStateWithoutValue) => {
  if (!context.experienceShop) return false;

  const selectedBookableItem = context.experienceShop?.bookableItems.find(
    (item) =>
      item.experienceOptionId.value === context.priceBreakdown?.optionId?.value
  );

  return selectedBookableItem?.isPickupIncluded;
};

export const getHasPickupLocationField = ({
  context,
}: ExperiencesStateWithoutValue) =>
  context[
    ParentState.experiencesAdditionalDetails
  ].requiredBookingQuestions.some(
    (bookingQuestion) => bookingQuestion.id === BookingQuestionId.PickupPoint
  );

export const getBookableLangGuides = ({
  context,
}: ExperiencesStateWithoutValue) => {
  if (!context.experienceShop) return [];

  const selectedBookableItem = context.experienceShop?.bookableItems.find(
    (item) =>
      item.experienceOptionId.value === context.priceBreakdown?.optionId?.value
  );

  return selectedBookableItem?.languageGuides || [];
};

export const getHasSpecialRequirementField = ({
  context,
}: ExperiencesStateWithoutValue) =>
  context[
    ParentState.experiencesAdditionalDetails
  ].requiredBookingQuestions.some(
    (bookingQuestion) =>
      bookingQuestion.id === BookingQuestionId.SpecialRequirements
  );

export const getPickupPointStates = ({
  context,
}: ExperiencesStateWithoutValue) => {
  const {
    selectedPickupPoint,
    hotelSelection,
    hotelName,
    address,
    city,
    zipCode,
  } = context[ParentState.experiencesAdditionalDetails];

  return {
    selectedPickupPoint,
    hotelSelection,
    hotelName,
    address,
    city,
    zipCode,
  };
};

export const getSelectedPickupPoint = ({
  context,
}: ExperiencesStateWithoutValue) =>
  context[ParentState.experiencesAdditionalDetails].selectedPickupPoint;

export const getSelectedLangGuide = ({
  context,
}: ExperiencesStateWithoutValue) =>
  context[ParentState.experiencesAdditionalDetails].selectedLangGuide;

export const getSpecialRequirement = ({
  context,
}: ExperiencesStateWithoutValue) =>
  context[ParentState.experiencesAdditionalDetails].specialRequirement;

export const getAdditionalDetailsErrors = ({
  context,
}: ExperiencesStateWithoutValue) =>
  context[ParentState.experiencesAdditionalDetails].errors;
