export const TRAVELER_INFO_TITLE = "Step 1. Traveler information";
export const TRAVELER_INFO_TITLE_UPDATED = "Select or add travelers";
export const ADDITIONAL_INFO_TITLE = "Additional information";
export const ADDITIONAL_INFO_SUBTITLE =
  "This experience operator requires travelers to include additional information for safety measures.";
export const ADULT_TITLE = "Adult";
export const CHILD_TITLE = "Child";
export const UPDATE_TEXT = "Updating Passengers";
export const VALIDATE_TEXT = "Validating Passengers";
export const ADD_TRAVELERS_TEXT = "Add Travelers";
export const ADD_TRAVELERS_TEXT_UPDATED = "Add new Traveler";
export const EDIT_TRAVELER_TEXT = "Edit traveler";
export const ADD_TRAVELERS_SUBTITLE =
  "Ensure all information matches your travel ID. You can't change this information once you place a booking.";
export const ADD_TRAVELER_INFO_TEXT = "Add Traveler Information";
export const TRAVELER_INFO_TEXT = "Traveler information";
export const ADD_TRAVELER_ERROR_MESSAGE =
  "Add or choose at least one traveler to continue.";
export const PASSPORT_TITLE = "Passport details";
export const PASSPORT_SUBTITLE =
  "This experience operator requires travelers to include passport details.";
export const WHOS_FLYING_HEADING = "Who’s flying?";
export const REQUIRED_FIELD = "Required field";

// corporate
export const COMPLETE_PROFILE_TITLE = "Complete traveler profile";
export const COMPLETE_PROFILE_SUBTITLE =
  "Please make sure all the information added matches the government issued ID for this traveler as this will be applied to travel bookings moving forward. Traveler information will be accessible to all Admins.";
