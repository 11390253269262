import { Box, Typography } from "@material-ui/core";
import { ActionLink, CloseButtonIcon, MobilePopoverCard } from "halifax";
import React from "react";
import styles from "./styles.module.scss";

export interface IExperiencesMobilePopoverCardProps {
  headerText: string;
  content: JSX.Element;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}

export const ExperiencesMobilePopoverCard = ({
  headerText,
  content,
  isOpen,
  setOpen,
}: IExperiencesMobilePopoverCardProps) => {
  return (
    <MobilePopoverCard
      open={isOpen}
      headerElement={
        <Box
          className={styles["experiences-mobile-popover-card-header-container"]}
        >
          <Typography className={styles["header-title"]}>
            {headerText}
          </Typography>
          <ActionLink
            className={styles["close-button"]}
            content={<CloseButtonIcon />}
            label="Close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </Box>
      }
      onClose={() => {
        setOpen(false);
      }}
      className={styles["experiences-mobile-popover-card-root"]}
      contentClassName={styles["experiences-mobile-popover-card-content"]}
      centered={true}
    >
      {content}
    </MobilePopoverCard>
  );
};
