import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import { ExperiencesPriceBreakdown, RewardsAccount } from "redmond";
import clsx from "clsx";

export interface IEarnInfoBannerProps {
  isSkeleton: boolean;
  largestValueAccount?: RewardsAccount;
  priceBreakdown?: ExperiencesPriceBreakdown;
  mobile?: boolean;
}

export const EarnInfoBanner = (props: IEarnInfoBannerProps) => {
  const { largestValueAccount, priceBreakdown, mobile } = props;

  if (!largestValueAccount || !priceBreakdown) return null;

  const { earn, productDisplayName } = largestValueAccount;
  const totalAmount = priceBreakdown.totalPriceWithFees.fiat.value;
  const earnRate = earn.experiencesMultiplier ?? earn.hotelsMultiplier;

  return (
    <Box
      className={clsx("experiences-shop-earn-info-container", {
        mobile: mobile,
      })}
    >
      <Icon
        name={IconName.PiggyBankCircleOutline}
        className="experiences-shop-piggy-bank"
      />
      <Typography className="experiences-shop-earn-info-body">
        <strong>{`Earn ${Math.floor(
          earnRate * totalAmount
        ).toLocaleString()} miles `}</strong>
        {`with your ${productDisplayName} card when you book this experience through Capital One Travel.`}
      </Typography>
    </Box>
  );
};
