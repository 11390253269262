export const EXPERIENCES_HEADER = "Experiences";

export const UPDATE_CALENDAR_TEXT = "Choose Dates";

export const WHERE_TO = "Where to?";

export const CHANGE_CTA_TEXT = "Change";
export const CONTACT_INFORMATION = "Contact Information";
export const DATE = "Date";
export const START_TIME = "Start time";
export const TRAVELERS = "Travelers";
export const TOTAL_NUM_TRAVELERS = (totalTravelers: number) =>
  `${totalTravelers} Travelers`;

export const TAXES_AND_FEES = "Taxes & Fees";
export const FREE_CANCELLATION = "Free cancellation";
export const VIEW_POLICY_DETAILS = "View policy details";
export const CANCELLATION_POLICY = "Cancellation policy";
