import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AvailabilityErrorModal } from "./component";
import { getExperiencesAvailabilityCallState } from "../../reducer";
import { ExperiencesAvailabilityCallState } from "../../reducer";
import { fetchInitialExperiencesAvailability } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    hasFailed:
      getExperiencesAvailabilityCallState(state) ==
      ExperiencesAvailabilityCallState.Failed
  };
};

const mapDispatchToProps = {
  fetchInitialExperiencesAvailability
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityErrorModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityErrorModal = withRouter(
  connector(AvailabilityErrorModal)
);
