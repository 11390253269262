import React from "react";
import { ExperienceCancellationPolicy } from "halifax";
import { Box } from "@material-ui/core";
import { ExperiencesCancellationPolicy } from "redmond";

import { ShopComponentSkeleton } from "../../component";

export interface IShopCancellation {
  isSkeleton?: boolean;
  cancellationPolicy?: ExperiencesCancellationPolicy;
}
export interface IShopCancellationPolicyProps extends IShopCancellation {
  selectedDate?: string | null;
  selectedTime?: string | null;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopCancellationPolicy = (props: IShopCancellationPolicyProps) => {
  const { isSkeleton, cancellationPolicy, selectedDate, selectedTime } = props;

  if (isSkeleton || !cancellationPolicy) {
    return <Skeleton />;
  }

  return (
    <ExperienceCancellationPolicy
      cancellationPolicy={cancellationPolicy}
      selectedDate={selectedDate}
      selectedTime={selectedTime}
    />
  );
};
