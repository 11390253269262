import { ConnectedProps, connect } from "react-redux";
import { withRouter } from "react-router";
import { ExperiencesAvailability } from "./component";
import { IStoreState } from "../../reducers/types";
import { getFromDate, getLocation, getUntilDate } from "../search/reducer";
import {
  ExperiencesAvailabilityCallState,
  getExperiencesAvailabilityCallState,
  getExperiencesByCategoryMap,
  getExperiencesMap,
  getFiltersChangedSinceLastSearch,
  getParsedExperiencesByCategory,
  getSortOption,
} from "./reducer";
import {
  getFetchRewardsAccountsCallState,
  getRewardsAccountWithLargestValue,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../rewards/reducer";
import {
  fetchInitialExperiencesAvailability,
  getAvailabilityRequestParameters,
  fetchFilteredExperiencesAvailability,
  setSortOption,
  fetchMoreExperiencesAvailability,
  setFilterTags,
} from "./actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const experiencesAvailabilityCallState =
    getExperiencesAvailabilityCallState(state);
  const isReadyToFetchMoreExperiences =
    experiencesAvailabilityCallState ===
      ExperiencesAvailabilityCallState.FollowUpSearchCallSuccess ||
    experiencesAvailabilityCallState ===
      ExperiencesAvailabilityCallState.InitialSearchCallSuccess;

  return {
    isReadyToFetchMoreExperiences,
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    location: getLocation(state),
    experiencesAvailabilityCallState:
      getExperiencesAvailabilityCallState(state),
    largestValueAccount: getRewardsAccountWithLargestValue(state),
    fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
    experiencesMap: getExperiencesMap(state),
    experiencesByCategory: getParsedExperiencesByCategory(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    filtersChangedSinceLastSearch: getFiltersChangedSinceLastSearch(state),
    sortOption: getSortOption(state),
    experiencesByCategoryMap: getExperiencesByCategoryMap(state),
  };
};

const mapDispatchToProps = {
  fetchInitialExperiencesAvailability,
  fetchMoreExperiencesAvailability,
  fetchFilteredExperiencesAvailability,
  getAvailabilityRequestParameters,
  setSortOption,
  setFilterTags,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedExperiencesAvailability = withRouter(
  connector(ExperiencesAvailability)
);
