import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  Icon,
  IconName,
  InformationalModal,
  TreesConfirmation,
  useDeviceTypes,
} from "halifax";

import {
  TREES_BOLDED_MODAL_CTA_TEXT,
  TREES_FOREST_PROGRAM_DESCRIPTION,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_IMG_LOCATION,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
} from "./textConstants";
import "./styles.scss";

export interface ITreesCardProps {
  isConfirmationScreen?: boolean;
}

export const TreesCard = ({
  isConfirmationScreen = false,
}: ITreesCardProps) => {
  const [treeModalOpen, setTreeModalOpen] = React.useState(false);
  const { matchesMobile } = useDeviceTypes();

  if (matchesMobile && !isConfirmationScreen)
    return (
      <Box className="experiences-checkout-trees-root mobile without-image">
        <Box className="trees-description-title">
          <Icon className="trees-icon" name={IconName.TreesIcon} />
          <InformationalModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            imageLocation={TREES_MODAL_IMG_LOCATION}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
            isMobile={matchesMobile}
          />
        </Box>
      </Box>
    );

  return (
    <Box
      className={clsx("experiences-checkout-trees-root with-image", {
        mobile: matchesMobile,
      })}
    >
      <Box
        className="trees-img-container"
        style={{ backgroundImage: `url(${TreesConfirmation})` }}
      />
      <Box className="trees-description-container">
        <Box className="trees-description-title">
          <Icon className="trees-icon" name={IconName.TreesIcon} />
          <InformationalModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            imageLocation={TREES_MODAL_IMG_LOCATION}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
            isMobile={matchesMobile}
          />
        </Box>
        <Typography variant="body2">
          {TREES_FOREST_PROGRAM_DESCRIPTION}
        </Typography>
      </Box>
    </Box>
  );
};
