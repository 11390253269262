import React from "react";
import {
  LoadingPopup,
  B2BSpinner,
  PoweredByHopper,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import {
  CartFulfillState,
  CartQuoteState,
  getChildState,
  getParentState,
  ParentState,
  useCheckoutState,
} from "@capone/checkout";

import { TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import {
  BOOKING_EXPERIENCE,
  SAVING_TRAVELERS_AND_CHECKING_AVAILABILITY,
} from "./textConstants";

export const BookingInProgressModal = () => {
  const { matchesMobile } = useDeviceTypes();

  const [state] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value);

  const isRunningCartQuote =
    parentState === ParentState.cartQuote &&
    [CartQuoteState.polling, CartQuoteState.schedule].includes(
      childState as CartQuoteState
    );

  const isRunningCartFulfil =
    parentState === ParentState.cartFulfill &&
    [CartFulfillState.polling, CartFulfillState.schedule].includes(
      childState as CartFulfillState
    );

  const getMessage = () => {
    if (isRunningCartFulfil) {
      return BOOKING_EXPERIENCE;
    }

    return SAVING_TRAVELERS_AND_CHECKING_AVAILABILITY;
  };

  return (
    <LoadingPopup
      classes={[
        clsx("experience-book-booking-in-progress-modal", {
          mobile: matchesMobile,
        }),
      ]}
      indicatorSize="small"
      indicator={B2BSpinner}
      open={isRunningCartQuote || isRunningCartFulfil}
      popupSize="small"
      message={getMessage()}
      footer={PoweredByHopper}
      textAlign="center"
      verticalAlignment={matchesMobile ? "center" : undefined}
      fullScreen={matchesMobile}
    />
  );
};
