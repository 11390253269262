import {
  CardPaymentActions,
  CartActions,
  ContactInformationActions,
  ReviewActions,
  RewardsPaymentActions,
  WalletActions,
  getObjectKeysAsObject,
} from "@capone/checkout";

import {
  setPaymentFulfillParams,
  setPrepareDetailsFulfillParams,
  setPlatform,
  setWalletCredits,
} from "./common";
import {
  ExperiencesTravelerActions,
  ExperiencesAdditionalDetailsActions,
} from "../../../../checkout";
import addExperienceProduct from "./experiences";

export const Actions: any = {
  ...CartActions,
  ...ReviewActions,
  ...CardPaymentActions,
  ...WalletActions,
  ...RewardsPaymentActions,
  ...ContactInformationActions,
  ...ExperiencesTravelerActions,
  ...ExperiencesAdditionalDetailsActions,

  // experience book actions,
  addExperienceProduct,

  // common actions
  setPaymentFulfillParams,
  setPrepareDetailsFulfillParams,
  setPlatform,
  setWalletCredits,
};

export const ActionTypes = getObjectKeysAsObject(Actions);
