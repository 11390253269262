import { Box, Typography, useScrollTrigger } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { RouterProps } from "react-router";
import { ExperiencesAvailabilityConnectorProps } from "./container";
import clsx from "clsx";
import {
  B2BButton,
  B2BLoadingPopup,
  ExperiencesAvailabilityCard,
  ExperiencesSearchLoadingImage,
  FloatingMenuPill,
  formatShortDate,
  Icon,
  IconName,
  useDeviceTypes,
  Header,
} from "halifax";
import { ExperienceId, ParsedGroupedExperienceIds } from "redmond";
import { Skeleton } from "@material-ui/lab";

import { config } from "../../api/config";
import "./styles.scss";
import * as textConstants from "../../utils/textConstants";
import { RewardsAccountSelection } from "../rewards/components";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FETCH_AVAILABILITY_TEXT,
  getEarnTagText,
  SEARCH_TITLE,
  VIEWING_EXPERIENCES_HEADER_TEXT,
} from "./textConstants";
import { ClientContext } from "../../App";
import { PATH_HOME, PATH_SHOP } from "../../utils/paths";
import { AvailabilitySearchControl } from "./components/AvailabilitySearchControl";
import { TravelWalletDrawer } from "../travel-wallet/components";
import { ExperiencesAvailabilityCallState } from "./reducer";
import { getEarnMessageToDisplay } from "../search/textConstants";
import { KeywordSearch } from "./components/KeywordSearch";
import { ExperiencesCategoryRow } from "./components/ExperiencesCategoryRow";
import { AvailabilitySortDropdown } from "./components/AvailabilityFilters";
import { AvailabilityErrorModal } from "./components/AvailabilityErrorModal";
import { MobileAvailabilitySearchControl } from "./components/MobileAvailabilitySearchControl";
import { BadgeExcellenceModal } from "../common";
import { transformToStringifiedShopQuery } from "../../utils/queryStringHelpers";
import { AvailabilityFilter } from "./components/AvailabilityFilter";
import { MobileAvailabilityFilter } from "./components/MobileAvailabilityFilter";

export interface IExperiencesAvailability
  extends ExperiencesAvailabilityConnectorProps,
    RouterProps {}

export const ExperiencesAvailability = (props: IExperiencesAvailability) => {
  const {
    history,
    isReadyToFetchMoreExperiences,
    fromDate,
    untilDate,
    location,
    experiencesAvailabilityCallState,
    largestValueAccount,
    fetchRewardsAccountsCallState,
    fetchInitialExperiencesAvailability,
    fetchMoreExperiencesAvailability,
    fetchFilteredExperiencesAvailability,
    getAvailabilityRequestParameters,
    experiencesMap,
    experiencesByCategory,
    rewardsKey,
    filtersChangedSinceLastSearch,
    sortOption,
    setSortOption,
    experiencesByCategoryMap,
    setFilterTags,
  } = props;

  const PAGE_SIZE = 10;

  const [selectedGroupedExperience, setSelectedGroupedExperience] =
    useState<ParsedGroupedExperienceIds>();

  const [experiencesToShow, setExperiencesToShow] = useState<ExperienceId[]>(
    selectedGroupedExperience?.experienceIds.slice(0, PAGE_SIZE) || []
  );

  const { logo } = useContext(ClientContext);
  const [isBadgeExcellenceModalOpen, setBadgeExcellenceModalOpen] =
    useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const { matchesMobile } = useDeviceTypes();

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const initialCallInProgress =
    experiencesAvailabilityCallState ===
    ExperiencesAvailabilityCallState.InitialSearchCallInProcess;

  const shouldRenderSkeleton =
    experiencesAvailabilityCallState ===
      ExperiencesAvailabilityCallState.NotCalled ||
    experiencesAvailabilityCallState ===
      ExperiencesAvailabilityCallState.InitialSearchCallInProcess;

  const numberOfQuickFilterSkeletons = 10;

  useEffect(() => {
    if (
      experiencesAvailabilityCallState ===
      ExperiencesAvailabilityCallState.NotCalled
    ) {
      getAvailabilityRequestParameters(history);
      fetchInitialExperiencesAvailability(history);
    } else if (filtersChangedSinceLastSearch) {
      fetchFilteredExperiencesAvailability(history);
    }
  }, [experiencesAvailabilityCallState, filtersChangedSinceLastSearch]);

  useEffect(() => {
    if (isReadyToFetchMoreExperiences) {
      setTimeout(() => {
        fetchMoreExperiencesAvailability(history);
      }, 500);
    }
  }, [
    isReadyToFetchMoreExperiences,
    fetchMoreExperiencesAvailability,
    history,
  ]);

  const setFetchMoreData = () => {
    const newPageSize = experiencesToShow.length + PAGE_SIZE;

    if (selectedGroupedExperience) {
      return setExperiencesToShow(
        experiencesByCategoryMap[selectedGroupedExperience.tag.tag.id].slice(
          0,
          newPageSize
        )
      );
    } else {
      return null;
    }
  };

  const headerDateText =
    fromDate && untilDate ? (
      <>
        <Typography variant={"body2"}>
          {textConstants.DATES_TEXT(
            formatShortDate(fromDate),
            formatShortDate(untilDate)
          )}
        </Typography>
      </>
    ) : null;

  const renderRightSkeleton = () => {
    const numberOfSkeletonRows = 2;
    const numberOfSkeletonCards = 5;

    const skeletonCategories: ParsedGroupedExperienceIds[] = Array.from(
      { length: numberOfSkeletonRows },
      (_, i) => {
        return {
          tag: { tag: { id: i, name: "" }, count: 0 },
          experienceIds: Array.from(
            { length: numberOfSkeletonCards },
            (_, j) => ({
              value: `${i}-${j}`,
            })
          ),
        };
      }
    );

    return renderDefaultRightSection(skeletonCategories);
  };

  const renderDefaultRightSection = (
    experiencesCategories: ParsedGroupedExperienceIds[] = experiencesByCategory
  ) => {
    return (
      <>
        <KeywordSearch
          title={SEARCH_TITLE(location?.label)}
          setSelectedGroupedExperience={setSelectedGroupedExperience}
        />
        <Box
          className={clsx("experiences-category-row-container", {
            mobile: matchesMobile,
          })}
        >
          {experiencesCategories.map((category) => {
            return (
              <ExperiencesCategoryRow
                key={category.tag.tag.id}
                history={history}
                handleViewAll={handleQuickFilterChange}
                experiencesMap={experiencesMap}
                experienceByCategory={category}
                experiencesForCurrentCategory={
                  experiencesByCategoryMap[category.tag.tag.id]
                }
                rewardsKey={rewardsKey}
                largestValueAccount={largestValueAccount}
                onBadgeExcellenceTagClick={() => {
                  if (!isBadgeExcellenceModalOpen)
                    setBadgeExcellenceModalOpen(true);
                }}
                fromDate={fromDate}
                untilDate={untilDate}
                isSkeleton={shouldRenderSkeleton}
                fetchMoreExperiencesAvailability={
                  fetchMoreExperiencesAvailability
                }
              />
            );
          })}
        </Box>
      </>
    );
  };

  const renderFilteredRightSection = () => {
    return (
      <>
        <Box className={clsx("filtered-title-section")}>
          <KeywordSearch
            title={`${selectedGroupedExperience?.tag.tag.name} (${selectedGroupedExperience?.tag.count})`}
            hideSearchBar={true}
            setSelectedGroupedExperience={setSelectedGroupedExperience}
          />
          {!matchesMobile && (
            <AvailabilitySortDropdown
              availabilitySortOption={sortOption}
              setAvailabilitySortOption={setSortOption}
            />
          )}
        </Box>
        <Box
          className={clsx("experiences-category-row-container", "filtered", {
            mobile: matchesMobile,
          })}
        >
          <InfiniteScroll
            dataLength={experiencesToShow.length}
            hasMore={
              selectedGroupedExperience
                ? experiencesToShow.length <
                  selectedGroupedExperience?.tag.count
                : false
            }
            loader={
              <Box className="loading-experiences">
                <ExperiencesAvailabilityCard
                  variant={"row"}
                  isSkeleton={true}
                />
              </Box>
            }
            next={setFetchMoreData}
          >
            {experiencesToShow.map((experienceId) => {
              let experienceAvailabilityInfo = experiencesMap.get(
                experienceId.value
              );

              if (experienceAvailabilityInfo) {
                return (
                  <ExperiencesAvailabilityCard
                    onClick={() => {
                      window.open(
                        `${PATH_SHOP}${transformToStringifiedShopQuery(
                          experienceId,
                          fromDate,
                          untilDate
                        )}`,
                        "_blank"
                      );
                    }}
                    experiencesAvailabilityInfo={experienceAvailabilityInfo}
                    isMobile={matchesMobile}
                    variant={!matchesMobile ? "row" : "card"}
                    rewardsKey={rewardsKey}
                    onBadgeExcellenceTagClick={() => {
                      if (!isBadgeExcellenceModalOpen)
                        setBadgeExcellenceModalOpen(true);
                    }}
                    earnTagContent={
                      largestValueAccount && (
                        <>
                          <Icon name={IconName.StarIcon} />
                          <Typography
                            className="earn-tag-text"
                            dangerouslySetInnerHTML={{
                              __html: getEarnTagText(
                                // TODO: Remove `hotelsMultiplier` and use `experiencesMultiplier` once we have that from BE https://hopper-jira.atlassian.net/browse/COTA-1548
                                largestValueAccount.earn.hotelsMultiplier,
                                largestValueAccount.rewardsBalance
                                  .currencyDescription ??
                                  largestValueAccount.rewardsBalance.currency
                              ),
                            }}
                          />
                        </>
                      )
                    }
                    isSkeleton={shouldRenderSkeleton}
                  />
                );
              } else {
                return null;
              }
            })}
          </InfiniteScroll>
        </Box>
      </>
    );
  };

  const handleQuickFilterChange = (option: ParsedGroupedExperienceIds) => {
    if (selectedGroupedExperience?.tag?.tag.id === option.tag.tag.id) {
      setSelectedGroupedExperience(undefined);
      setFilterTags([]);
    } else {
      setSelectedGroupedExperience(option);
      setFilterTags([option.tag.tag]);
    }
    setExperiencesToShow([]);
  };

  const renderQuickFilterSkeleton = () => {
    return Array.from({ length: numberOfQuickFilterSkeletons }, (_, i) => (
      <B2BButton
        key={`skeleton-filter-${i}`}
        aria-label={"Skeleton"}
        className={clsx("experiences-availability-quick-filter-button", {
          "category-selected": false,
        })}
        variant="b2b-shop-filter"
      >
        <Box className="generic-dropdown-content-container">
          <Skeleton
            className={clsx(
              "experiences-availability-quick-filter-button",
              "skeleton"
            )}
          />
        </Box>
      </B2BButton>
    ));
  };

  const renderDesktopView = () => {
    return (
      <Box
        className={clsx("experiences-availability-container", config.TENANT)}
      >
        {matchesMobile ? (
          <Box
            className={clsx(
              "mobile-location-search-contents global-mobile-nav",
              {
                scrolled: scrollTrigger,
              }
            )}
          >
            <MobileAvailabilitySearchControl />
          </Box>
        ) : (
          <>
            <Header
              className="rewards-components-section"
              left={
                <Box className="rewards-account-section-left-content">
                  <Box className="logo" onClick={() => history.push(PATH_HOME)}>
                    {logo}
                  </Box>
                  <Box className="rewards-account-section-travel-details">
                    <Typography variant="body1" tabIndex={0}>
                      {VIEWING_EXPERIENCES_HEADER_TEXT(
                        location ? location.label : ""
                      )}
                    </Typography>
                    {headerDateText}
                  </Box>
                </Box>
              }
              right={
                <Box className="desktop-experiences-availability-rewards-account-contents">
                  <RewardsAccountSelection
                    className="b2b hide-balance-border"
                    popoverClassName="b2b"
                  />
                  <TravelWalletDrawer />
                </Box>
              }
            />
            <AvailabilitySearchControl
              history={history}
              scrolled={scrollTrigger}
            />
          </>
        )}
        <Box className={clsx("experiences-availability-quick-filter")}>
          {location && (
            <B2BButton
              key={location?.id.Id}
              aria-label={`Best of ${location.label}`}
              className={clsx("experiences-availability-quick-filter-button", {
                "category-selected": selectedGroupedExperience === undefined,
              })}
              variant="b2b-shop-filter"
              onClick={() => {
                setSelectedGroupedExperience(undefined);
                setFilterTags([]);
              }}
            >
              <Box className="generic-dropdown-content-container">
                <Icon
                  className="location-quick-filter-icon"
                  name={IconName.CompassThinIcon}
                />
                <Box className="text">{`Best of ${location.label}`}</Box>
              </Box>
            </B2BButton>
          )}
          {shouldRenderSkeleton
            ? renderQuickFilterSkeleton()
            : experiencesByCategory.map((category) => (
                <B2BButton
                  key={category.tag.tag.name}
                  aria-label={category.tag.tag.name}
                  className={clsx(
                    "experiences-availability-quick-filter-button",
                    {
                      "category-selected":
                        selectedGroupedExperience?.tag.tag.id ==
                        category.tag.tag.id,
                    }
                  )}
                  variant="b2b-shop-filter"
                  onClick={() => {
                    handleQuickFilterChange(category);
                  }}
                >
                  <Box className="generic-dropdown-content-container">
                    <Box className="text">{`${category.tag.tag.name} (${category.tag.count})`}</Box>
                  </Box>
                </B2BButton>
              ))}
        </Box>
        {
          <Box className="experiences-availability-split-view">
            <Box className="experiences-split-components-section">
              {!matchesMobile && (
                <Box className="left-section">
                  <AvailabilityFilter />
                </Box>
              )}
              <Box className="right-section">
                {shouldRenderSkeleton
                  ? renderRightSkeleton()
                  : selectedGroupedExperience
                  ? renderFilteredRightSection()
                  : renderDefaultRightSection()}
              </Box>
            </Box>
          </Box>
        }
      </Box>
    );
  };

  return (
    <>
      <Box
        className={clsx("experiences-availability-root", {
          mobile: matchesMobile,
        })}
      >
        {renderDesktopView()}
        {initialCallInProgress && (
          <B2BLoadingPopup
            open
            message={FETCH_AVAILABILITY_TEXT}
            secondaryMessage={getEarnMessageToDisplay(
              fetchRewardsAccountsCallState,
              largestValueAccount
            )}
            image={ExperiencesSearchLoadingImage}
            className="experiences-availability-loading-popup"
            popupSize={matchesMobile ? "mobile" : "desktop"}
          />
        )}
        <BadgeExcellenceModal
          isOpen={isBadgeExcellenceModalOpen}
          isMobile={matchesMobile}
          onClose={() => {
            setBadgeExcellenceModalOpen(false);
          }}
        />
        {matchesMobile && !initialCallInProgress && (
          <>
            <FloatingMenuPill
              items={[
                {
                  label: selectedGroupedExperience ? "Sort & filter" : "Filter",
                  onClick: () => setIsFilterModalOpen(true),
                  icon: IconName.Settings,
                },
              ]}
            />
            <MobileAvailabilityFilter
              isFilterModalOpen={isFilterModalOpen}
              displaySort={!!selectedGroupedExperience}
              onClose={() => setIsFilterModalOpen(false)}
            />
          </>
        )}
      </Box>
      <AvailabilityErrorModal />
    </>
  );
};
