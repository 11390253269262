import React, { useCallback } from "react";
import { Box, Radio, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { B2BTextField } from "halifax";
import {
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@capone/checkout";

import { ExperiencesAdditionalDetailsSelectors } from "../../../../../checkout";
import { Event, TEvent } from "../../../state/events";
import { ExperiencesMachineContext } from "../../../state/types";

import styles from "./styles.module.scss";
import {
  HOTEL_OPTION_NOT_AVAILABLE_LABEL,
  PickupPointOption,
  PickupPointValidation,
} from "../../../../../checkout/states/AdditionalDetails";

const pickupPointOptionLabels: {
  [key in PickupPointOption]: string;
} = {
  [PickupPointOption.SelectPickup]: "Select hotel and arrange pickup now",
  [PickupPointOption.PickupLater]: "Arrange pickup later",
  [PickupPointOption.NoPickup]: "I do not need a pick up",
};

export const PickupPoint = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();
  const {
    selectedPickupPoint,
    hotelSelection,
    hotelName,
    address,
    city,
    zipCode,
  } = useSelector(ExperiencesAdditionalDetailsSelectors.getPickupPointStates);
  const pickupLocations = useSelector(
    ExperiencesAdditionalDetailsSelectors.getPickupLocations
  );
  const isPickupIncluded = useSelector(
    ExperiencesAdditionalDetailsSelectors.getIsPickupIncluded
  );

  const validationErrors = useSelector(
    ExperiencesAdditionalDetailsSelectors.getAdditionalDetailsErrors
  );

  const options = [
    isPickupIncluded && PickupPointOption.SelectPickup,
    pickupLocations?.allowContactSupplierLater && PickupPointOption.PickupLater,
    pickupLocations?.allowMeetAtDeparturePoint && PickupPointOption.NoPickup,
  ].filter((option) => !!option) as PickupPointOption[]; // not sure why type assert from filter doesn't work

  const clearValidationErrors = useCallback(() => {
    send({ type: Event.CLEAR_ADDITIONAL_DETAILS_ERROR });
  }, []);

  const renderEnterPickupLocation = () => {
    const hasEnterPickupLocationError = validationErrors.includes(
      PickupPointValidation.EnterPickupLocation
    );

    return (
      <div>
        <div className={clsx(styles["pickup-header"], styles.pt)}>
          <div
            className={clsx(styles["pickup-title"], {
              [styles.error]: hasEnterPickupLocationError,
            })}
          >
            Enter pickup location
          </div>
          <div
            className={clsx(styles["pickup-subtitle"], {
              [styles.error]: hasEnterPickupLocationError,
            })}
          >
            Your pickup location will be confirmed before your experience.
          </div>
        </div>
        <div className={styles["fields-container"]}>
          <B2BTextField
            fullWidth
            label="Hotel name"
            onChange={(data) => send({ type: Event.SET_HOTEL_NAME, data })}
            onFocus={clearValidationErrors}
            value={hotelName}
            required
            error={hasEnterPickupLocationError && !hotelName}
          />
          <B2BTextField
            fullWidth
            label="Address"
            onChange={(data) => send({ type: Event.SET_ADDRESS, data })}
            onFocus={clearValidationErrors}
            value={address}
            required
            error={hasEnterPickupLocationError && !address}
          />
          <div className={styles["two-fields-row"]}>
            <B2BTextField
              label="City"
              onChange={(data) => send({ type: Event.SET_CITY, data })}
              onFocus={clearValidationErrors}
              value={city}
              required
              error={hasEnterPickupLocationError && !city}
            />
            <B2BTextField
              label="Zip code"
              onChange={(data) => send({ type: Event.SET_ZIP_CODE, data })}
              onFocus={clearValidationErrors}
              value={zipCode}
              required
              error={hasEnterPickupLocationError && !zipCode}
            />
          </div>
        </div>
        {hasEnterPickupLocationError && (
          <div className={clsx(styles["error-caption"], [styles.error])}>
            Complete all required fields to continue.
          </div>
        )}
      </div>
    );
  };

  const renderSelectHotelPickup = (hideTitle = false) => {
    const hasHotelSelectionError = validationErrors.includes(
      PickupPointValidation.HotelSelection
    );

    const locations = [
      pickupLocations?.allowCustomLocation && {
        experienceLocation: {
          name: HOTEL_OPTION_NOT_AVAILABLE_LABEL,
        },
      },
      ...(pickupLocations?.locations || []),
    ].filter((location) => !!location);

    return (
      <div>
        {!hideTitle && (
          <div className={styles["pickup-header"]}>
            <div
              className={clsx(styles["pickup-title"], {
                [styles.error]: hasHotelSelectionError,
              })}
            >
              Select hotel
            </div>
          </div>
        )}
        <div className={styles["form-container"]}>
          <Autocomplete
            fullWidth
            className={clsx(styles["hotel-select-autocomplete"], {
              [styles["ac-error"]]: hasHotelSelectionError,
            })}
            onChange={(_e, data) =>
              send({ type: Event.SET_HOTEL_SELECTION, data })
            }
            onFocus={clearValidationErrors}
            options={locations}
            closeIcon={<ClearIcon aria-label="Clear selection" />}
            clearOnEscape
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === "Escape") {
                event.stopPropagation();
              }
            }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                aria-label="Hotel select pick up location, press escape to clear"
                label="Search by hotel name"
                variant="outlined"
              />
            )}
            getOptionLabel={({
              experienceLocation: { name, address: locationAddress },
            }) => `${name} ${locationAddress ? `, ${locationAddress}` : ""}`}
            renderOption={({
              experienceLocation: { name, address: locationAddress },
            }) => (
              <div>
                <div>{name}</div>
                {locationAddress && (
                  <div className={styles["option-caption"]}>
                    {locationAddress}
                  </div>
                )}
              </div>
            )}
            value={hotelSelection}
          />
          {hotelSelection?.name === HOTEL_OPTION_NOT_AVAILABLE_LABEL &&
            renderEnterPickupLocation()}
        </div>
        {hasHotelSelectionError && (
          <div className={clsx(styles["error-caption"], [styles.error])}>
            Select a location to continue.
          </div>
        )}
      </div>
    );
  };

  const handleRowSelection = (option: PickupPointOption) => {
    send({
      type: Event.SET_SELECTED_PICKUP_POINT,
      data: option,
    });
    clearValidationErrors();
  };

  const hasPickupPointSelectionError = validationErrors.includes(
    PickupPointValidation.PickupPointSelection
  );

  return (
    <div className={styles["section-container"]}>
      {options.length === 1 && options[0] === PickupPointOption.SelectPickup ? (
        <>
          <div
            className={clsx(styles["section-title"], {
              [styles.error]: hasPickupPointSelectionError,
            })}
          >
            Hotel pickup (Required to attend this experience)
          </div>
          {!pickupLocations?.allowCustomLocation && (
            <div className={styles["section-warning"]}>
              This tour operator requires you to be picked up from one of these
              select hotels. If your hotel is not listed, you can meet your tour
              operator at any of the other hotels listed below.
            </div>
          )}
          <div>{renderSelectHotelPickup(true)}</div>
        </>
      ) : (
        <>
          <div
            className={clsx(styles["section-title"], {
              [styles.error]: hasPickupPointSelectionError,
            })}
          >
            Hotel pickup available
          </div>
          <div
            className={clsx(styles["section-subtitle"], {
              [styles.error]: hasPickupPointSelectionError,
            })}
          >
            This provider offers pickup from select hotels.
          </div>
          <div className={styles["radio-selection-container"]}>
            {options.map((option) => (
              <React.Fragment key={option}>
                <div className={styles["radio-row-container"]}>
                  <Radio
                    className={styles.radio}
                    checked={option === selectedPickupPoint}
                    onChange={() => handleRowSelection(option)}
                  />
                  <Box
                    className={clsx(styles["row-label"], {
                      [styles.selected]: option === selectedPickupPoint,
                    })}
                    onClick={() => handleRowSelection(option)}
                  >
                    {pickupPointOptionLabels[option]}
                  </Box>
                </div>

                {option === PickupPointOption.SelectPickup &&
                  selectedPickupPoint === option && (
                    <div className={styles["pickup-container"]}>
                      {renderSelectHotelPickup()}
                    </div>
                  )}

                {option === PickupPointOption.PickupLater &&
                  selectedPickupPoint === option && (
                    <div
                      className={clsx(
                        styles["pickup-container"],
                        styles["section-subtitle"]
                      )}
                    >
                      Contact your tour operator before your experience to
                      arrange a pickup.
                    </div>
                  )}
              </React.Fragment>
            ))}
          </div>
          {hasPickupPointSelectionError && (
            <div className={clsx(styles["error-caption"], [styles.error])}>
              You must make a selection to continue.
            </div>
          )}
        </>
      )}
    </div>
  );
};
