import { ExperiencesAgeBandEnum, IPassengerCounts } from "redmond";

export const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId);

  if (section) {
    const BANNER_HEIGHT =
      document.querySelector(".b2b-portal-banner-root")?.getBoundingClientRect()
        .height ?? 0;
    const HEADER_HEIGHT =
      document.querySelector(".app-header")?.getBoundingClientRect().height ??
      0;
    const NAV_HEIGHT =
      document
        .querySelector(".experiences-shop-navigation")
        ?.getBoundingClientRect().height ?? 0;
    const MARGIN = 20;
    const yOffset = BANNER_HEIGHT + HEADER_HEIGHT + NAV_HEIGHT + MARGIN;

    window.scrollTo({
      top: section.offsetTop - yOffset,
      behavior: "smooth",
    });
  }
};

export const getTotalTravelersCount = (travelerCounts?: IPassengerCounts) => {
  if (!travelerCounts) return 0;

  return (
    (travelerCounts?.adultsCount ?? 0) +
    (travelerCounts?.seniorsCount ?? 0) +
    (travelerCounts?.youthsCount ?? 0) +
    (travelerCounts?.childrenCount ?? 0) +
    (travelerCounts?.infantsCount ?? 0)
  );
};

export const getPaxMixFromTravelersCount = (
  travelerCounts?: IPassengerCounts
) => [
  {
    ageBand: ExperiencesAgeBandEnum.INFANT,
    numberOfTravelers: travelerCounts?.infantsCount ?? 0,
  },
  {
    ageBand: ExperiencesAgeBandEnum.SENIOR,
    numberOfTravelers: travelerCounts?.seniorsCount ?? 0,
  },
  {
    ageBand: ExperiencesAgeBandEnum.YOUTH,
    numberOfTravelers: travelerCounts?.youthsCount ?? 0,
  },
  {
    ageBand: ExperiencesAgeBandEnum.ADULT,
    numberOfTravelers: travelerCounts?.adultsCount ?? 2,
  },
  {
    ageBand: ExperiencesAgeBandEnum.CHILD,
    numberOfTravelers: travelerCounts?.childrenCount ?? 0,
  },
];
