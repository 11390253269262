import React, { useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ExperiencesPriceBreakdown,
  FiatPrice,
  RewardsAccount,
  RewardsPrice,
} from "redmond";
import {
  CurrencyFormatters,
  getRewardText,
  Icon,
  IconName,
  getPriceString,
  quickPluralize,
  ActionLink,
  DesktopPopupModal,
} from "halifax";

import "./styles.scss";
import { PricingRow } from "./components/PricingRow";
import {
  CANCELLATION_POLICY,
  FREE_CANCELLATION,
  TAXES_AND_FEES,
  VIEW_POLICY_DETAILS,
} from "../textConstants";
import {
  IShopCancellationPolicyProps,
  ShopCancellationPolicy,
} from "../../shop/components/ShopCancellationPolicy/component";

export interface ExperiencesPricingBreakdownProps {
  className?: string;
  priceBreakdown?: ExperiencesPriceBreakdown;
  largestValueAccount?: RewardsAccount;
  isFreeCancellation?: boolean;
  cancellationPolicyProps?: IShopCancellationPolicyProps | null;
  mobile?: boolean;
}

export type SummaryLineType =
  // | "custom"
  // | "markdown"
  // | "payment"
  // | "rewards"
  "total";

// export interface ICreditLineItem {
//   amount: number;
//   currency: string;
//   icon?: IconName;
//   label: string;
//   labelProps?: TypographyProps;
//   valueProps?: TypographyProps;
// }

export interface IExperiencesPricingLineItem {
  lineTitle?: string;
  amount?: number;
  skipIfPriceUnavailable?: boolean;
  icon?: IconName;
  label: string;
}

interface IExperiencesSummaryLineItemBase {
  type: SummaryLineType;
  fiatPrice: FiatPrice;
}

// export interface ICustomLineItem extends ISimplifiedSummaryLineItemBase {
//   type: "custom";
//   icon?: IconName;
//   label: string;
//   rewardsPrice?: RewardsPrice;
//   className?: string;
//   travelCreditBreakdown?: {
//     label: string;
//     formattedAmount: string;
//   }[];
// }

// export interface IMarkdownLineItem extends ISimplifiedSummaryLineItemBase {
//   type: "markdown";
//   className?: string;
//   label: string;
//   markdownType: PaymentTypeEnum;
//   redemptionRate?: number;
//   rewardsPrice?: RewardsPrice;
// }

export interface IExperiencesTotalLineItem
  extends IExperiencesSummaryLineItemBase {
  type: "total";
  label?: string;
  details?: string;
  rewardsPrice?: RewardsPrice;
}

// export interface IRewardsLineItem extends ISimplifiedSummaryLineItemBase {
//   type: "rewards";
//   rewardsAccountName: string;
//   rewardsPrice: RewardsPrice;
// }

// export interface IPaymentLineItem extends ISimplifiedSummaryLineItemBase {
//   type: "payment";
//   label?: string;
//   lastFour?: string;
//   rewardsPrice?: RewardsPrice;
// }

export type IExperiencesSummaryLineItem = IExperiencesTotalLineItem;

const renderPricingItem = ({
  index,
  amount,
  skipIfPriceUnavailable,
  currencyCode,
  label,
}: IExperiencesPricingLineItem & {
  index: number;
  currencyCode: string;
}) => {
  const renderPricingRow = ({
    label,
    amount,
    className,
    rowIcon,
  }: {
    label: string;
    amount?: number;
    className?: string;
    rowIcon?: IconName;
  }) => {
    if (amount === undefined && skipIfPriceUnavailable) {
      return <></>;
    }

    return (
      <PricingRow
        className={clsx(className ?? "fees")}
        amount={amount}
        currency={currencyCode}
        label={label}
        rowIcon={rowIcon}
      />
    );
  };

  return (
    <Box className="items-section" key={index}>
      <Box className="pricing-item-body">
        <Box className="content-section">
          {renderPricingRow({
            label: label,
            amount: amount,
          })}
        </Box>
      </Box>
    </Box>
  );
};

const renderSummaryItem = (
  index: number,
  summaryLineItem: IExperiencesSummaryLineItem
) => {
  const { type: summaryType } = summaryLineItem;
  let leftSectionContent = "";
  let rightSectionContent: {
    fiatAmountText: string;
    rewardsAmountText?: string;
    totalAmountText?: string;
  } | null = null;
  let rowIcon;

  switch (summaryLineItem.type) {
    case "total": {
      const { fiatPrice, label, rewardsPrice } = summaryLineItem;

      leftSectionContent = label ?? "Total";
      rightSectionContent = {
        fiatAmountText: `${CurrencyFormatters.get(
          fiatPrice.currencyCode
        ).format(fiatPrice.value)}`,
        rewardsAmountText: rewardsPrice
          ? getRewardText({
              reward: rewardsPrice,
            })
          : undefined,
      };
      break;
    }
    default:
      break;
  }

  if (!leftSectionContent || !rightSectionContent) {
    return null;
  }

  const { fiatAmountText, rewardsAmountText } = rightSectionContent;

  return (
    <Box className={clsx("summary-item-wrapper")} key={index}>
      <Box className={clsx("summary-item-line-wrapper")}>
        <Box className={clsx("summary-line-section", "left", summaryType)}>
          <Typography
            className="label"
            component="p"
            variant={summaryType === "total" ? "h6" : "subtitle2"}
          >
            {rowIcon && <Icon className="row-icon" name={rowIcon} />}
            {leftSectionContent}
            {summaryType === "total" && "details" in summaryLineItem && (
              <span className="label-details">{summaryLineItem.details}</span>
            )}
          </Typography>
        </Box>
        <Box
          className={clsx(
            "summary-line-section",
            "right",
            summaryLineItem.type
          )}
        >
          <Typography
            className={clsx("value", "fiat")}
            variant="h6"
            component="p"
          >
            {fiatAmountText}
          </Typography>
          {rewardsAmountText && (
            <>
              <Typography
                className="separator"
                variant="subtitle2"
                component="p"
              >
                /
              </Typography>
              <Typography
                className="value rewards"
                variant="subtitle2"
                component="p"
              >
                {rewardsAmountText}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const ExperiencesPricingBreakdown = (
  props: ExperiencesPricingBreakdownProps
): JSX.Element => {
  const {
    className,
    priceBreakdown,
    largestValueAccount,
    isFreeCancellation,
    cancellationPolicyProps,
    mobile,
  } = props;
  const [openCancellationPolicyModal, setOpenCancellationPolicyModal] =
    useState(false);

  const pricingItems: IExperiencesPricingLineItem[] = useMemo(() => {
    if (!priceBreakdown) return [];
    const priceItems = priceBreakdown.lineItems.map(
      (item): IExperiencesPricingLineItem => ({
        label: `${getPriceString({
          price: item.specificAgeBand.pricePerTraveler.fiat.value,
          currencySymbol:
            item.specificAgeBand.pricePerTraveler.fiat.currencySymbol,
        })} x ${item.specificAgeBand.numberOfTravelers} ${quickPluralize(
          item.specificAgeBand.numberOfTravelers,
          item.specificAgeBand.ageBand.toLowerCase()
        )}`,
        amount: item.totalPriceForAgeBand.fiat.value,
      })
    );
    priceItems.push({
      label: TAXES_AND_FEES,
      amount: priceBreakdown.bookingFee.fiat.value,
    });
    return priceItems;
  }, [priceBreakdown]);

  const summaryItems: IExperiencesSummaryLineItem[] = useMemo(() => {
    if (!priceBreakdown) return [];
    const totalItem: IExperiencesTotalLineItem = {
      type: "total",
      fiatPrice: priceBreakdown.totalPriceWithFees.fiat,
      rewardsPrice: largestValueAccount
        ? priceBreakdown.totalPriceWithFees.rewards[
            largestValueAccount?.accountReferenceId
          ]
        : undefined,
    };
    return [totalItem];
  }, [priceBreakdown]);

  const currencyCode = useMemo(() => {
    if (!priceBreakdown) return "USD";
    return priceBreakdown.totalPriceWithFees.fiat.currencyCode;
  }, [priceBreakdown]);

  return (
    <>
      <Box className={clsx(className, "experiences-pricing-breakdown")}>
        <Typography
          className="experiences-pricing-breakdown-title"
          variant="h6"
        >
          Checkout breakdown
        </Typography>
        {isFreeCancellation && !mobile && (
          <Box className="experiences-shop-checkout-breakdown-cancellation-policy">
            <Typography className="experiences-shop-checkout-breakdown-cancellation-policy-text">
              <Icon name={IconName.CheckCircleFilledGreen} />
              {FREE_CANCELLATION}
            </Typography>
            <ActionLink
              onClick={() => {
                setOpenCancellationPolicyModal(true);
              }}
              className="experiences-shop-checkout-breakdown-cancellation-policy-button"
              content={
                <Typography className="experiences-shop-checkout-breakdown-cancellation-policy-text">
                  {VIEW_POLICY_DETAILS}
                  <Icon name={IconName.InfoCircle} />
                </Typography>
              }
            />
          </Box>
        )}
        {pricingItems && (
          <Box className="items-section items-section--pricing">
            {pricingItems.map((item, index) =>
              renderPricingItem({
                index,
                currencyCode,
                ...item,
              })
            )}
          </Box>
        )}
        <Box className="items-section items-section--summary">
          {summaryItems.map((item, index) => renderSummaryItem(index, item))}
        </Box>
      </Box>
      {isFreeCancellation && cancellationPolicyProps && (
        <DesktopPopupModal
          open={openCancellationPolicyModal}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            setOpenCancellationPolicyModal(false);
          }}
          className="desktop-experiences-shop-cancellation-policy-modal-root"
          contentClassName="desktop-cancellation-policy-modal-wrapper"
          invisibleBackdrop={false}
        >
          <div className="experiences-shop-section-container">
            <Typography className="experiences-shop-component-title">
              {CANCELLATION_POLICY}
            </Typography>
            <ShopCancellationPolicy {...cancellationPolicyProps} />
          </div>
        </DesktopPopupModal>
      )}
    </>
  );
};
