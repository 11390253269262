import React from "react";
import { Box, Typography } from "@material-ui/core";

import "./styles.scss";
import {
  TRIP_REVIEW_HEADING_TEXT,
  TRIP_REVIEW_SUBTITLE_TEXT,
} from "./textConstants";
import { TravelerSelection, ContactInfo, PriceBreakdown } from "..";

export const MobileTripReview = () => (
  <Box className="experiences-checkout-trip-review">
    <Typography className="trip-review-heading" variant="h2">
      {TRIP_REVIEW_HEADING_TEXT}
    </Typography>
    <Typography className="trip-review-subtitle">
      {TRIP_REVIEW_SUBTITLE_TEXT}
    </Typography>

    <TravelerSelection isMobile />
    <ContactInfo isMobile />
    <PriceBreakdown isMobile />
  </Box>
);
