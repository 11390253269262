import React from "react";
import { Box, Typography } from "@material-ui/core";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { ExperienceLocation, ExperienceLogistics, ICoordinates } from "redmond";
import { convertICoordinatesToGoogleMapCoords } from "../../../../utils/googleMapsHelpers";
import { ActionButton, Icon, IconName, MapPin } from "halifax";
import * as textConstants from "../../textConstants";

export interface IShopMapProps {
  locationLogistics: ExperienceLogistics;
  setShowExpandedlMap: (arg: boolean) => void;
  variant: "small" | "large";
  sameAsStart: boolean;
}

export const renderAddress = (
  location: ExperienceLocation,
  sameAsStart?: boolean
) => {
  const [line1, ...rest] = location.address.split(",");
  const line2 = rest.join(",");

  return (
    <Box className={styles["experiences-shop-overview-map-address"]}>
      {sameAsStart ? (
        <Typography variant="body2">
          {textConstants.END_SAME_AS_START}
        </Typography>
      ) : (
        <>
          {location.name && (
            <Typography variant="body2">{location.name}</Typography>
          )}
          <Typography variant="body2">{line1}</Typography>
          <Typography variant="body2">{line2}</Typography>
        </>
      )}
    </Box>
  );
};

export const ShopMap = (props: IShopMapProps) => {
  const { locationLogistics, setShowExpandedlMap, variant, sameAsStart } =
    props;

  const startCoordinates: ICoordinates[] = [];
  locationLogistics.startLocations.map((startLocation) => {
    startCoordinates.push({
      lat: startLocation.coordinates.lat - 0.00015,
      lon: startLocation.coordinates.lon - 0.003,
    });
  });

  const endCoordinates: ICoordinates[] = [];
  locationLogistics.endLocations.map((endLocation) => {
    endCoordinates.push({
      lat: endLocation.coordinates.lat - 0.00015,
      lon: endLocation.coordinates.lon - 0.003,
    });
  });

  let centerCoordinates = startCoordinates[0];
  if (!sameAsStart) {
    centerCoordinates = {
      lat: (startCoordinates[0].lat + endCoordinates[0].lat) / 2,
      lon: (startCoordinates[0].lon + endCoordinates[0].lon) / 2,
    };
  }
  const center = convertICoordinatesToGoogleMapCoords(centerCoordinates);

  if (variant == "small") {
    return (
      <Box className={clsx(styles["small-map-container"])}>
        <GoogleMap
          id="small-map"
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "8px",
          }}
          options={{
            clickableIcons: false,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            draggable: false,
            fullscreenControl: false,
            draggableCursor: "pointer",
          }}
          center={center}
          zoom={16}
        >
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={center}
          >
            {/* This overlay view requires a child but we don't want anything in the map */}
            <div></div>
          </OverlayView>
        </GoogleMap>

        <ActionButton
          defaultStyle="h4r-secondary"
          className={styles["show-map-button"]}
          onClick={(event) => {
            event?.stopPropagation();
            setShowExpandedlMap(true);
          }}
          message={
            <Typography className={styles["toggle-map-button-text"]}>
              {textConstants.VIEW_ON_MAP}
            </Typography>
          }
        />
      </Box>
    );
  } else {
    return (
      <Box className={clsx(styles["large-map-container"])}>
        <GoogleMap
          id="large-map"
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "8px",
          }}
          options={{
            clickableIcons: false,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: true,
          }}
          center={center}
          zoom={16}
        >
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={convertICoordinatesToGoogleMapCoords(startCoordinates[0])}
          >
            <Box className={clsx(styles["location-marker-wrapper"])}>
              <Box className={clsx(styles["location-tooltip"])}>
                <Typography
                  variant="h6"
                  className={clsx(styles["location-tooltip-title"])}
                >
                  <Icon name={IconName.B2BMapPin} />
                  {sameAsStart
                    ? textConstants.STARTING_AND_END_POINT
                    : textConstants.STARTING_POINT}
                </Typography>
                <Box className={clsx(styles["location-tooltip-content"])}>
                  {renderAddress(locationLogistics.startLocations[0])}
                </Box>
              </Box>
              <MapPin onClick={() => {}} />
            </Box>
          </OverlayView>
          {!sameAsStart && (
            <OverlayView
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={convertICoordinatesToGoogleMapCoords(endCoordinates[0])}
            >
              <Box className={clsx(styles["location-marker-wrapper"])}>
                <Box className={clsx(styles["location-tooltip"])}>
                  <Typography
                    variant="h6"
                    className={clsx(styles["location-tooltip-title"])}
                  >
                    <Icon
                      name={IconName.FlagIcon}
                      className={clsx(styles["end-location-icon"])}
                    />
                    {textConstants.END_POINT}
                  </Typography>
                  <Box className={clsx(styles["location-tooltip-content"])}>
                    {renderAddress(locationLogistics.endLocations[0])}
                  </Box>
                </Box>
                <MapPin onClick={() => {}} />
              </Box>
            </OverlayView>
          )}
        </GoogleMap>
      </Box>
    );
  }
};
