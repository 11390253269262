import { getObjectKeysAsObject } from "@capone/checkout";

import { deleteUserPassengersService } from "./deleteUserPassenger";
import { fetchUserPassengersService } from "./fetchUserPassengers";
import { trackEditTravelers } from "./trackEditTravelers";
import { trackNewTraveler } from "./trackNewTraveler";
import { updateUserPassengerService } from "./updateUserPassenger";

export const services = {
  deleteUserPassengersService,
  fetchUserPassengersService,
  trackEditTravelers,
  trackNewTraveler,
  updateUserPassengerService,
};

export const ServiceTypes = getObjectKeysAsObject(services);
