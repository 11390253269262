import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { ExperiencesRewardsAndPayment } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getRewardsAccounts } from "../../../rewards/reducer";

export const mapStateToProps = (state: IStoreState) => ({
  rewardsAccounts: getRewardsAccounts(state),
});

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesRewardsAndPaymentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedExperiencesRewardsAndPayment = withRouter(
  connector(ExperiencesRewardsAndPayment)
);
