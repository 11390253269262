import {
  CartServices,
  getObjectKeysAsObject,
  ContactInformationServices,
  CardPaymentServices,
} from "@capone/checkout";

import { checkoutServices } from "./common";

import {
  AdditionalDetailsServices,
  ExperiencesTravelerServices,
} from "../../../../checkout";

export const Services: any = {
  ...CartServices,
  ...checkoutServices,
  ...ContactInformationServices,
  ...CardPaymentServices,
  ...AdditionalDetailsServices,
  ...ExperiencesTravelerServices,
};

export const ServiceTypes = getObjectKeysAsObject(Services);
