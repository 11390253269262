export const BASE_PATH_HOME = "/";
export const PATH_HOME = "/experiences/";
export const PATH_HOME_SEARCH = `${PATH_HOME}search/`;

export const PATH_AVAILABILITY = `${PATH_HOME}availability/`;
export const PATH_SHOP = `${PATH_HOME}shop/`;
export const PATH_TRAVEL_SALE = `/travelsale/`;

export const PATH_REVIEW = `${PATH_HOME}review/`;
export const PATH_BOOK = `${PATH_HOME}book/`;
export const PATH_BOOK_CONFIRMATION = `${PATH_BOOK}confirm/`;
