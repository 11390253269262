import { State } from "xstate";
import {
  getChildState,
  getNestedChildState,
  ParentState,
} from "@capone/checkout";
import { BookingQuestionId, PersonId, Person } from "redmond";

import { ExperiencesContext } from "../../../modules/book/state/types";
import {
  TravelerInformationState,
  TravelerInformationChildState,
} from "./types";

type TravelerStateType = State<ExperiencesContext>;
type TravelerStateWithoutValue = Pick<TravelerStateType, "context">;

export const getIsTravelerInformationLoading = (state: TravelerStateType) => {
  const childState = getChildState(state.value) as TravelerInformationState;
  const nestedChildState = getNestedChildState(
    state.value
  ) as TravelerInformationChildState;

  return (
    childState === TravelerInformationState.loading ||
    [
      TravelerInformationChildState.add,
      TravelerInformationChildState.delete,
      TravelerInformationChildState.update,
    ].includes(nestedChildState)
  );
};

export const getUserTravelers = ({ context }: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].userTravelers;

export const getSelectedTravelerIds = ({
  context,
}: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].selectedTravelerIds;

export const getAllSelectedUserTravelers = (
  state: TravelerStateWithoutValue
) => {
  const selectedTravelerIds = getSelectedTravelerIds(state);
  const userTravelers = getUserTravelers(state);

  return userTravelers.filter((traveler) =>
    selectedTravelerIds.includes(traveler.id)
  );
};

export const getNumTravelerAlertDismissed = ({
  context,
}: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].numTravelerAlertDismissed;

export const getCurrentUserTraveler = ({
  context,
}: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].currentUserTraveler;

export const getOpenTravelerFormModal = ({ value }: TravelerStateType) =>
  TravelerInformationState.travelerForm ===
  (getChildState(value) as TravelerInformationState);

export const getTravelerErrorOpen = ({ value }: TravelerStateType) => {
  const stateValue = getChildState(value);
  return TravelerInformationState.error === stateValue;
};

export const getTravelerError = ({ context }: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].error;

export const getTravelerVisited = ({ context }: TravelerStateWithoutValue) =>
  context[ParentState.experiencesTravelerInformation].visited;

export const getTravelerStateValidated = (state: TravelerStateWithoutValue) =>
  getSelectedTravelerIds(state).length > 0;

export const getSelectedPrimaryTravelerId = (
  state: TravelerStateWithoutValue
) =>
  state.context[ParentState.experiencesPrimaryTraveler]
    .selectedPrimaryTravelerId;

export const getSelectedPrimaryTraveler = (
  state: TravelerStateWithoutValue
): Person | undefined => {
  const selectedPrimaryTravelerId = getSelectedPrimaryTravelerId(state);
  const { userTravelers } =
    state.context[ParentState.experiencesTravelerInformation];

  return userTravelers?.find(
    (person) => person.id === selectedPrimaryTravelerId
  );
};

// we only handle these per traveler booking questions in traveler information steps
const HANDLED_PER_TRAVELER_BOOKING_QUESTIONS: BookingQuestionId[] = [
  BookingQuestionId.Height,
  BookingQuestionId.Weight,
  BookingQuestionId.PassportPassportNo, // this covers for all passport booking question
];

export const getPerTravelerBookingQuestion = ({
  context,
}: TravelerStateWithoutValue) => {
  const bookingQuestions =
    context.experienceShop?.bookingQuestions?.perTraveler || [];
  const { bookingQuestionResponse } = context.experiencesTravelerInformation;

  const handledBookingQuestionIds = bookingQuestions
    .filter((question) =>
      HANDLED_PER_TRAVELER_BOOKING_QUESTIONS.includes(question.id)
    )
    .map((question) => question.id);

  const requirePassport = handledBookingQuestionIds.includes(
    BookingQuestionId.PassportPassportNo
  );
  const requireHeight = handledBookingQuestionIds.includes(
    BookingQuestionId.Height
  );
  const requireWeight = handledBookingQuestionIds.includes(
    BookingQuestionId.Weight
  );

  return {
    requirePassport,
    requireHeight,
    requireWeight,
    hasAnsweredBookingQuestions: (travelerId: PersonId) => {
      if (!requirePassport && !requireHeight && !requireWeight) {
        return true;
      }

      const { weight, heightFt, heightIn } =
        bookingQuestionResponse?.[travelerId] || {};
      const { passport } =
        context.experiencesTravelerInformation.currentUserTraveler || {};

      const missingWeight = requireWeight && !weight;
      const missingHeight = requireHeight && (!heightFt || !heightIn);
      const missingPassport = requirePassport && !passport;

      return !(missingWeight || missingHeight || missingPassport);
    },
    getCurrentTravelerBookingQuestionResponse: (travelerId: PersonId) => {
      if (!bookingQuestionResponse || !bookingQuestionResponse[travelerId]) {
        return {
          weight: "",
          heightFt: "",
          heightIn: "",
        };
      }

      return bookingQuestionResponse[travelerId];
    },
  };
};
