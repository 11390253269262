export const PRIMARY_TRAVELER_TITLE_SUBHEADING =
  "Select the primary traveler for this experience.";
export const PRIMARY_TRAVELER_SUBTITLE =
  "The primary traveler must be present upon checking in for your experience..";

export const ADULT_TITLE = "Adult";
export const CHILD_TITLE = "Child";

export const TRAVELER_INFO_TEXT = "Add Primary Traveler";
export const ADD_TRAVELER_ERROR_MESSAGE =
  "Add or choose one traveler to continue.";
export const PRIMARY_TRAVELER_NOTICE = "Select 1 primary traveler to continue.";

export const CONTINUE_TEXT = "Continue";
