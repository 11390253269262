import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  BackButton,
  DesktopPopupModal,
  ExperiencesAvailabilityCard,
  Icon,
  IconName,
} from "halifax";
import { ExperienceAvailability, RewardsAccount } from "redmond";

import styles from "./styles.module.scss";
import { ExperiencesSimilarCard, ExperiencesCompareCard } from "./components";
import {
  COMPARE_ITINERARY,
  SEE_MORE_SIMILAR_EXPERIENCES,
} from "../../textConstants";
import { PATH_SHOP } from "../../../../utils/paths";
import { transformToStringifiedShopQuery } from "../../../../utils/queryStringHelpers";
import { getEarnTagText } from "../../../availability/textConstants";

export interface IShopSimilar {
  isSkeleton: boolean;
  selectedExperienceInfo?: ExperienceAvailability;
  similarExperiences?: Array<ExperienceAvailability>;
  largestValueAccount?: RewardsAccount;
}

export interface IShopSimilarProps extends IShopSimilar {
  fromDate: Date | null;
  untilDate: Date | null;
  mobile?: boolean;
  rewardsKey?: string;
}

const SIMILAR_EXPERIENCES_SKELETON: Array<ExperienceAvailability> = Array.from({
  length: 3,
});

export const ShopSimilar = (props: IShopSimilarProps) => {
  const {
    isSkeleton,
    selectedExperienceInfo,
    similarExperiences = SIMILAR_EXPERIENCES_SKELETON,
    largestValueAccount,
    fromDate,
    untilDate,
    mobile = false,
    rewardsKey,
  } = props;

  const [isOverFlow, setIsOverFlow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedComparedExpId, setSelectedComparedExpId] = useState("");

  const shopSimilarId = "experiences-shop-similar";

  const determineOverFlow = () => {
    const overFlowedElement = document.getElementById(shopSimilarId);
    if (overFlowedElement) {
      return overFlowedElement.clientWidth < overFlowedElement.scrollWidth;
    }
    return false;
  };

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (isSkeleton || !similarExperiences) return;

    const overFlow = determineOverFlow();
    setIsOverFlow(overFlow);
  }, [windowWidth, isSkeleton, similarExperiences]);

  const leftScrollButton = () => {
    const overFlowedElement = document.getElementById(shopSimilarId);
    if (overFlowedElement) {
      overFlowedElement.scrollLeft -= 350;
    }
  };

  const rightScrollButton = () => {
    const overFlowedElement = document.getElementById(shopSimilarId);
    if (overFlowedElement) {
      overFlowedElement.scrollLeft += 350;
    }
  };

  const renderDesktopVersion = () => (
    <>
      <Box className={styles["experiences-shop-similar-container"]}>
        <ExperiencesSimilarCard
          experiencesAvailabilityInfo={selectedExperienceInfo}
          rewardsAccount={largestValueAccount}
          selectedExperienceIdValue={selectedExperienceInfo?.id?.value}
          fromDate={fromDate}
          untilDate={untilDate}
          isSkeleton={isSkeleton}
        />
        <Box className={styles["experiences-shop-other-similar-container"]}>
          {isOverFlow && (
            <BackButton
              aria-label={SEE_MORE_SIMILAR_EXPERIENCES}
              onClick={leftScrollButton}
              className={styles["left-scroll-button"]}
            />
          )}
          <Box
            className={styles["experiences-shop-other-similar-wrapper"]}
            id={shopSimilarId}
          >
            {similarExperiences.map(
              (
                experiencesAvailabilityInfo: ExperienceAvailability,
                expIdx: number
              ) => (
                <ExperiencesSimilarCard
                  key={experiencesAvailabilityInfo?.id?.value || expIdx}
                  experiencesAvailabilityInfo={experiencesAvailabilityInfo}
                  rewardsAccount={largestValueAccount}
                  selectedExperienceIdValue={selectedExperienceInfo?.id?.value}
                  fromDate={fromDate}
                  untilDate={untilDate}
                  isSkeleton={isSkeleton}
                  onCompareClick={(comparedExpId) => {
                    setSelectedComparedExpId(comparedExpId);
                    setOpenModal(true);
                  }}
                />
              )
            )}
          </Box>
          {isOverFlow && (
            <BackButton
              aria-label={SEE_MORE_SIMILAR_EXPERIENCES}
              onClick={rightScrollButton}
              className={styles["right-scroll-button"]}
            />
          )}
        </Box>
      </Box>
      {selectedExperienceInfo?.id?.value && (
        <DesktopPopupModal
          className={styles["experiences-shop-similar-compare-modal-popup"]}
          open={openModal}
          invisibleBackdrop={false}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            setOpenModal(false);
          }}
        >
          <div className={styles["experiences-shop-similar-modal-header"]}>
            {COMPARE_ITINERARY}
          </div>
          <div className={styles["experiences-shop-similar-modal-content"]}>
            <ExperiencesCompareCard
              experiencesAvailabilityInfo={selectedExperienceInfo}
              selectedExperienceIdValue={selectedExperienceInfo.id.value}
              fromDate={fromDate}
              untilDate={untilDate}
              closeModal={() => setOpenModal(false)}
            />
            <ExperiencesCompareCard
              experiencesAvailabilityInfo={
                similarExperiences.find(
                  (exp) => exp.id.value === selectedComparedExpId
                ) as ExperienceAvailability
              }
              selectedExperienceIdValue={selectedExperienceInfo.id.value}
              fromDate={fromDate}
              untilDate={untilDate}
              closeModal={() => setOpenModal(false)}
            />
          </div>
        </DesktopPopupModal>
      )}
    </>
  );

  const renderMobileVersion = () => (
    <Box
      className={
        (styles["experiences-shop-similar-container"], styles["mobile"])
      }
    >
      <Box className={styles["experiences-shop-other-similar-container"]}>
        <Box
          className={styles["experiences-shop-other-similar-wrapper"]}
          id={shopSimilarId}
        >
          {similarExperiences.map(
            (experiencesAvailabilityInfo: ExperienceAvailability) => (
              <ExperiencesAvailabilityCard
                onClick={() => {
                  window.open(
                    `${PATH_SHOP}${transformToStringifiedShopQuery(
                      experiencesAvailabilityInfo?.id,
                      fromDate,
                      untilDate
                    )}`,
                    "_blank"
                  );
                }}
                key={selectedExperienceInfo?.id.value}
                experiencesAvailabilityInfo={experiencesAvailabilityInfo}
                variant="card"
                rewardsKey={rewardsKey}
                earnTagContent={
                  largestValueAccount && (
                    <>
                      <Icon name={IconName.StarIcon} />
                      <Typography
                        className="earn-tag-text"
                        dangerouslySetInnerHTML={{
                          __html: getEarnTagText(
                            // TODO: Remove `hotelsMultiplier` and use `experiencesMultiplier` once we have that from BE https://hopper-jira.atlassian.net/browse/COTA-1548
                            largestValueAccount.earn.hotelsMultiplier,
                            largestValueAccount.rewardsBalance
                              .currencyDescription ??
                              largestValueAccount.rewardsBalance.currency
                          ),
                        }}
                      />
                    </>
                  )
                }
                isSkeleton={isSkeleton}
              />
            )
          )}
        </Box>
      </Box>
    </Box>
  );
  return mobile ? renderMobileVersion() : renderDesktopVersion();
};
