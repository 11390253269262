import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import "./styles.scss";
import { CurrencyFormatters, Icon, IconName } from "halifax";
import React from "react";

export interface IPricingRowProps {
  amount?: number;
  className?: string;
  currency?: string;
  label: string;
  rowIcon?: IconName;
}

const defaultProps: Partial<IPricingRowProps> = {
  className: "fees",
};

const Amount = (props: {
  origAmount?: number;
  amount?: number;
  currency?: string;
}) => {
  const { origAmount, amount, currency } = props;
  if (amount === undefined) {
    return (
      <Typography className="price-unavail" variant="body2">
        Price unavailable
      </Typography>
    );
  }

  if (origAmount) {
    return (
      <>
        <span className="cross-out">
          {CurrencyFormatters.get(currency).format(origAmount)}
        </span>{" "}
        <span className="green-text">
          {CurrencyFormatters.get(currency).format(amount)}
        </span>
      </>
    );
  }

  return <>{CurrencyFormatters.get(currency).format(amount)}</>;
};

/**
 * @description Pretty much the same component as the one in `PriceBreakdown`
 * but this one is used by exchange-module in its Pricing Breakdown page
 */
const PricingRow = (props: IPricingRowProps): JSX.Element => {
  const { amount, className, currency, label, rowIcon } = props;

  return (
    <Box className={clsx("price-line", className, {})}>
      <Typography className="price-line__label" variant="subtitle2">
        {rowIcon && (
          <Icon className={clsx("price-line__label__icon")} name={rowIcon} />
        )}
        {label}
      </Typography>
      <Typography className={clsx("price-line__value")} variant="subtitle2">
        <Amount amount={amount} currency={currency} />
      </Typography>
    </Box>
  );
};

PricingRow.defaultProps = defaultProps;

export default PricingRow;
