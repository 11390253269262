import { ICoordinates } from "redmond";

export const convertICoordinatesToGoogleMapCoords: (
  coordinates: ICoordinates
) => google.maps.LatLng = (coordinates) => {
  return new google.maps.LatLng({
    lat: coordinates.lat,
    lng: coordinates.lon,
  });
};
