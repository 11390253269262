import {
  CardPaymentEventTypes,
  CardPaymentServices,
  ChildMachineConfig,
  ParentState,
  PaymentInformationChildState,
  PaymentInformationState,
  CardPaymentActionTypes,
  CardPaymentGuardTypes,
  WalletServices,
  combineServices,
  ParentContextWithWallet,
} from "@capone/checkout";
import { ActionTypes } from "../../../modules/book/state/actions";
import { GuardTypes } from "../../../modules/book/state/guards";

// eslint-disable-next-line import/prefer-default-export
export const getMachineState = (config: ChildMachineConfig) => ({
  id: ParentState.cardPayment,
  initial: PaymentInformationState.loading,
  always: {
    actions: CardPaymentActionTypes.setPaymentVisited,
    cond: CardPaymentGuardTypes.notVisitedPayment,
  },
  states: {
    [PaymentInformationState.loading]: {
      initial: "fetchWallet",
      states: {
        fetchWallet: {
          invoke: {
            src: (ctx: ParentContextWithWallet) =>
              combineServices([
                WalletServices.getWalletCredits(ctx),
                WalletServices.getWalletCreditHistory(ctx),
              ]),
            onDone: {
              target: "listPaymentMethods",
              actions: ActionTypes.setWalletCredits,
            },
          },
        },
        listPaymentMethods: {
          invoke: {
            src: CardPaymentServices.listPaymentMethods,
            onDone: {
              target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
              actions: CardPaymentActionTypes.handleListPayments,
            },
            onError: {
              target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.error}`,
              actions: CardPaymentActionTypes.setPaymentError,
            },
          },
        },
      },
    },
    [PaymentInformationState.idle]: {
      on: {
        [CardPaymentEventTypes.SET_SELECTED_PAYMENT_METHOD_ID]: {
          actions: CardPaymentActionTypes.setSelectedPaymentMethodId,
        },
      },
    },
    [PaymentInformationState.add]: {
      initial: PaymentInformationChildState.idle,
      states: {
        [PaymentInformationChildState.idle]: {},
        [PaymentInformationChildState.verify]: {
          invoke: {
            src: CardPaymentServices.verifyPayment,
            onDone: {
              actions: CardPaymentActionTypes.setPaymentMethodId,
              target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
            },
            onError: {
              actions: CardPaymentActionTypes.setPaymentError,
              target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.error}`,
            },
          },
        },
      },
    },
    [PaymentInformationState.delete]: {
      invoke: {
        src: CardPaymentServices.deletePaymentMethod,
        onDone: {
          actions: CardPaymentActionTypes.deletePaymentMethod,
          target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
        },
        onError: {
          actions: CardPaymentActionTypes.setPaymentError,
          target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.error}`,
        },
      },
    },
    [PaymentInformationState.error]: {
      on: {
        [CardPaymentEventTypes.CLEAR_PAYMENT_ERROR]: {
          target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
        },
      },
    },
  },
  on: {
    [CardPaymentEventTypes.NEXT]: [
      {
        target: `#${config.parentMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        cond: GuardTypes.isDesktop,
      },
      {
        target: `#${config.parentMachineId}.${ParentState.review}`,
        cond: GuardTypes.isMobile,
      },
    ],
    [CardPaymentEventTypes.PREVIOUS]: [
      {
        target: `#${config.parentMachineId}.${ParentState.contactInformation}`,
        cond: GuardTypes.isMobile,
      },
      {
        target: `#${config.parentMachineId}.${ParentState.experiencesTravelerInformation}`,
        cond: GuardTypes.isDesktop,
      },
    ],
    [CardPaymentEventTypes.OPEN_PAYMENT_FORM]: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}`,
    [CardPaymentEventTypes.CLOSE_PAYMENT_FORM]: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
    [CardPaymentEventTypes.VERIFY_PAYMENT_METHOD]: {
      actions: CardPaymentActionTypes.setSpreedlyToken,
      target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}.${PaymentInformationChildState.verify}`,
    },
    [CardPaymentEventTypes.DELETE_PAYMENT_METHOD]: {
      target: `#${config.parentMachineId}.${ParentState.cardPayment}.${PaymentInformationState.delete}`,
    },
  },
});
