import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { ShopErrorModal } from "./component";
import {
  hasExperiencesShopFailed,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { fetchExperienceShop } from "../../../../api/v0/shop/fetchExperiencesShop";

const mapStateToProps = (state: IStoreState) => {
  return {
    hasFailed: hasExperiencesShopFailed(state),
  };
};

const mapDispatchToProps = {
  fetchExperienceShop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ShopErrorModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedShopErrorModal = withRouter(connector(ShopErrorModal));
