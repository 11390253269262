export const UPDATE_TRAVELERS_CTA_TEXT = "Update Travelers";
export const SEARCH_AGAIN_CTA_TEXT = "Search Again";
export const CONTINUE_CTA_TEXT = "Continue";
export const SELECT_ANOTHER_EXPERIENCE_CTA_TEXT = "Select another experience";
export const TRY_AGAIN_CTA_TEXT = "Try again";

export const GENERIC_ERROR_TITLE = "There was an error.";
export const INVALID_PASSENGERS_TITLE = "Invalid Passengers";
export const PASSENGER_VALIDATION_TITLE = "Passenger Validation Error";
export const UNABLE_TO_COMPLETE_BOOKING_TITLE =
  "Unable to complete your booking";
export const NO_AVAILABILITY_TITLE = "No Availability";
export const MISSING_CONTACT_INFO_TITLE = "Missing Contact Information";
export const TOO_MANY_PASSENGERS_TITLE = "Too Many Passengers";
export const UPGRADE_REQUIRED_TITLE = "Upgrade is required";
export const BOOKING_FAILED_TITLE = "We were unable to process your booking.";

export const GENERIC_ERROR_SUBTITLE =
  "There was an error, reload the page or select another experience.";
export const GENERIC_ERROR_TRY_AGAIN_SUBTITLE =
  "There was an error, please try again or reload the page.";

// general
export const PRICE_INCREASE_TITLE = (difference: number) =>
  `The price per traveler for this experience has increased by $${difference.toFixed()}.`;
export const PRICE_DECREASE_TITLE = (difference: number) =>
  `You’re in luck! The price per traveler for this experience has decreased by $${difference.toFixed()}.`;
export const PRICE_CHANGE_SUBTITLE =
  "Sometimes experience prices can change unexpectedly. You can review the new experience price per traveler in the price total.";
