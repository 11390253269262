import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
  B2BTextField,
} from "halifax";
import {
  IPerson,
  PersonId,
  CLICKED_ADD_TRAVELER,
  ClickedAddTravelerProperties,
} from "redmond";
import {
  getParentState,
  ParentState,
  useCheckoutStateSelector as useSelector,
  useCheckoutState,
} from "@capone/checkout";

import * as constants from "./textConstants";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import { Event, TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import { PriceBreakdown } from "..";
import "./styles.scss";
import { DesktopExperiencesBookValidationError } from "../DesktopExperiencesBookWorkflow";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { ExperiencesTravelerSelectors } from "../../../../checkout";

const getTravelerWorkflowStep = ({
  openTravelerFormModal,
  isMobile,
  parentState,
}: {
  openTravelerFormModal: boolean;
  isMobile: boolean;
  parentState: ParentState;
}): TravelerSelectStep => {
  switch (true) {
    case openTravelerFormModal:
      return TravelerSelectStep.TravelerInfoForm;
    case isMobile && parentState !== ParentState.review:
      return TravelerSelectStep.TravelerSelect;
    default:
      return TravelerSelectStep.Main;
  }
};

export interface ITravelerSelectionProps {
  isMobile?: boolean;
  validationErrorTypes?: DesktopExperiencesBookValidationError[];
  showNonModalContent?: boolean;
}

export const TravelerSelection = ({
  isMobile,
  validationErrorTypes,
  showNonModalContent,
}: ITravelerSelectionProps) => {
  const history = useHistory();
  const [state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const openTravelerFormModal = useSelector(
    ExperiencesTravelerSelectors.getOpenTravelerFormModal
  );

  const travelers = useSelector(ExperiencesTravelerSelectors.getUserTravelers);
  const selectedTravelerIds = useSelector(
    ExperiencesTravelerSelectors.getSelectedTravelerIds
  );

  const travelersLoading = useSelector(
    ExperiencesTravelerSelectors.getIsTravelerInformationLoading
  );

  const hasError = useSelector(
    ExperiencesTravelerSelectors.getTravelerErrorOpen
  );

  const currentTraveler = useSelector(
    ExperiencesTravelerSelectors.getCurrentUserTraveler
  );

  const {
    requirePassport,
    requireWeight,
    requireHeight,
    hasAnsweredBookingQuestions,
    getCurrentTravelerBookingQuestionResponse,
  } = useSelector(ExperiencesTravelerSelectors.getPerTravelerBookingQuestion);

  const [weight, setWeight] = useState("");
  const [heightFt, setHeightFt] = useState("");
  const [heightIn, setHeightIn] = useState("");
  const [hasMissingFields, setHasMissingFields] = useState(false);

  const expState = useExperiments();

  useEffect(() => {
    if (currentTraveler) {
      const currentTravelerResponse = getCurrentTravelerBookingQuestionResponse(
        currentTraveler.id
      );

      setWeight(currentTravelerResponse.weight);
      setHeightFt(currentTravelerResponse.heightFt);
      setHeightIn(currentTravelerResponse.heightIn);
    }
  }, [currentTraveler]);

  const handleSelectTraveler = (
    travelerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => {
    if (hasError) {
      send(Event.CLEAR_TRAVELER_INFORMATION_ERROR);
    }

    const selectedTraveler = travelers.find((p) => p.id === travelerId);
    if (selectedTraveler) {
      send({
        type: Event.SET_CURRENT_TRAVELER,
        traveler: selectedTraveler as IPerson,
      });
    }

    if (!hasAnsweredBookingQuestions(travelerId)) {
      send(Event.OPEN_TRAVELER_FORM);
      return;
    }

    send({
      type: Event.SELECT_TRAVELER,
      travelerId,
      singleTravelerWorkflow,
    });
  };

  const handleUpdateTraveler = (traveler: IPerson) => {
    if (
      (requireWeight && !weight) ||
      (requireHeight && (!heightFt || !heightIn))
    ) {
      setHasMissingFields(true);
      return;
    }

    if (requirePassport || requireWeight || requireHeight) {
      send({
        type: Event.ANSWER_BOOKING_QUESTIONS,
        travelerId: traveler.id,
        data: {
          heightFt,
          heightIn,
          weight,
        },
      });
    }

    send({
      type: Event.UPDATE_TRAVELER,
      person: {
        ...traveler,
        // validation will fail if any of these fields are empty so include all or nothing
        passport:
          traveler.passport?.countryOfIssue &&
          traveler.passport?.expiration &&
          traveler.passport?.number
            ? traveler.passport
            : undefined,
      },
      onUpdate: true,
    });
  };

  const handleDeleteTraveler = (personId: string) => {
    send({ type: Event.DELETE_TRAVELER, personId });
  };

  const handleEditClick = (traveler: IPerson) => {
    if (parentState === ParentState.review) {
      // edit travelers from mobile review screen
      // send({
      //   type: FlightPassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER,
      //   passenger: traveler,
      // });
    } else {
      send({
        type: Event.SET_CURRENT_TRAVELER,
        traveler,
      });
      send(Event.OPEN_TRAVELER_FORM);
    }
  };

  const handleFormClose = () => {
    send(Event.OPEN_TRAVELER_PICKER);
  };

  const handleClickAddNewTraveler = () => {
    send({
      type: Event.SET_CURRENT_TRAVELER,
      traveler: undefined,
    });
    send(Event.OPEN_TRAVELER_FORM);
  };

  const handleContinue = () => {
    send(Event.NEXT);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const { sessionInfo } = useContext(ClientContext);

  return travelersLoading ? (
    <LoadingIndicator
      className="flight-book-passenger-selection-loading-indicator"
      indicatorSize="small"
      indicator={B2BSpinner}
      message={constants.UPDATE_TEXT}
    />
  ) : (
    <TravelerSelectWorkflow
      showGenderField
      showNationalityField
      requireNationality
      // show passport section if pre traveler booking question requires it
      showPassportSection={requirePassport}
      requirePassport={requirePassport}
      className={clsx("experiences-traveler-selection-root", {
        error: validationErrorTypes?.includes("travelers"),
      })}
      travelers={travelers.map(personToIPerson)}
      progress={getTravelerWorkflowStep({
        openTravelerFormModal,
        isMobile: !!isMobile,
        parentState,
      })}
      setProgress={() => {}} // progress is derived from state machine
      userInfo={sessionInfo?.userInfo}
      titles={{
        travelerInfoTitle:
          isMobile && parentState === ParentState.review
            ? constants.TRAVELER_INFO_TEXT
            : constants.TRAVELER_INFO_TITLE_UPDATED,
        travelerInfoSubtitle: undefined,
        frequentFlyerTitle: "",
        additionalInfoTitle: "",
        adultTitle: constants.ADULT_TITLE,
        childTitle: constants.CHILD_TITLE,
        infantSeatTitle: "",
        infantLapTitle: "",
        addTravelers: constants.ADD_TRAVELERS_TEXT_UPDATED,
        editTravelerTitle: constants.EDIT_TRAVELER_TEXT,
        travelerInfoFormSubtitle: constants.ADD_TRAVELERS_SUBTITLE,
        travelerInfoSectionTitle: constants.TRAVELER_INFO_TEXT,
        passportTitle: constants.PASSPORT_TITLE,
        passportSubtitle: constants.PASSPORT_SUBTITLE,
        completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
        completeTravelerProfileSubtitle: constants.COMPLETE_PROFILE_SUBTITLE,
      }}
      selectedTravelerIds={selectedTravelerIds}
      handleSelectTraveler={handleSelectTraveler}
      handleUpdatePassenger={handleUpdateTraveler}
      handleDeletePassenger={handleDeleteTraveler}
      isMobile={isMobile}
      buttonClassName="b2b"
      errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
      isFlights
      onClickAddNewTraveler={() => {
        handleClickAddNewTraveler();
        trackEvent({
          eventName: CLICKED_ADD_TRAVELER,
          properties: addTrackingProperties(expState.trackingProperties, {
            entry_type: "checkout",
          } as ClickedAddTravelerProperties),
        });
      }}
      onClickEditTraveler={(traveler) => handleEditClick(traveler)}
      tenant={config.TENANT}
      trackEvent={trackEvent}
      setSelectedTravelerIds={() => {}}
      editPassenger={
        currentTraveler ? personToIPerson(currentTraveler) : undefined
      }
      onTravelerFormClose={handleFormClose}
      onContinue={isMobile ? handleContinue : undefined}
      onGoBack={isMobile ? handleGoBack : undefined}
      selectionScreenHeaderElement={<PriceBreakdown isMobile dropdown />}
      mobileTravelerRowType="checkbox"
      updatedDesign
      customHeader={
        <Box className="experiences-flight-book-passenger-mobile-header">
          <Typography variant="h3" className="pax-header-primary-title">
            {constants.WHOS_FLYING_HEADING}
          </Typography>
          <hr />
          <Typography variant="h4" className="pax-header-secondary-title">
            {constants.TRAVELER_INFO_TITLE_UPDATED}
          </Typography>
        </Box>
      }
      showMobilePopoverTransition={false}
      showMobileChangeCTA={false}
      bypassSelectForSingleTraveler={false}
      showNonModalContent={showNonModalContent}
      showAdditionalInfoSection={requireWeight || requireHeight}
      renderCustomAdditionalInfoSection={(errorOnSave: boolean) => {
        const hasFieldErrors = errorOnSave || hasMissingFields;

        return (
          <Box className="traveler-info-form-section experiences-additional-info-container">
            <Box className="traveler-info-description">
              <Typography className="section-title">
                {constants.ADDITIONAL_INFO_TITLE}
              </Typography>
              <Typography className="section-subtitle">
                {constants.ADDITIONAL_INFO_SUBTITLE}
              </Typography>
            </Box>
            <form className="additional-fields-form">
              {requireWeight && (
                <B2BTextField
                  className={clsx("experiences-weight-input-field")}
                  value={weight}
                  onChange={setWeight}
                  onBlur={() => {
                    setHasMissingFields(false);
                  }}
                  label="Weight (lbs)"
                  error={!weight && hasFieldErrors}
                  errorHelper={
                    !weight && hasFieldErrors
                      ? constants.REQUIRED_FIELD
                      : undefined
                  }
                  fullWidth
                  required
                />
              )}
              {requireHeight && (
                <Box>
                  <B2BTextField
                    className="experiences-height-input-ft-field"
                    value={heightFt}
                    onChange={setHeightFt}
                    onBlur={() => {
                      setHasMissingFields(false);
                    }}
                    label="Height (fts)"
                    error={!heightFt && hasFieldErrors}
                    errorHelper={
                      !heightFt && hasFieldErrors
                        ? constants.REQUIRED_FIELD
                        : undefined
                    }
                    required
                  />
                  <B2BTextField
                    className="experiences-height-input-inch-field"
                    value={heightIn}
                    onChange={setHeightIn}
                    onBlur={() => {
                      setHasMissingFields(false);
                    }}
                    label="Height (inches)"
                    error={!heightIn && hasFieldErrors}
                    errorHelper={
                      !heightIn && hasFieldErrors
                        ? constants.REQUIRED_FIELD
                        : undefined
                    }
                    required
                  />
                </Box>
              )}
            </form>
          </Box>
        );
      }}
    />
  );
};
