import { ExperiencesMachineContext } from "../types";

export const ExperiencesCommonGuards = {
  isMobile: (ctx: ExperiencesMachineContext) => {
    return ctx.platform === "mobile";
  },
  isDesktop: (ctx: ExperiencesMachineContext) => {
    return ctx.platform === "desktop";
  },
};
