import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import {
  ExperienceAvailability,
  ExperiencePrice,
  ExperiencePricingCategoryEnum,
  ExperiencesCancellationSummary,
  MediaAsset,
  MediaAssetKindEnum,
  RewardsAccount,
} from "redmond";
import {
  ActionButton,
  AvailabilityCarousel,
  formatDurationHours,
  genericPriceFormatter,
  getPricesWithComma,
  getTotalPriceText,
  Icon,
  IconName,
  Tag,
} from "halifax";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

import styles from "./styles.module.scss";
import {
  AVG_REVIEWS,
  CANCELLATION,
  COMPARE_ITINERARY,
  CONTINUE_TO_CHECKOUT,
  CURRENT,
  DURATION,
  PAID_CANCELLATION,
  PER_GROUP,
  PER_PERSON,
  PRICE,
  RATING,
  VIEW_DETAILS,
} from "../../../../textConstants";
import { EarnTagText } from "../../../../../common";
import { transformToStringifiedShopQuery } from "../../../../../../utils/queryStringHelpers";
import { PATH_BOOK, PATH_SHOP } from "../../../../../../utils/paths";
import { FREE_CANCELLATION } from "../../../../../common/textConstants";

export interface IExperiencesSimilarCardProps {
  isMobile?: boolean;
  experiencesAvailabilityInfo?: ExperienceAvailability;
  variant?: "row" | "card";
  rewardsAccount?: RewardsAccount;
  selectedExperienceIdValue?: string;
  isSkeleton: boolean;
  fromDate: Date | null;
  untilDate: Date | null;
  onCompareClick?: (selectedComparedExpId: string) => void;
}

export const ExperiencesSimilarCard = (
  props: IExperiencesSimilarCardProps
): React.ReactElement => {
  const {
    isMobile,
    experiencesAvailabilityInfo,
    variant = "card",
    rewardsAccount,
    selectedExperienceIdValue,
    fromDate,
    untilDate,
    isSkeleton,
    onCompareClick,
  } = props;
  const history = useHistory();

  const {
    name,
    rating,
    duration,
    bestPrice,
    pricingCategory,
    coverImage,
    additionalImages,
    cancellationSummary,
    id,
  } = experiencesAvailabilityInfo ?? {
    name: "",
    rating: { numberOfReviews: 0, reviewAverage: 0 },
    duration: 0,
    bestPrice: {
      fiat: {
        currencyCode: "",
        currencySymbol: "",
        value: 0,
      },
      rewards: {},
      accountSpecific: {},
    },
    pricingCategory: {
      ExperiencePricingCategory: ExperiencePricingCategoryEnum.PerPerson,
    },
    coverImage: "",
    additionalImages: [],
  };

  const experiencePrice: ExperiencePrice = {
    price: bestPrice,
    pricingCategory,
  };

  const suffixText =
    pricingCategory?.ExperiencePricingCategory ===
    ExperiencePricingCategoryEnum.PerGroup
      ? PER_GROUP
      : PER_PERSON;

  const priceString = getPricesWithComma(
    getTotalPriceText({
      price: experiencePrice.price.fiat,
      priceFormatter: genericPriceFormatter,
    })
  );

  const coverMedia: MediaAsset = {
    kind: MediaAssetKindEnum.Image,
    url: coverImage,
  };

  let mediaArray: MediaAsset[] = [coverMedia];
  if (variant === "row") {
    additionalImages?.map((additionalImage) => {
      mediaArray.push({
        kind: MediaAssetKindEnum.Image,
        url: additionalImage,
      } as MediaAsset);
    });
  }

  const isMainExpCard = selectedExperienceIdValue === id?.value;

  return (
    <Box
      className={clsx({
        mobile: isMobile,
        [styles.card]: variant === "card",
        // TODO row style
        // [styles.row]: variant === "row",
      })}
    >
      <Box className={styles["carousel-container"]}>
        {rewardsAccount && !isSkeleton && (
          <Box className={styles["tag-container"]}>
            <Tag
              className={styles["earn-tag"]}
              label={<EarnTagText rewardsAccount={rewardsAccount} />}
            />
          </Box>
        )}
        {isMainExpCard && !isSkeleton ? (
          <div className={styles["current-tag"]}>{CURRENT}</div>
        ) : null}
        <AvailabilityCarousel
          media={mediaArray}
          hideArrows={variant === "card"}
          isSkeleton={isSkeleton}
        />
      </Box>
      <Box className={styles["info-container"]}>
        <Box className={styles["info-row-container"]}>
          <Box className={styles["experience-name-container"]}>
            {isSkeleton ? (
              <Skeleton className={styles["skeleton-container"]} />
            ) : (
              name
            )}
          </Box>
        </Box>
        <Box className={styles["info-row-container"]}>
          <Box
            className={clsx(
              styles["info-title-icon-container"],
              styles["reviews-title-icon-container"]
            )}
          >
            <Icon name={IconName.TripAdvisorIcon} />
            <Typography variant="body1">{RATING}</Typography>
          </Box>
          {isSkeleton ? (
            <Skeleton className={styles["skeleton-container"]} />
          ) : (
            <Typography variant="h6">
              {AVG_REVIEWS({
                avg: (rating.reviewAverage / rating.numberOfReviews).toFixed(1),
                numberOfReviews: rating.numberOfReviews,
              })}
            </Typography>
          )}
        </Box>
        <Box className={styles["info-row-container"]}>
          <Box className={styles["info-title-icon-container"]}>
            <Icon name={IconName.ClockIconThin} />
            <Typography variant="body1">{DURATION}</Typography>
          </Box>
          {isSkeleton ? (
            <Skeleton className={styles["skeleton-container"]} />
          ) : (
            <Typography variant="h6">
              {formatDurationHours(dayjs.duration({ minutes: duration }))}
            </Typography>
          )}
        </Box>
        <Box className={styles["info-row-container"]}>
          <Box className={styles["info-title-icon-container"]}>
            <Icon name={IconName.CalendarCancellationIcon} />
            <Typography variant="body1">{CANCELLATION}</Typography>
          </Box>
          {isSkeleton ? (
            <Skeleton className={styles["skeleton-container"]} />
          ) : cancellationSummary ===
            ExperiencesCancellationSummary.FreeCancellation ? (
            <Typography
              variant="h6"
              className={styles["free-cancellation-label"]}
            >
              {FREE_CANCELLATION}
            </Typography>
          ) : (
            <Typography variant="h6">{PAID_CANCELLATION}</Typography>
          )}
        </Box>
        <Box className={styles["info-row-container"]}>
          <Box className={styles["info-title-icon-container"]}>
            <Icon name={IconName.MoneyOutlineThin} />
            <Typography variant="body1">{PRICE}</Typography>
          </Box>
          {isSkeleton ? (
            <Skeleton className={styles["skeleton-container"]} />
          ) : (
            <Typography variant="h6">
              {priceString} {suffixText}
            </Typography>
          )}
        </Box>
        {isMainExpCard && !isSkeleton ? (
          <ActionButton
            className={styles["checkout-cta-button"]}
            onClick={() =>
              history.push(`${PATH_BOOK}${history.location.search}`)
            }
            message={CONTINUE_TO_CHECKOUT}
          />
        ) : (
          <Box>
            <Button
              variant="outlined"
              color="primary"
              className={styles["view-details-button"]}
              onClick={() => {
                if (id) {
                  window.open(
                    `${PATH_SHOP}${transformToStringifiedShopQuery(
                      id,
                      fromDate,
                      untilDate
                    )}`,
                    "_blank"
                  );
                }
              }}
            >
              {VIEW_DETAILS}
            </Button>
            <Button
              variant="text"
              color="primary"
              className={styles["compare-itinerary-button"]}
              onClick={() => {
                if (onCompareClick) {
                  onCompareClick(id?.value || "");
                }
              }}
            >
              {COMPARE_ITINERARY}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
