import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { ActionButton, B2BSpinner, BackButton, LoadingPopup } from "halifax";
import {
  CardPaymentSelectors,
  getChildState,
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";

import "./styles.scss";
import { Event, TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import { PATH_BOOK_CONFIRMATION } from "../../../../utils/paths";
import { getLoadingMessage } from "./utils";
import {
  ContactInfo,
  PriceBreakdown,
  TreesCard,
  TravelerSelection,
  ExperiencesRewardsAndPayment,
  MobileTripReview,
} from "..";
import { CONFIRM_AND_BOOK_CTA_TEXT } from "../textConstants";
import {
  AVAILABLE,
  getExperimentVariant,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";

export const MobileExperiencesBookWorkflow = () => {
  const history = useHistory();
  const [state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value);

  const isCostCoveredBySelectedPaymentMethods = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCostCoveredBySelectedPaymentMethods
  );

  const expState = useExperiments();
  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesExperiment = treesModalExperiment === AVAILABLE;

  const handleBack = () => {
    send(Event.GO_TO_CARD_PAYMENT);
  };

  const handleContinue = () => {
    send(Event.NEXT);
  };

  React.useEffect(() => {
    // update path so header becomes visible for confirmation
    if (parentState === ParentState.bookingConfirmation) {
      history.replace(PATH_BOOK_CONFIRMATION);
    }
  }, [parentState]);

  React.useEffect(() => {
    send({ type: Event.SET_PLATFORM, platform: "mobile" });
  }, []);

  const loadingMessage = getLoadingMessage(
    parentState,
    childState || undefined
  );

  return (
    <Box className="mobile-experiences-book-workflow-root">
      {parentState === ParentState.review && (
        <>
          <Box className="experiences-book-review-header">
            <BackButton
              className="experiences-book-review-header-back-button"
              onClick={handleBack}
            />
            <PriceBreakdown isMobile dropdown />
          </Box>

          <MobileTripReview />
          <ActionButton
            onClick={handleContinue}
            message={CONFIRM_AND_BOOK_CTA_TEXT}
            className="experiences-mobile-book-button"
            disabled={!isCostCoveredBySelectedPaymentMethods}
          />
          {isTreesExperiment && <TreesCard />}
        </>
      )}

      {parentState === ParentState.experiencesTravelerInformation && (
        <TravelerSelection isMobile showNonModalContent={false} />
      )}

      {parentState === ParentState.contactInformation && (
        <ContactInfo isMobile contactFormOnly />
      )}

      {parentState === ParentState.cardPayment && (
        <ExperiencesRewardsAndPayment isMobile />
      )}

      <LoadingPopup
        classes={["mobile-experiences-book-loading-popup"]}
        open={!!loadingMessage}
        message={loadingMessage || ""}
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen={true}
      />
    </Box>
  );
};
