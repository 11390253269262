import {
  ParentState,
  useCheckoutStateSelector as useSelector,
} from "@capone/checkout";
import { CheckoutStepperProps } from "halifax/build/CheckoutStepper/component";

import { ExperiencesAdditionalDetailsSelectors } from "../../../../checkout";

export const getCheckoutSteps = (
  parentState: ParentState
): CheckoutStepperProps["steps"] => {
  const hasAdditionalDetailsStep = useSelector(
    ExperiencesAdditionalDetailsSelectors.getHasAdditionalDetailsStep
  );

  return [
    {
      name: "Traveler information",
      status: (() => {
        switch (parentState) {
          case ParentState.experiencesTravelerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Primary traveler",
      status: (() => {
        switch (parentState) {
          case ParentState.experiencesTravelerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
            return 0;
          case ParentState.experiencesPrimaryTraveler:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    hasAdditionalDetailsStep && {
      name: "Additional details",
      status: (() => {
        switch (parentState) {
          case ParentState.experiencesTravelerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
          case ParentState.experiencesPrimaryTraveler:
            return 0;
          case ParentState.experiencesAdditionalDetails:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Rewards & payment",
      status: (() => {
        switch (parentState) {
          case ParentState.experiencesTravelerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
          case ParentState.experiencesPrimaryTraveler:
          case ParentState.experiencesAdditionalDetails:
          case ParentState.cartQuote:
            return 0;
          case ParentState.cardPayment:
            return 1;
          default:
            return 2;
        }
      })(),
    },
  ].filter((step) => !!step) as CheckoutStepperProps["steps"];
};
